import { IconProps } from "./dto";

export function CodeIcon(props: IconProps) {
    return (
        <svg width={props.size} height={props.size} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_698_15663)">
                <path d="M12 13.5L16.5 9L12 4.5M6 4.5L1.5 9L6 13.5" stroke={props.color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            </g>
            <defs>
                <clipPath id="clip0_698_15663">
                    <rect width={props.size} height={props.size} fill="white" />
                </clipPath>
            </defs>
        </svg>

    )
}