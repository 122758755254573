export interface UserDTO {
    collaborators: string[] | null
    customerId: string | null
    email: string
    name: string
    avatarObjectId: string
    id: string
    role: UserRole
    surname: string
    userId: string
    phone: string
}

export enum UserRole {
    admin = 'admin',
    team_leader = 'team_leader',
    commercial = 'commercial',
    customer = 'customer',
    collaborator = 'collaborator'
}

export interface FindAllUsersFilters {
    role?: UserRole
    search?: string
    order: boolean
    sort?: keyof UserDTO
    itemsPerPage: number
    page: number
}

export const UserRoleMap = new Map<UserRole, string>([
    [UserRole.admin, 'Admin'],
    [UserRole.team_leader, 'Team Leader'],
    [UserRole.commercial, 'Commerciale'],
    [UserRole.customer, 'Cliente'],
    [UserRole.collaborator, 'Collaboratore'],
])

export interface FindAllUserResponse {
    data: UserDTO[]
    next: boolean
    page: number
    perPage: number
    prev: false
    total: number
    totalPage: number
}

export interface EditUserRequest {
    name: string
    surname: string
    collaborators: string[]
    customerId: string
    phone: string
}

export interface NewUserRequest {
    name: string
    surname: string
    email: string
    objectId: string
    role?: string
    collaborators: string[]
    customerId: string
    phone: string
}