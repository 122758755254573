import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../lib/redux/hooks";
import { EditIcon } from "../../ui/icons/edit";
import { SortIcon } from "../../ui/icons/sort";
import { TrashIcon } from "../../ui/icons/trash";
import Avatar from "../../ui/molecules/avatar";
import Pills from "../../ui/molecules/pills";
import { Spinner } from "../../ui/molecules/spinner";
import { ConfirmModal } from "../../ui/organisms/confirmModal.tsx";
import { Dropdown } from "../../ui/organisms/dropdown";
import { Pagination } from "../../ui/organisms/pagination";
import { UserRoleMap } from "./dto";
import { deleteUser, setFindAllUsersFiltersOrder, setFindAllUsersFiltersPage, setFindAllUsersFiltersSort, setSelectedUser } from "./slice";

export function UsersList() {
    const usersState = useAppSelector(state => state.users)
    const dispatch = useAppDispatch()
    const [idToDelete, setIdToDelete] = useState<string | undefined>()

    useEffect(() => {
        if (usersState.deleteUserStatus === 'successfully') {
            setIdToDelete(undefined)
        }
    }, [usersState.deleteUserStatus])

    if (usersState.findAllStatus === 'loading' || usersState.findUsersAvatarsListStatus === 'loading') {
        return (
            <div className="w-full flex items-center justify-center h-[45%]">
                <Spinner />
            </div>
        )
    }

    return (
        <div className="w-[100%] flex flex-col">
            <div className="flex items-center w-full justify-between bg-white px-[16px] rounded-t-[12px] py-[4px]">
                {usersState.findAllResponse?.total === 1 ?
                    <span className="text-text-md text-neutral-850">
                        Trovato <span className="font-semibold">1</span> utente
                    </span>
                    :
                    <span className="text-text-md text-neutral-850">
                        Trovati <span className="font-semibold">{usersState.findAllResponse?.total}</span> utenti
                    </span>
                }
                <Pagination pages={usersState.findAllResponse?.totalPage || 1} currentPage={usersState.filters.page} setPage={(page) => dispatch(setFindAllUsersFiltersPage(page))} />
            </div>
            <div>
                <table className="w-[100%]">
                    <thead className="bg-neutral-800 h-[48px] font-medium text-neutral-300 uppercase text-text-xs">
                        <tr className="bg-neutral-800">
                            <th className="text-left pr-[4px] pl-[12px]" />
                            <th className="text-left">
                                <div className="flex gap-[4px] px-[4px]">
                                    <div
                                        onClick={() => { dispatch(setFindAllUsersFiltersSort('name')); dispatch(setFindAllUsersFiltersOrder(!usersState.filters.order)) }}
                                        className="cursor-pointer hover: opacity-70"
                                    >
                                        <SortIcon />
                                    </div>
                                    Nome
                                </div>
                            </th>
                            <th className="text-left">
                                <div className="flex gap-[4px] px-[4px]">
                                    <div
                                        onClick={() => { dispatch(setFindAllUsersFiltersSort('surname')); dispatch(setFindAllUsersFiltersOrder(!usersState.filters.order)) }}
                                        className="cursor-pointer hover: opacity-70"
                                    >
                                        <SortIcon />
                                    </div>
                                    Cognome
                                </div>
                            </th>
                            <th className="text-left">
                                <div className="flex gap-[4px] px-[4px]">
                                    <div
                                        onClick={() => { dispatch(setFindAllUsersFiltersSort('role')); dispatch(setFindAllUsersFiltersOrder(!usersState.filters.order)) }}
                                        className="cursor-pointer hover: opacity-70"
                                    >
                                        <SortIcon />
                                    </div>
                                    Ruolo
                                </div>
                            </th>
                            <th className="text-left">
                                <div className="flex gap-[4px] px-[4px]">
                                    <div
                                        onClick={() => { dispatch(setFindAllUsersFiltersSort('email')); dispatch(setFindAllUsersFiltersOrder(!usersState.filters.order)) }}
                                        className="cursor-pointer hover: opacity-70"
                                    >
                                        <SortIcon />
                                    </div>
                                    Email
                                </div>
                            </th>
                            <th className="text-left">
                                <div className="flex gap-[4px] px-[4px]">
                                    <div
                                        onClick={() => { dispatch(setFindAllUsersFiltersSort('phone')); dispatch(setFindAllUsersFiltersOrder(!usersState.filters.order)) }}
                                        className="cursor-pointer hover: opacity-70"
                                    >
                                        <SortIcon />
                                    </div>
                                    Telefono
                                </div>
                            </th>
                            <th className="text-left pr-[12px] pl-[4px]">
                                <div className="flex gap-[4px] px-[4px] w-[20px]" />
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {usersState.findAllResponse?.data.map(user =>
                            <tr
                                className="bg-white h-[56px] border-b-[1px] border-b-neutral-100"
                                key={user.userId}
                            >
                                <td width={60} className="pr-[4px] pl-[12px] text-text-md font-semibold text-neutral-600">
                                    <Avatar
                                        type="single"
                                        size="sm"
                                        shape="circle"
                                        altTextInitials={user.name[0] + user.surname[0]}
                                        imageUrl={usersState.findUsersAvatarsListResponse.find(object => object.userId === user.id)?.objectId!}
                                    />
                                </td>
                                <td className="pr-[4px] text-text-md font-semibold text-neutral-600">{user.name}</td>
                                <td className="px-[4px] text-text-md font-semibold text-neutral-600">{user.surname}</td>
                                <td className="px-[4px]"><Pills size={"sm"} label={UserRoleMap.get(user.role)!} outline={true} emphasis={false} color={"blue"} /></td>
                                <td className="px-[4px] text-text-sm text-neutral-600">{user.email}</td>
                                <td className="px-[4px] text-text-sm text-neutral-600">{user.phone}</td>
                                <td className="w-[20px] cursor-pointer">
                                    <Dropdown items={[
                                        {
                                            label: 'Modifica',
                                            icon: <EditIcon color={""} size={0} />,
                                            onClick: () => dispatch(setSelectedUser(user.id))
                                        },
                                        {
                                            label: 'Cancella',
                                            icon: <TrashIcon color={""} size={0} />,
                                            onClick: () => setIdToDelete(user.id)
                                        }
                                    ]} />
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
                <div className="flex bg-white shadow-sm items-center justify-end rounded-b-[12px] px-[16px] py-[4px]">
                    <Pagination pages={usersState.findAllResponse?.totalPage || 1} currentPage={usersState.filters.page} setPage={(page) => dispatch(setFindAllUsersFiltersPage(page))} />
                </div>
            </div>
            <ConfirmModal
                title={"Elimina utente"}
                label={"Sei sicuro di voler eliminare questo utente?"}
                confirmAction={function (): void {
                    idToDelete && dispatch(deleteUser(idToDelete))
                }} onClose={function (): void {
                    setIdToDelete(undefined)
                }}
                visible={idToDelete !== undefined}
            />
        </div>
    )
}