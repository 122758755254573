import { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../lib/redux/hooks";
import { Layout } from "../../ui/layout";
import { ConfirmModal } from "../../ui/organisms/confirmModal.tsx";
import { MenuItems } from "../../ui/organisms/navbar/dto";
import { TabPages } from "../../ui/organisms/tabs/tabPages";
import { Companies } from "./companies";
import { ImportModalCompanies } from "./companies/import/modal";
import { deleteCompany, setIdToDeleteCompany, setSelectedCompany } from "./companies/slice";
import { HeaderDatabase } from "./header";
import { People } from "./people";
import { AssociateCompanyModal } from "./people/associateCompany";
import { ImportModalPeople } from "./people/import/modal";
import { deletePerson, setIdToDeletePerson, setSelectedPerson } from "./people/slice";
import { setTabValue } from "./slice";

export function Database() {
    const databaseState = useAppSelector(state => state.database)
    const peopleState = useAppSelector(state => state.people)
    const companiesState = useAppSelector(state => state.companies)
    const [direction, setDirection] = useState<'left' | 'right'>('left');
    const dispatch = useAppDispatch()

    return (
        <Layout
            menuItem={MenuItems.DATABASE}
            breadcrumbItems={['Database']}
            headerLabel="Database"
            headerChildren={<HeaderDatabase direction={direction} setDirection={value => setDirection(value)} />}
        >
            <div className="max-h-full">
                <TabPages
                    direction={direction}
                    setDirection={value => setDirection(value)}
                    pages={[
                        <div key={0} className="max-h-full"><People /></div>,
                        <div key={1} className="h-full"><Companies /></div>,
                        // <div key={2} className="h-full w-full"></div>
                    ]}
                    value={databaseState.tabValue}
                    setValue={(e) => { dispatch(setTabValue(e)); dispatch(setSelectedCompany(undefined)); dispatch(setSelectedPerson(undefined)) }}
                />
                <ConfirmModal
                    title={"Elimina persona"}
                    label={"Sei sicuro di voler eliminare questa persona?"}
                    confirmAction={function (): void {
                        peopleState.idToDelete && dispatch(deletePerson(peopleState.idToDelete))
                    }} onClose={function (): void {
                        dispatch(setIdToDeletePerson(undefined))
                    }}
                    visible={peopleState.idToDelete !== undefined}
                />
                <ConfirmModal
                    title={"Elimina azienda"}
                    label={"Sei sicuro di voler eliminare questa azienda?"}
                    confirmAction={function (): void {
                        companiesState.idToDelete && dispatch(deleteCompany(companiesState.idToDelete))
                    }} onClose={function (): void {
                        dispatch(setIdToDeleteCompany(undefined))
                    }}
                    visible={companiesState.idToDelete !== undefined}
                />
                <ImportModalCompanies />
                <ImportModalPeople />
                <AssociateCompanyModal />
            </div>
        </Layout>
    )
}