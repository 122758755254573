import { createSlice } from "@reduxjs/toolkit"
import { PromiseStatuses } from "../types" 

export const urlMap = new Map<string, string>([
    ['https://iltuocontabile.madfarm.it', 'wss://iltuocontabile.madfarm.it'],
    ['https://app.iltuocontabile.it', 'wss://app.iltuocontabile.it'],
    ['http://localhost:3000', 'ws://localhost:8086'],
])
interface KeycloakState {
    userRoles: string[]
    keycloakInitStatus: PromiseStatuses
    socket?: WebSocket
}

const initialState: KeycloakState = {
    userRoles: [],
    keycloakInitStatus: 'idle',
}

const keycloakSlice = createSlice({
    name: 'keycloak/slice',
    initialState,
    reducers: {
        setRoles: (state, action) => {
            state.userRoles = action.payload
        },
        setKeycloakInitStatus: (state, action) => {
            state.keycloakInitStatus = action.payload
        },
        setSocket: (state, action) => {
            state.socket = action.payload
        }
    },
})

export const {
    setRoles,
    setKeycloakInitStatus,
    setSocket
} = keycloakSlice.actions

export default keycloakSlice.reducer