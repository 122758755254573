import clsx from 'clsx';
import { ReactElement, cloneElement, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAppSelector } from '../../../lib/redux/hooks';
import { colors } from '../../colors';
import { MenuStatus } from '../../layout/dto';
import { MenuItems } from './dto';


interface Props {
  link: {
    name: string,
    href: string,
    icon: ReactElement,
    menuItem: MenuItems
  },
  menuItem: MenuItems
}

export default function NavLinks(props: Props) {
  const { link } = props
  const linkIcon = link.icon;
  const [hover, setHover] = useState(false)
  const navigate = useNavigate()

  const layoutState = useAppSelector(state => state.layout)

  return (
    <Link
      onMouseOver={() => setHover(true)}
      onMouseOut={() => setHover(false)}
      key={link.name}
      // onClick={() => navigate(link.href)}
      className={clsx(
        "flex h-[40px] cursor-pointer grow items-center rounded-[8px] p-[8px] border-1 focus:outline-none focus:shadow-xs hover:shadow-xs text-sm font-medium text-neutral-500 hover:text-neutral-600 md:flex-none",
        {
          'bg-neutral-100  text-neutral-800': link.menuItem === props.menuItem,
          'text-neutral-500 hover:text-neutral-600': link.menuItem !== props.menuItem,
          'justify-center': layoutState.menuStatus === MenuStatus.CLOSED,
          'justify-between': layoutState.menuStatus !== MenuStatus.CLOSED,
        }
      )}
      to={link.href}
    >
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', gap: '8px' }}>
        {cloneElement(linkIcon, { color: hover && link.menuItem !== props.menuItem ? colors.neutral[600] : link.menuItem === props.menuItem ? colors.neutral[800] : colors.neutral[500] })}
        {
          layoutState.menuStatus !== MenuStatus.CLOSED &&
          <p className="text-label-sm align-text-bottom font-medium">{link.name}</p>
        }
      </div>
      {/* <Pills size={'sm'} label={'4'} outline={false} emphasis={pathname === link.href ? false : true} color={hover || pathname === link.href ? 'blue' : 'gray'} /> */}
    </Link>
  );
}
