import { useEffect } from 'react';
import { keycloak } from './app/lib/keycloak';
import { setKeycloakInitStatus, setRoles } from './app/lib/keycloak/slice';
import { createPreferences, findAllAtecos, findAllPreferences, setCreatePreferencesStatus, setFindAllPreferencesStatus, setUpdatePreferencesStatus } from './app/lib/preferences/slice';
import { useAppDispatch, useAppSelector } from './app/lib/redux/hooks';
import { KeycloakRouter } from './app/lib/router';
import { companyColumns, peopleColumns } from './app/pages/database/dto';
import { Spinner } from './app/ui/molecules/spinner';

function App() {
  const dispatch = useAppDispatch()
  const preferencesState = useAppSelector(state => state.preferences)

  useEffect(() => {
    if (window.location.pathname === '/')
      window.location.replace(window.location.origin + '/dashboard')
  }, [window.location.pathname])

  const keycloakState = useAppSelector(state => state.keycloak)

  keycloak.onAuthSuccess = () => {
    if (keycloak.hasRealmRole('company')) {
      keycloak.logout({ redirectUri: window.location.origin })
    }
    dispatch(setRoles(keycloak.tokenParsed?.realm_access?.roles))
    dispatch(setKeycloakInitStatus('successfully'))
    dispatch(findAllPreferences(keycloak.subject!))
    dispatch(findAllAtecos())
  }

  useEffect(() => {
    if (
      preferencesState.findAllStatus === 'successfully' &&
      preferencesState.findAllResponse !== undefined
    ) {
      dispatch(setFindAllPreferencesStatus('idle'))
      if (preferencesState.findAllResponse.data.length === 0) {
        dispatch(createPreferences({
          owner: '',
          personPreferences: peopleColumns,
          companyPreferences: companyColumns
        }))
      }
    }
  }, [preferencesState.findAllStatus])

  useEffect(() => {
    if (preferencesState.createStatus === 'successfully' || preferencesState.updateStatus === 'successfully') {
      dispatch(setCreatePreferencesStatus('idle'))
      dispatch(setUpdatePreferencesStatus('idle'))
      dispatch(findAllPreferences(keycloak.subject!))
    }
  }, [preferencesState.createStatus, preferencesState.updateStatus])

  keycloak.onAuthRefreshError = () => {
    keycloak.logout()
  }

  if (
    keycloakState.keycloakInitStatus === 'idle'
  ) {
    return (
      <div className='w-full h-[100vh] flex items-center justify-center'>
        <Spinner />
      </div>
    )
  }

  return (
    <div className="App">
      <KeycloakRouter />
    </div>
  );
}

export default App;
