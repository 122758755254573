import { IconProps } from "./dto";

export function MoreHorizontalIcon(props: IconProps) {
    return (
        <svg width={props.size} height={props.size} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.99996 8.66671C8.36815 8.66671 8.66663 8.36823 8.66663 8.00004C8.66663 7.63185 8.36815 7.33337 7.99996 7.33337C7.63177 7.33337 7.33329 7.63185 7.33329 8.00004C7.33329 8.36823 7.63177 8.66671 7.99996 8.66671Z" stroke={props.color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M12.6666 8.66671C13.0348 8.66671 13.3333 8.36823 13.3333 8.00004C13.3333 7.63185 13.0348 7.33337 12.6666 7.33337C12.2984 7.33337 12 7.63185 12 8.00004C12 8.36823 12.2984 8.66671 12.6666 8.66671Z" stroke={props.color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M3.33329 8.66671C3.70148 8.66671 3.99996 8.36823 3.99996 8.00004C3.99996 7.63185 3.70148 7.33337 3.33329 7.33337C2.9651 7.33337 2.66663 7.63185 2.66663 8.00004C2.66663 8.36823 2.9651 8.66671 3.33329 8.66671Z" stroke={props.color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>

    )
}