import { useEffect } from "react"
import { useAppDispatch, useAppSelector } from "../../../../lib/redux/hooks"
import { groupBy } from "../../../../lib/utils"
import { ShoppingCartIcon } from "../../../../ui/icons/shoppingCart"
import { SelectCustom } from "../../../../ui/molecules/select"
import { AccordionFilter } from "../../../../ui/organisms/accordionFilter"
import { setFindAllPeopleFiltersCategory } from "../slice"

export function CategoryFilter() {
    const peopleState = useAppSelector(state => state.people)
    const databaseState = useAppSelector(state => state.database)
    const preferencesState = useAppSelector(state => state.preferences)

    const dispatch = useAppDispatch()

    useEffect(() => {
        if (peopleState.filters.sector.length === 0) {
            dispatch(setFindAllPeopleFiltersCategory([]))
        } else if (peopleState.filters.atecos.length === 0) {
            let newCategories: { value: string, label: string }[] = []
            peopleState.filters.sector.forEach(sector => {
                const categories = preferencesState.findAllAtecosResponse.filter(_ateco => _ateco.sector === sector.value)
                categories.forEach(category => {
                    if (category && !newCategories.map(cat => cat.value).includes(category.category)) {
                        newCategories.push({ value: category.category, label: category.category })
                    }
                })
            })
            dispatch(setFindAllPeopleFiltersCategory(newCategories))
        }
    }, [peopleState.filters.sector])

    const filteredAtecos = preferencesState.findAllAtecosResponse
        .filter(ateco =>
            peopleState.filters.atecos.length > 0 ?
                peopleState.filters.atecos.map(_ateco => _ateco.value).includes(ateco.atecoCode) :
                peopleState.filters.sector.length > 0 ?
                    peopleState.filters.sector.map(_ateco => _ateco.value).includes(ateco.sector) :
                    ateco
        )

    return (
        <AccordionFilter
            key={'person-filter-category'}
            label={"Categoria"}
            icon={<ShoppingCartIcon color={""} size={0} />}
            showClear={peopleState.filters.category.length > 0}
            options={peopleState.filters.category}
            clearAction={function (): void {
                dispatch(setFindAllPeopleFiltersCategory([]))
            }}
            deleteFilter={function (option): void {
                dispatch(setFindAllPeopleFiltersCategory(peopleState.filters.category.filter(name => name.value !== option.value)))
            }}
        >
            <SelectCustom
                isMulti
                isClearable={false}
                onChange={e => {
                    if (e !== undefined && typeof e !== 'string') {
                        dispatch(setFindAllPeopleFiltersCategory(e.map(option => ({ value: option, label: (groupBy(preferencesState.findAllAtecosResponse, 'category').find(ateco => ateco.key === option)?.key) }))))
                    }
                }}
                defaultValue={peopleState.filters.category.map(option => option.value)}
                placeholder={"Cerca per categoria"}
                options={groupBy(filteredAtecos, 'category').map(sector => ({ value: sector.key, label: sector.key }))}
                noOptionsMessage="Non sono presenti categorie."
            />
        </AccordionFilter>
    )
}