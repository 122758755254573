import axios from "axios";
import { keycloak } from "../keycloak";
import { ObjectService } from "./service";

export class ObjectServiceImpl implements ObjectService {
    public findFileById(id: string): Promise<string> {
        let url: string = "/api/objects/file/" + id
        if (id) {
            return axios({
                url: url,
                method: "GET",
                headers: {
                    Authorization: 'Bearer ' + keycloak.token,
                    Accept: '*/*',
                    ContentType: 'application/json',
                }
            }).then((response) => response.data)
        }
        return Promise.resolve("")

    }

    public findFileByIdWithUserId(id: string, userid: string): Promise<{ userId: string, objectId: string }> {
        let url: string = "/api/objects/file/" + id
        return axios({
            url: url,
            method: "GET",
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then(response => {
            const data = response.data;
            const modifiedResponse = {
                userId: userid,
                objectId: data
            };
            return modifiedResponse;
        });
    }
}
