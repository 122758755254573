import AsyncSelect from 'react-select/async';
import { colors } from "../../colors";
import { SelectAsyncProps } from "./dto";
import './style.css';

export function SelectCustomAsync(props: SelectAsyncProps) {
    return (
        <div className="w-[100%]">
            <AsyncSelect
                menuIsOpen={props.menuIsOpen}
                cacheOptions
                loadOptions={input => props.loadOptions(input)}
                defaultOptions={props.defaultOptions}
                isClearable={props.isClearable}
                isMulti={props.isMulti}
                isDisabled={props.disabled}
                noOptionsMessage={() => <span className="text-text-sm">{props.noOptionsMessage}</span>}
                placeholder={props.placeholder}
                defaultValue={props.defaultValue !== undefined ? props.defaultValue : null}
                value={props.defaultValue !== undefined ? props.defaultValue : null}
                styles={{
                    placeholder: (baseStyles) => ({
                        ...baseStyles,
                        fontSize: '14px',
                        lineHeight: '16px',
                        color: colors.neutral[400],
                        fontFamily: 'Fira Sans',
                        fontWeight: 500
                    }),
                    option: (baseStyles, props) => ({
                        ...baseStyles,
                        fontSize: '14px',
                        lineHeight: '20px',
                        color: props.isSelected ? colors.neutral[800] : colors.neutral[600],
                        fontFamily: 'Fira Sans',
                        fontWeight: 500,
                        backgroundColor: props.isSelected ? colors.brandPrimary[400] : colors.white,
                        '&:hover': {
                            backgroundColor: colors.brandPrimary[200]
                        }
                    }),
                    input: (baseStyles, props) => ({
                        ...baseStyles,
                        fontSize: '14px',
                        lineHeight: '20px',
                        color: colors.neutral[600],
                        fontFamily: 'Fira Sans',
                        fontWeight: 500
                    }),
                    singleValue: (baseStyles, props) => ({
                        ...baseStyles,
                        fontSize: '14px',
                        lineHeight: '20px',
                        color: colors.neutral[600],
                        fontFamily: 'Fira Sans',
                        fontWeight: 500
                    }),
                    multiValue: (baseStyles, props) => ({
                        ...baseStyles,
                        fontSize: '14px',
                        lineHeight: '20px',
                        color: colors.brandPrimary[900],
                        fontFamily: 'Fira Sans',
                        fontWeight: 500,
                        padding: '0px 8px',
                        borderRadius: 8,
                        backgroundColor: colors.brandPrimary[200],
                        border: '1px solid ' + colors.brandPrimary[400],
                    }),
                    multiValueRemove: (baseStyles, props) => ({
                        ...baseStyles,
                        color: colors.neutral[500],
                        '&:hover': {
                            color: colors.brandPrimary[600],
                            backgroundColor: colors.brandPrimary[200],
                        }
                    }),
                    indicatorsContainer: (baseStyles) => ({
                        ...baseStyles,
                        alignItems: 'center'
                    }),
                    container: (baseStyles) => ({
                        ...baseStyles,
                        width: '100%',
                        minHeight: '44px'
                    }),
                    control: (baseStyles, state) => ({
                        ...baseStyles,
                        minHeight: '44px',
                        width: '100%',
                        borderRadius: '8px',
                        boxShadow: 'none',
                        border: (state.isFocused ? '1px solid ' + colors.neutral[300] : '1px solid ' + colors.neutral[300]),
                        fontFamily: 'Fira Sans',
                        padding: '0px 12px',
                        '&:hover': {
                            border: '1px neutral ' + colors.neutral[300]
                        }
                    }),
                    valueContainer: (baseStyles, state) => ({
                        ...baseStyles,
                        padding: 0,
                    }),
                    indicatorSeparator: (baseStyles, state) => ({
                        display: 'none'
                    }),
                    dropdownIndicator: (baseStyles, state) => ({
                        color: state.isFocused ? colors.neutral[500] : colors.neutral[500]
                    }),
                }}
                onChange={(e) => {
                    props.onChange && props.onChange(props.isMulti ? e as { value: string, label: string }[] : e as { value: string, label: string })
                }}
            />
            {
                props.error &&
                <span className="text-text-sm text-red-500">{props.errorLabel}</span>
            }
        </div>
    )
}