import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { PromiseStatuses } from "../../lib/types"
import { AtecoDTO, CreatePreferenceRequest, FindAllPreferencesResponse } from "./dto"
import { NewPreferencesService } from "./service"

interface PreferencesState {
    findAllStatus: PromiseStatuses
    findAllResponse?: FindAllPreferencesResponse
    createStatus: PromiseStatuses
    updateStatus: PromiseStatuses
    findAllAtecosResponse: AtecoDTO[]
    findAllAtecosStatus: PromiseStatuses
}

const initialState: PreferencesState = {
    findAllStatus: 'idle',
    createStatus: 'idle',
    updateStatus: 'idle',
    findAllAtecosResponse: [],
    findAllAtecosStatus: 'idle'
}

export const findAllPreferences = createAsyncThunk(
    'preferences/findAllPreferences',
    async (owner: string, thunkApi): Promise<FindAllPreferencesResponse> => {
        const PreferencesService = NewPreferencesService()

        return PreferencesService.findAllPreferences(owner)
    }
)

export const createPreferences = createAsyncThunk(
    'preferences/createPreferences',
    async (request: CreatePreferenceRequest, thunkApi): Promise<void> => {
        const PreferencesService = NewPreferencesService()

        return PreferencesService.createPreferences(request)
    }
)

export const updatePreferences = createAsyncThunk(
    'preferences/updatePreferences',
    async (request: { data: CreatePreferenceRequest, id: string }, thunkApi): Promise<void> => {
        const PreferencesService = NewPreferencesService()

        return PreferencesService.updatePreferences(request.data, request.id)
    }
)

export const findAllAtecos = createAsyncThunk(
    'preferences/findAllAtecos',
    async (_, thunkApi): Promise<AtecoDTO[]> => {
        const PreferencesService = NewPreferencesService()

        return PreferencesService.findAllAtecos()
    }
)

const preferencesSlice = createSlice({
    name: 'preferences/slice',
    initialState,
    extraReducers(builder) {
        builder
            .addCase(findAllPreferences.pending, (state) => {
                state.findAllStatus = 'loading'
            })
            .addCase(findAllPreferences.fulfilled, (state, action) => {
                state.findAllStatus = 'successfully'
                state.findAllResponse = action.payload
            })
            .addCase(findAllPreferences.rejected, (state) => {
                state.findAllStatus = 'failed'
            })
            .addCase(createPreferences.pending, (state) => {
                state.createStatus = 'loading'
            })
            .addCase(createPreferences.fulfilled, (state) => {
                state.createStatus = 'successfully'
            })
            .addCase(createPreferences.rejected, (state) => {
                state.createStatus = 'failed'
            })
            .addCase(updatePreferences.pending, (state) => {
                state.updateStatus = 'loading'
            })
            .addCase(updatePreferences.fulfilled, (state) => {
                state.updateStatus = 'successfully'
            })
            .addCase(updatePreferences.rejected, (state) => {
                state.updateStatus = 'failed'
            })
            .addCase(findAllAtecos.pending, (state) => {
                state.findAllAtecosStatus = 'loading'
            })
            .addCase(findAllAtecos.fulfilled, (state, action) => {
                state.findAllAtecosStatus = 'successfully'
                state.findAllAtecosResponse = action.payload
            })
            .addCase(findAllAtecos.rejected, (state) => {
                state.findAllAtecosStatus = 'failed'
            })
    },
    reducers: {
        setFindAllPreferencesStatus: (state, action) => {
            state.findAllStatus = action.payload
        },
        setCreatePreferencesStatus: (state, action) => {
            state.createStatus = action.payload
        },
        setUpdatePreferencesStatus: (state, action) => {
            state.updateStatus = action.payload
        }
    },
})

export const {
    setFindAllPreferencesStatus,
    setCreatePreferencesStatus,
    setUpdatePreferencesStatus
} = preferencesSlice.actions

export default preferencesSlice.reducer