import { ImportCSVResponseDTO } from "../dto";
import { CompanyDTO, EditCompanyRequest, FindAllCompaniesFilters, FindAllCompaniesResponse } from "./dto";
import { CompaniesServiceImpl } from "./serviceImpl";

export interface CompaniesService {
    findAllCompanies(filters: FindAllCompaniesFilters): Promise<FindAllCompaniesResponse>
    deleteCompany(id: string): Promise<void>
    findCompanyById(id: string): Promise<CompanyDTO>
    editCompany(id: string, data: EditCompanyRequest): Promise<void>
    importContact(data: FormData): Promise<ImportCSVResponseDTO>
}

export function NewCompaniesService(): CompaniesService {
    return new CompaniesServiceImpl();
}
