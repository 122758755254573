import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../../lib/redux/hooks";
import Button from "../../../../ui/molecules/button";
import { findAllCompaniesForFilters } from "../../slice";
import { findAllCompanies, setFindAllCompaniesFiltersAtecos, setFindAllCompaniesFiltersCategory, setFindAllCompaniesFiltersCity, setFindAllCompaniesFiltersCountryRegion, setFindAllCompaniesFiltersDepartment, setFindAllCompaniesFiltersEmployees, setFindAllCompaniesFiltersName, setFindAllCompaniesFiltersPrivacy, setFindAllCompaniesFiltersRevenue, setFindAllCompaniesFiltersRole, setFindAllCompaniesFiltersSector, setFindAllCompaniesFiltersSmartphone, setFindAllCompaniesFiltersState, setFindAllCompaniesFiltersZipCode } from "../slice";
import { AtecoFilter } from "./ateco";
import { CategoryFilter } from "./category";
import { CompanyFilter } from "./company";
import { DepartmentFilter } from "./department";
import { EmployeesNumberFilter } from "./employeesNumber";
import { PrivacyFilter } from "./privacy";
import { RevenueFilter } from "./revenue";
import { RoleFilter } from "./role";
import { SectorFilter } from "./sector";
import { SmartphoneFilter } from "./smartphone";
import { StateFilter } from "./state";

export function Filters() {
    const databaseState = useAppSelector(state => state.database)
    const companiesState = useAppSelector(state => state.companies)

    const dispatch = useAppDispatch()

    useEffect(() => {
        dispatch(findAllCompaniesForFilters())
    }, [])

    const filters = Object.entries(companiesState.filters).filter(entr => entr[0] !== 'page' && entr[0] !== 'itemsPerPage' && entr[0] !== 'order' && entr[0] !== 'sort' && entr[1] !== undefined && entr[1].length !== 0).length

    return (
        <div className="bg-white h-full rounded-[12px] border-[1px] border-neutral-200 w-[400px] overflow-auto">
            <div className="px-[24px] py-[12px] flex items-center justify-between">
                <span className="text-text-md text-neutral-800 font-bold">
                    {'Filtri ' + (filters === 0 ? '' : '(' + filters + ')')}
                </span>
                <div className="flex items-center">
                    <Button
                        size={"sm"}
                        iconPosition={"off"}
                        variant={"link"}
                        color={"blue"}
                        label="Pulisci tutti"
                        onClick={() => {
                            dispatch(setFindAllCompaniesFiltersName([]))
                            dispatch(setFindAllCompaniesFiltersRevenue(undefined))
                            dispatch(setFindAllCompaniesFiltersEmployees(undefined))
                            dispatch(setFindAllCompaniesFiltersAtecos([]))
                            dispatch(setFindAllCompaniesFiltersSector([]))
                            dispatch(setFindAllCompaniesFiltersCategory([]))
                            dispatch(setFindAllCompaniesFiltersDepartment([]))
                            dispatch(setFindAllCompaniesFiltersRole([]))
                            dispatch(setFindAllCompaniesFiltersSmartphone(undefined))
                            dispatch(setFindAllCompaniesFiltersPrivacy(undefined))
                            dispatch(setFindAllCompaniesFiltersState(undefined))
                            dispatch(setFindAllCompaniesFiltersCountryRegion([]))
                            dispatch(setFindAllCompaniesFiltersCity([]))
                            dispatch(setFindAllCompaniesFiltersZipCode([]))
                            dispatch(findAllCompanies({
                                atecos: [],
                                category: [],
                                department: [],
                                employees: undefined,
                                itemsPerPage: 10,
                                name: [],
                                order: true,
                                page: 0,
                                privacy: undefined,
                                revenue: undefined,
                                role: [],
                                sector: [],
                                smartphone: undefined,
                                sort: undefined,
                                state: undefined,
                                city: [],
                                countryRegion: [],
                                zipCode: []
                            }))
                        }}
                    />
                    <Button
                        size={"sm"}
                        iconPosition={"off"}
                        variant={"outline"}
                        color={"gray"}
                        label="Applica"
                        onClick={() => dispatch(findAllCompanies(companiesState.filters))}
                    />
                </div>
            </div>
            <CompanyFilter />
            <RevenueFilter />
            <EmployeesNumberFilter />
            <AtecoFilter />
            <SectorFilter />
            <CategoryFilter />
            <DepartmentFilter />
            <RoleFilter />
            <SmartphoneFilter />
            <PrivacyFilter />
            <StateFilter />
        </div>
    )
}