import clsx from "clsx";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../lib/redux/hooks";
import { Spinner } from "../../../ui/molecules/spinner";
import { Banner } from "../../../ui/organisms/banner";
import { NotFound } from "../../../ui/organisms/notFound";
import { setTabValue } from "../slice";
import { CompanyEmployees } from "./companyEmployees";
import { EditCompany } from "./editCompany";
import { Filters } from "./filters";
import { CompaniesList } from "./list";
import { Options } from "./options/options";
import { findAllCompanies, setDeleteCompanyStatus, setEditCompanyStatus, setFindAllCompaniesFiltersPage, setSelectedCompany } from "./slice";


export function Companies() {
    const databaseState = useAppSelector(state => state.database)
    const companiesState = useAppSelector(state => state.companies)

    const dispatch = useAppDispatch()
    const navigate = useNavigate()

    const [isFirstLoadCompanies, setIsFirstLoadCompanies] = useState(true);

    useEffect(() => {
        dispatch(findAllCompanies(companiesState.filters))
        setIsFirstLoadCompanies(false);
    }, [])

    useEffect(() => {
        if (!isFirstLoadCompanies) {
            dispatch(setFindAllCompaniesFiltersPage(0));
            dispatch(findAllCompanies({ ...companiesState.filters, page: 0 }))
        }
    }, [companiesState.filters.order, companiesState.filters.sort])

    useEffect(() => {
        if (!isFirstLoadCompanies)
            dispatch(findAllCompanies(companiesState.filters))
    }, [companiesState.filters.page])

    useEffect(() => {
        let timeoutId: NodeJS.Timeout;
        if (companiesState.deleteCompanyStatus === 'failed' || companiesState.deleteCompanyStatus === 'successfully') {
            if (companiesState.deleteCompanyStatus === 'successfully') {
                dispatch(findAllCompanies(companiesState.filters))
            }
            timeoutId = setTimeout(() => {
                dispatch(setDeleteCompanyStatus('idle'))
            }, 5000);
        }
        return () => {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
        };
    }, [companiesState.deleteCompanyStatus]);

    useEffect(() => {
        let timeoutId: NodeJS.Timeout;
        if (companiesState.editCompanyStatus === 'failed' || companiesState.editCompanyStatus === 'successfully') {
            if (companiesState.editCompanyStatus === 'successfully') {
                dispatch(setSelectedCompany(undefined))
                dispatch(findAllCompanies(companiesState.filters))
            }
            timeoutId = setTimeout(() => {
                dispatch(setEditCompanyStatus('idle'))
            }, 5000);
        }
        return () => {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
        };
    }, [companiesState.editCompanyStatus]);

    useEffect(() => {
        if (companiesState.importCSVStatus === 'failed' || companiesState.importCSVStatus === 'successfully') {
            navigate('/database')
            dispatch(setTabValue(1))
        }
    }, [companiesState.importCSVStatus]);

    return (
        <div className="flex flex-col h-full pl-[24px] py-[24px] gap-4">
            <div className="absolute flex flex-col gap-4 right-6">
                <Banner
                    label={"Azienda eliminata."}
                    visible={companiesState.deleteCompanyStatus === 'successfully'}
                    closeAction={function (): void {
                        dispatch(setDeleteCompanyStatus('idle'))
                    }}
                    type={"success"}
                />
                <Banner
                    label={"Si è verificato un errore durante l'eliminazione dell'azienda."}
                    visible={companiesState.deleteCompanyStatus === 'failed'}
                    closeAction={function (): void {
                        dispatch(setDeleteCompanyStatus('idle'))
                    }}
                    type={"error"}
                />
                <Banner
                    label={"Azienda aggiornata."}
                    visible={companiesState.editCompanyStatus === 'successfully'}
                    closeAction={function (): void {
                        dispatch(setEditCompanyStatus('idle'))
                    }}
                    type={"success"}
                />
                <Banner
                    label={"Si è verificato un errore durante la modifica dell'azienda."}
                    visible={companiesState.editCompanyStatus === 'failed'}
                    closeAction={function (): void {
                        dispatch(setEditCompanyStatus('idle'))
                    }}
                    type={"error"}
                />
            </div>
            <div className="h-full flex gap-4">
                <div className={clsx("transition-all duration-300 h-full w-[400px] flex-shrink-0", {
                    "block": databaseState.filtersActive,
                    "hidden": !databaseState.filtersActive,
                })}>
                    <Filters />
                </div>
                <div className="pr-[24px] h-full w-full overflow-auto">
                    {
                        companiesState.findAllStatus === 'loading' ?
                            <div className="w-full h-[45%] flex items-center justify-center">
                                <Spinner />
                            </div> :
                            companiesState.findAllResponse?.data.length === 0 || companiesState.findAllResponse?.data === null ?
                                <NotFound /> :
                                <CompaniesList />
                    }
                </div>
                <div className={clsx("absolute flex-shrink-0 w-[290px] h-full right-0 top-0 bg-white transition-all shadow-md", {
                    'hidden': !databaseState.optionsActive
                })}>
                    <Options />
                </div>
                <div className={clsx("absolute flex-shrink-0 w-[480px] h-full right-0 top-0 bg-neutral-50 transition-all shadow-lg overflow-auto", {
                    'hidden': companiesState.selectedCompany === undefined
                })}>
                    <EditCompany />
                </div>
                <div className={clsx("absolute flex-shrink-0 w-[480px] h-full right-0 top-0 bg-neutral-50 transition-all shadow-lg overflow-auto", {
                    'hidden': companiesState.findEmployeesStatus !== 'successfully'
                })}>
                    <CompanyEmployees />
                </div>
            </div>
        </div>
    )
}