import React, { useEffect, useState } from "react";
import "./FileUploader.css";
import { FileUploadStatusMap, FileUploadStyleMap, FileUploaderProps } from "./FileUploader.types";

const FileUploader: React.FC<FileUploaderProps> = ({
    ...props
}) => {
    const [inputRef, setInputRef] = useState<HTMLInputElement | null>(null);
    const [dragged, setDragged] = useState<boolean>(false);

    const [status, setStatus] = useState<'default' | 'hover' | 'dragon' | 'focus' | 'error' | 'disabled'>('default')

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files !== null && event.target.files.length > 0) {
            props.onChange && props.onChange(Array.from(event.target.files))
        }
    };

    const max = 10 * 1024 * 1024

    useEffect(() => {
        if (props.disabled) {
            setStatus('disabled')
        } else {
            setStatus('default')
        }
    }, [props.disabled])

    useEffect(() => {
        if (dragged) {
            setStatus('dragon')
        } else {
            setStatus('default')
        }
    }, [dragged])

    useEffect(() => {
        if (props.error) {
            setStatus('error')
        } else {
            setStatus('default')
        }
    }, [props.error])

    const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        event.stopPropagation();

        if (!props.disabled) {
            setDragged(false);
            const files = event.dataTransfer.files;
            props.onChange && props.onChange(Array.from(files));
        }
    };

    return (
        <div>
            <div
                id={props.id}
                draggable
                onClick={() => inputRef?.click()}
                onDragOver={e => {
                    if (!props.disabled) {
                        e.preventDefault();
                        e.stopPropagation();
                        e.dataTransfer.dropEffect = 'copy';
                        !props.disabled && setDragged(true);
                    }
                }}
                onDragEnter={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    if (!props.disabled) {
                        setDragged(true);
                    }
                }}
                onDragLeave={e => {
                    if (!props.disabled) {
                        e.preventDefault();
                        e.stopPropagation();
                        !props.disabled && setDragged(false);
                    }
                }}
                onDrop={handleDrop}
                onProgress={e => console.log(e)}
                onLoad={e => console.log(e)}
                onFocus={() => !props.disabled && !props.error && setStatus('focus')}
                onBlur={() => !props.disabled && !props.error && setStatus('default')}
                onMouseEnter={() => !props.disabled && !props.error && setStatus('hover')}
                onMouseLeave={() => !props.disabled && !props.error && setStatus('default')}
                style={{
                    ...{
                        borderRadius: '8px',
                        display: "flex",
                        justifyContent: 'center',
                        cursor: 'pointer',
                        gap: '16px'
                    },
                    ...FileUploadStatusMap.get(status)?.style,
                    ...FileUploadStyleMap.get(props.style)
                }}
            >
                {
                    props.style === 'large' ? (
                        <>
                            {FileUploadStatusMap.get(status)?.icon}
                            <span className="upload-files-span">
                                {'Drag & drop files or '}
                                <span style={{ color: 'rgba(53, 113, 233, 1)' }}>
                                    browse files
                                </span>
                                <p className="upload-files-span-types" style={{ color: status === 'error' ? 'rgba(253, 102, 91, 1)' : 'rgba(173, 173, 214, 1)' }}>
                                    {status === 'error' ? 'Formato non supportato' : 'PDF, DOC, TXT, ZIP - Max 2MB'}
                                </p>
                            </span>
                        </>
                    ) : (
                        <div style={{ display: 'flex', flexDirection: 'column', gap: '16px', alignItems: 'flex-start' }}>
                            <div style={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
                                {FileUploadStatusMap.get(status)?.icon}
                                <span className="upload-files-span">
                                    {'Drag & drop files or '}
                                    <span style={{ color: 'rgba(53, 113, 233, 1)' }}>
                                        browse files
                                    </span>
                                </span>
                            </div>
                            <span className="upload-files-span-dimensions" style={{ color: status === 'error' ? 'rgba(253, 102, 91, 1)' : 'rgba(173, 173, 214, 1)' }}>Dimensione massima del file: 10 MB</span>
                        </div>
                    )
                }
            </div >
            <input draggable name={props.id} id={props.id} ref={ref => { if (!props.disabled) { setInputRef(ref) } }} multiple={props.multiple} type="file" style={{ display: 'none' }} onChange={handleChange} />
        </div >
    )
};

export default FileUploader;