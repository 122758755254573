import { Link } from "react-router-dom";
import { colors } from "../../colors";
import { ChevronIcon } from "../../icons/chevron";
import { HeaderProps } from "./dto";

export function Header(props: HeaderProps) {
    return (
        <div style={{ height: '64px !important', padding: '0px 24px', display: 'flex', alignItems: 'center', justifyContent: 'flex-start', gap: '24px' }}>
            <div className="flex items-center gap-[8px] flex-shrink-0 h-16">
                {
                    props.backPath &&
                    <Link className="cursor-pointer" to={props.backPath} >
                        <ChevronIcon color={colors.brandPrimary[600]} size={24} direction="l" />
                    </Link>
                }
                <div className="text-heading-sm font-bold text-neutral-800" style={{ width: 'max-content', display: 'block' }}>{props.label}</div>
            </div>
            <div className="h-[100%] flex-1 flex-shrink-0">
                {props.children}
            </div>
        </div>
    )
}