import clsx from "clsx";
import Papa from 'papaparse';
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../lib/redux/hooks";
import { Layout } from "../../../../ui/layout";
import Button from "../../../../ui/molecules/button";
import { SelectCustom } from "../../../../ui/molecules/select";
import FileUploader from "../../../../ui/organisms/fileUploader/FileUploader";
import { MenuItems } from "../../../../ui/organisms/navbar/dto";
import { setTabValue } from "../../slice";
import { PeopleHeader } from "../dto";
import { importCSV } from "../slice";

export function ImportPeople() {
    const [error, setError] = useState(false)
    const [name, setName] = useState('')
    const [delimiter, setDelimiter] = useState('')
    const [fields, setFields] = useState<string[]>([])
    const [file, setFile] = useState<File | null>(null)
    const [rows, setRows] = useState<Record<string, any>[]>([]);
    const [headers, setHeaders] = useState<PeopleHeader>({
        name: [],
        surname: [],
        role: [],
        department: [],
        phone: [],
        email: [],
        linkedinProfile: [],
        privacy: [],
        companyName: [],
        companyVat: [],
        companyPhone: [],
    })

    const formData = new FormData()

    const options = fields.filter(field => (
        !headers.name.includes(field) &&
        !headers.surname.includes(field) &&
        !headers.role.includes(field) &&
        !headers.department.includes(field) &&
        !headers.phone.includes(field) &&
        !headers.email.includes(field) &&
        !headers.linkedinProfile.includes(field) &&
        !headers.privacy.includes(field) &&
        !headers.companyName.includes(field) &&
        !headers.companyVat.includes(field) &&
        !headers.companyPhone.includes(field)
    ))

    const handleFileChange = (e: File[]) => {
        const file = e[0]
        setError(false)
        if (file) {
            if (file.type !== 'text/csv') {
                setError(true)
                setName('')
                setDelimiter('')
                setFields([])
                setRows([]);
                setFile(null)
            } else {
                setName(file.name)
                Papa.parse(file, {
                    complete: (result) => {
                        setDelimiter(result.meta.delimiter)
                        setFields(result.meta.fields ?? [])
                        //@ts-ignore
                        setRows(result.data);
                        setFile(file)
                    },
                    header: true,
                    dynamicTyping: true,
                    skipEmptyLines: true,
                });
            }
        }
    };

    const dispatch = useAppDispatch()
    const navigate = useNavigate()

    const peopleState = useAppSelector(state => state.people)

    useEffect(() => {
        if (peopleState.importCSVStatus === 'failed' || peopleState.importCSVStatus === 'successfully') {
            navigate('/database')
            dispatch(setTabValue(0))
        }
    }, [peopleState.importCSVStatus]);

    return (
        <Layout
            menuItem={MenuItems.DATABASE}
            breadcrumbItems={['Database', 'Importa contatti']}
            headerLabel="Importa contatti"
            headerBackPath="/database"
        >
            <div
                className="p-6 flex flex-col gap-10 justify-between max-h-full"
            >
                <div className={clsx("flex flex-col gap-3 flex-1")}>
                    <div className="flex items-center w-full justify-between">
                        <span className="text-text-lg font-medium text-neutral-700">{name ? name : 'Seleziona un file .csv'}</span>
                        {
                            fields.length > 0 &&
                            // <div className="flex-1 flex items-end">
                            <Button
                                size={"md"}
                                iconPosition={"off"}
                                variant={"solid"}
                                color={"blue"}
                                label="Importa contatti"
                                onClick={() => {
                                    const filteredHeaders = Object.fromEntries(
                                        Object.entries(headers).filter(([key, value]) => Array.isArray(value) && value.length > 0)
                                    );
                                    formData.delete('file')
                                    formData.delete('headers')
                                    formData.delete('delimiter')
                                    if (file !== null)
                                        formData.append('file', file)
                                    formData.append('headers', JSON.stringify(filteredHeaders))
                                    formData.append('delimiter', delimiter)
                                    dispatch(importCSV(formData))
                                }}
                            />
                            // </div>
                        }
                    </div>
                    {
                        file === null &&
                        <FileUploader
                            error={error}
                            id={"import-contacts-people"}
                            style={"large"}
                            onChange={e => handleFileChange(e)}
                        />
                    }
                    {rows.length > 0 && (
                        <div className="overflow-auto max-h-80 mt-4">
                            <table className="min-w-full border-collapse border border-gray-200">
                                <thead>
                                    <tr>
                                        {fields.map((field) => (
                                            <th
                                                key={field}
                                                className="border border-gray-300 p-2 bg-gray-100 font-semibold text-text-sm text-neutral-800"
                                            >
                                                {field}
                                            </th>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody>
                                    {rows.slice(0, 3).map((row, rowIndex) => (
                                        <tr key={rowIndex}>
                                            {fields.map(field => (
                                                <td
                                                    key={field}
                                                    className="border border-gray-300 p-2 text-text-sm text-neutral-600"
                                                >
                                                    {row[field] || ''}
                                                </td>
                                            ))}
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    )}
                </div>
                {
                    fields.length > 0 && (
                        <div className="flex flex-wrap gap-y-4 gap-x-10 border border-neutral-200 bg-white rounded-2xl p-8 overflow-auto">
                            <div className="flex flex-col gap-2 items-start bg-neutral-100 p-4 rounded-[16px] border border-neutral-200">
                                <span className="w-64 text-text-md font-bold text-neutral-700">Nome</span>
                                <div className="w-96">
                                    <SelectCustom
                                        defaultValue={headers.name[0]}
                                        isClearable
                                        onChange={e => {
                                            if (e === undefined) {
                                                const newHeaders = { ...headers }
                                                newHeaders.name = []
                                                setHeaders(newHeaders)
                                            }
                                            if (typeof e === 'string') {
                                                const newHeaders = { ...headers }
                                                newHeaders.name = [e]
                                                setHeaders(newHeaders)
                                            }
                                        }}
                                        placeholder={"Seleziona campo per il nome"}
                                        options={options.map(field => ({ label: field, value: field }))}
                                    />
                                </div>
                            </div>
                            <div className="flex flex-col gap-2 items-start bg-neutral-100 p-4 rounded-[16px] border border-neutral-200">
                                <span className="w-64 text-text-md font-bold text-neutral-700">Cognome</span>
                                <div className="w-96">
                                    <SelectCustom
                                        defaultValue={headers.surname[0]}
                                        isClearable
                                        onChange={e => {
                                            if (e === undefined) {
                                                const newHeaders = { ...headers }
                                                newHeaders.surname = []
                                                setHeaders(newHeaders)
                                            }
                                            if (typeof e === 'string') {
                                                const newHeaders = { ...headers }
                                                newHeaders.surname = [e]
                                                setHeaders(newHeaders)
                                            }
                                        }}
                                        placeholder={"Seleziona campo per il cognome"}
                                        options={options.map(field => ({ label: field, value: field }))}
                                    />
                                </div>
                            </div>
                            <div className="flex flex-col gap-2 items-start bg-neutral-100 p-4 rounded-[16px] border border-neutral-200">
                                <span className="w-64 text-text-md font-bold text-neutral-700">Ruolo</span>
                                <div className="w-96">
                                    <SelectCustom
                                        defaultValue={headers.role[0]}
                                        isClearable
                                        onChange={e => {
                                            if (e === undefined) {
                                                const newHeaders = { ...headers }
                                                newHeaders.role = []
                                                setHeaders(newHeaders)
                                            }
                                            if (typeof e === 'string') {
                                                const newHeaders = { ...headers }
                                                newHeaders.role = [e]
                                                setHeaders(newHeaders)
                                            }
                                        }}
                                        placeholder={"Seleziona campo per il ruolo"}
                                        options={options.map(field => ({ label: field, value: field }))}
                                    />
                                </div>
                            </div>
                            <div className="flex flex-col gap-2 items-start bg-neutral-100 p-4 rounded-[16px] border border-neutral-200">
                                <span className="w-64 text-text-md font-bold text-neutral-700">Settore</span>
                                <div className="w-96">
                                    <SelectCustom
                                        defaultValue={headers.department[0]}
                                        isClearable
                                        onChange={e => {
                                            if (e === undefined) {
                                                const newHeaders = { ...headers }
                                                newHeaders.department = []
                                                setHeaders(newHeaders)
                                            }
                                            if (typeof e === 'string') {
                                                const newHeaders = { ...headers }
                                                newHeaders.department = [e]
                                                setHeaders(newHeaders)
                                            }
                                        }}
                                        placeholder={"Seleziona campo per il settore"}
                                        options={options.map(field => ({ label: field, value: field }))}
                                    />
                                </div>
                            </div>
                            <div className="flex flex-col gap-2 items-start bg-neutral-100 p-4 rounded-[16px] border border-neutral-200">
                                <span className="w-64 text-text-md font-bold text-neutral-700">Cellulare</span>
                                <div className="w-96">
                                    <SelectCustom
                                        defaultValue={headers.phone[0]}
                                        isClearable
                                        onChange={e => {
                                            if (e === undefined) {
                                                const newHeaders = { ...headers }
                                                newHeaders.phone = []
                                                setHeaders(newHeaders)
                                            }
                                            if (typeof e === 'string') {
                                                const newHeaders = { ...headers }
                                                newHeaders.phone = [e]
                                                setHeaders(newHeaders)
                                            }
                                        }}
                                        placeholder={"Seleziona campo per il cellulare"}
                                        options={options.map(field => ({ label: field, value: field }))}
                                    />
                                </div>
                            </div>
                            <div className="flex flex-col gap-2 items-start bg-neutral-100 p-4 rounded-[16px] border border-neutral-200">
                                <span className="w-64 text-text-md font-bold text-neutral-700">Email</span>
                                <div className="w-96">
                                    <SelectCustom
                                        defaultValue={headers.email[0]}
                                        isClearable
                                        onChange={e => {
                                            if (e === undefined) {
                                                const newHeaders = { ...headers }
                                                newHeaders.email = []
                                                setHeaders(newHeaders)
                                            }
                                            if (typeof e === 'string') {
                                                const newHeaders = { ...headers }
                                                newHeaders.email = [e]
                                                setHeaders(newHeaders)
                                            }
                                        }}
                                        placeholder={"Seleziona campo per l'email"}
                                        options={options.map(field => ({ label: field, value: field }))}
                                    />
                                </div>
                            </div>
                            <div className="flex flex-col gap-2 items-start bg-neutral-100 p-4 rounded-[16px] border border-neutral-200">
                                <span className="w-64 text-text-md font-bold text-neutral-700">Profilo Linkedin</span>
                                <div className="w-96">
                                    <SelectCustom
                                        defaultValue={headers.linkedinProfile[0]}
                                        isClearable
                                        onChange={e => {
                                            if (e === undefined) {
                                                const newHeaders = { ...headers }
                                                newHeaders.linkedinProfile = []
                                                setHeaders(newHeaders)
                                            }
                                            if (typeof e === 'string') {
                                                const newHeaders = { ...headers }
                                                newHeaders.linkedinProfile = [e]
                                                setHeaders(newHeaders)
                                            }
                                        }}
                                        placeholder={"Seleziona campo per il profilo linkedin"}
                                        options={options.map(field => ({ label: field, value: field }))}
                                    />
                                </div>
                            </div>
                            <div className="flex flex-col gap-2 items-start bg-neutral-100 p-4 rounded-[16px] border border-neutral-200">
                                <span className="w-64 text-text-md font-bold text-neutral-700">Privacy</span>
                                <div className="w-96">
                                    <SelectCustom
                                        defaultValue={headers.privacy[0]}
                                        isClearable
                                        onChange={e => {
                                            if (e === undefined) {
                                                const newHeaders = { ...headers }
                                                newHeaders.privacy = []
                                                setHeaders(newHeaders)
                                            }
                                            if (typeof e === 'string') {
                                                const newHeaders = { ...headers }
                                                newHeaders.privacy = [e]
                                                setHeaders(newHeaders)
                                            }
                                        }}
                                        placeholder={"Seleziona campo per la privacy"}
                                        options={options.map(field => ({ label: field, value: field }))}
                                    />
                                </div>
                            </div>
                            <div className="flex flex-col gap-2 items-start bg-neutral-100 p-4 rounded-[16px] border border-neutral-200">
                                <span className="w-64 text-text-md font-bold text-neutral-700">Nome azienda</span>
                                <div className="w-96">
                                    <SelectCustom
                                        defaultValue={headers.companyName[0]}
                                        isClearable
                                        onChange={e => {
                                            if (e === undefined) {
                                                const newHeaders = { ...headers }
                                                newHeaders.companyName = []
                                                setHeaders(newHeaders)
                                            }
                                            if (typeof e === 'string') {
                                                const newHeaders = { ...headers }
                                                newHeaders.companyName = [e]
                                                setHeaders(newHeaders)
                                            }
                                        }}
                                        placeholder={"Seleziona campo per il nome dell'azienda"}
                                        options={options.map(field => ({ label: field, value: field }))}
                                    />
                                </div>
                            </div>
                            <div className="flex flex-col gap-2 items-start bg-neutral-100 p-4 rounded-[16px] border border-neutral-200">
                                <span className="w-64 text-text-md font-bold text-neutral-700">P.IVA azienda</span>
                                <div className="w-96">
                                    <SelectCustom
                                        defaultValue={headers.companyVat[0]}
                                        isClearable
                                        onChange={e => {
                                            if (e === undefined) {
                                                const newHeaders = { ...headers }
                                                newHeaders.companyVat = []
                                                setHeaders(newHeaders)
                                            }
                                            if (typeof e === 'string') {
                                                const newHeaders = { ...headers }
                                                newHeaders.companyVat = [e]
                                                setHeaders(newHeaders)
                                            }
                                        }}
                                        placeholder={"Seleziona campo per la P.IVA azienda"}
                                        options={options.map(field => ({ label: field, value: field }))}
                                    />
                                </div>
                            </div>
                            <div className="flex flex-col gap-2 items-start bg-neutral-100 p-4 rounded-[16px] border border-neutral-200">
                                <span className="w-64 text-text-md font-bold text-neutral-700">Telefono azienda</span>
                                <div className="w-96">
                                    <SelectCustom
                                        defaultValue={headers.companyPhone}
                                        isClearable
                                        isMulti
                                        onChange={e => {
                                            if (e === undefined || e.length === 0) {
                                                const newHeaders = { ...headers }
                                                newHeaders.companyPhone = []
                                                setHeaders(newHeaders)
                                            } else if (e.length > 0 && typeof e !== 'string') {
                                                const newHeaders = { ...headers }
                                                newHeaders.companyPhone = [...e]
                                                setHeaders(newHeaders)
                                            }
                                        }}
                                        placeholder={"Seleziona campo per il telefono dell'azienda"}
                                        options={options.map(field => ({ label: field, value: field })).concat(headers.companyPhone.map(phone => ({ label: phone, value: phone })))}
                                    />
                                </div>
                            </div>
                        </div>
                    )
                }
            </div>
        </Layout>
    )
}