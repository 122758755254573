import React from "react";
import { IconProps } from "./dto";

export function PersonalCardIcon(props: IconProps) {
    return (
        <svg width={props.size} height={props.size} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.75 16.3125H5.25C1.9425 16.3125 0.9375 15.3075 0.9375 12V6C0.9375 2.6925 1.9425 1.6875 5.25 1.6875H12.75C16.0575 1.6875 17.0625 2.6925 17.0625 6V12C17.0625 15.3075 16.0575 16.3125 12.75 16.3125ZM5.25 2.8125C2.565 2.8125 2.0625 3.3225 2.0625 6V12C2.0625 14.6775 2.565 15.1875 5.25 15.1875H12.75C15.435 15.1875 15.9375 14.6775 15.9375 12V6C15.9375 3.3225 15.435 2.8125 12.75 2.8125H5.25Z" fill={props.color} />
            <path d="M14.25 6.5625H10.5C10.1925 6.5625 9.9375 6.3075 9.9375 6C9.9375 5.6925 10.1925 5.4375 10.5 5.4375H14.25C14.5575 5.4375 14.8125 5.6925 14.8125 6C14.8125 6.3075 14.5575 6.5625 14.25 6.5625Z" fill={props.color} />
            <path d="M14.25 9.5625H11.25C10.9425 9.5625 10.6875 9.3075 10.6875 9C10.6875 8.6925 10.9425 8.4375 11.25 8.4375H14.25C14.5575 8.4375 14.8125 8.6925 14.8125 9C14.8125 9.3075 14.5575 9.5625 14.25 9.5625Z" fill={props.color} />
            <path d="M14.25 12.5625H12.75C12.4425 12.5625 12.1875 12.3075 12.1875 12C12.1875 11.6925 12.4425 11.4375 12.75 11.4375H14.25C14.5575 11.4375 14.8125 11.6925 14.8125 12C14.8125 12.3075 14.5575 12.5625 14.25 12.5625Z" fill={props.color} />
            <path d="M6.37508 9.02994C5.31758 9.02994 4.45508 8.16744 4.45508 7.10994C4.45508 6.05244 5.31758 5.18994 6.37508 5.18994C7.43258 5.18994 8.29508 6.05244 8.29508 7.10994C8.29508 8.16744 7.43258 9.02994 6.37508 9.02994ZM6.37508 6.31494C5.94008 6.31494 5.58008 6.67494 5.58008 7.10994C5.58008 7.54494 5.94008 7.90494 6.37508 7.90494C6.81008 7.90494 7.17008 7.54494 7.17008 7.10994C7.17008 6.67494 6.81008 6.31494 6.37508 6.31494Z" fill={props.color} />
            <path d="M8.99993 12.8099C8.71493 12.8099 8.46743 12.5924 8.43743 12.2999C8.35493 11.4899 7.70243 10.8374 6.88493 10.7624C6.53993 10.7324 6.19493 10.7324 5.84993 10.7624C5.03243 10.8374 4.37993 11.4824 4.29743 12.2999C4.26743 12.6074 3.98993 12.8399 3.68243 12.8024C3.37493 12.7724 3.14993 12.4949 3.17993 12.1874C3.31493 10.8374 4.38743 9.76495 5.74493 9.64495C6.15743 9.60745 6.57743 9.60745 6.98993 9.64495C8.33993 9.77245 9.41993 10.8449 9.55493 12.1874C9.58493 12.4949 9.35993 12.7724 9.05243 12.8024C9.03743 12.8099 9.01493 12.8099 8.99993 12.8099Z" fill={props.color} />
        </svg>

    )
}