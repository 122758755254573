import clsx from "clsx"
import { cloneElement, useState } from "react"
import { colors } from "../../colors"
import { MinusIcon } from "../../icons/minus"
import { PlusIcon } from "../../icons/plus"
import { AccordionProps } from "./dto"

export function AccordionFilter(props: AccordionProps) {
    const [open, setOpen] = useState(false)

    return (
        <div className={clsx("px-6 border-t-[1px] transition-all", {
            "bg-white": !open,
            "bg-neutral-50": open,
        })}>
            <div
                className="h-[56px] w-full flex items-center cursor-pointer justify-between transition-all"
                onClick={() => setOpen(!open)}
            >
                <div className="flex items-center gap-2">
                    {cloneElement(props.icon, { size: 18, color: open ? colors.brandPrimary[600] : colors.neutral[500] })}
                    <span className={clsx("text-label-md font-semibold", {
                        'text-neutral-600': !open,
                        'text-brandPrimary-600': open,
                    })}>{props.label}</span>
                </div>
                <div className="flex items-center gap-2">
                    {
                        props.showClear &&
                        <span
                            onClick={e => {
                                e.preventDefault()
                                e.stopPropagation()
                                props.clearAction()
                            }}
                            className="text-label-xs text-neutral-500 font-medium"
                        >
                            Pulisci
                        </span>
                    }
                    {
                        open ?
                            <MinusIcon color={colors.neutral[500]} size={16} /> :
                            <PlusIcon color={colors.neutral[500]} size={16} />
                    }
                </div>
            </div>
            <div className={clsx('pb-2 flex flex-wrap gap-1', {
                "block": !open,
                'hidden': open
            })}>
                {props.options?.map(option =>
                    <div
                        onClick={() => props.deleteFilter(props.options?.find(opt => opt.value === option.value)!)}
                        key={option.value}
                        className="rounded-[4px] px-2 py-1 gap-1 flex items-center bg-brandPrimary-100 border-[0.5px] border-brandPrimary-400 w-fit"
                    >
                        <span className="text-label-xs text-brandPrimary-900">{option.label}</span>
                        <div className={clsx("rotate-45 cursor-pointer", { "hidden": props.hideDelete })}><PlusIcon color={colors.neutral[500]} size={15} /></div>
                    </div>
                )}
            </div>
            <div className={clsx(
                'transition-all pb-5',
                {
                    "block": open,
                    'hidden': !open
                }
            )}>
                {props.children}
            </div>
        </div>
    )
}