import { useEffect } from "react"
import { useAppDispatch, useAppSelector } from "../../../lib/redux/hooks"
import { colors } from "../../../ui/colors"
import { PlusIcon } from "../../../ui/icons/plus"
import Avatar from "../../../ui/molecules/avatar"
import Button from "../../../ui/molecules/button"
import { Spinner } from "../../../ui/molecules/spinner"
import { EditCompanyForm } from "./editCompanyForm"
import { editCompany, findCompany, setSelectedCompany } from "./slice"

export function EditCompany() {
    const companiesState = useAppSelector(state => state.companies)

    const dispatch = useAppDispatch()

    useEffect(() => {
        if (companiesState.selectedCompany !== undefined) {
            dispatch(findCompany(companiesState.selectedCompany))
        }
    }, [companiesState.selectedCompany])

    return (
        <>
            {
                companiesState.findCompanyStatus === 'loading' ?
                    <Spinner /> :
                    <>
                        <div className="px-6 pt-4 flex items-center justify-between">
                            <Avatar
                                type="info"
                                imageUrl=""
                                firstRow={companiesState.findCompanyResponse?.name!}
                                size="md"
                                altTextInitials={companiesState.findCompanyResponse?.name.slice(0, 1).toUpperCase()!}
                            />
                            <div className="flex items-center gap-4">
                                <Button size={"sm"} iconPosition={"off"} variant={"outline"} color={"blue"} label="Salva" onClick={() => dispatch(editCompany({ id: companiesState.findCompanyResponse?.id!, data: companiesState.editCompanyRequest }))} />
                                <div className="cursor-pointer rotate-45" onClick={() => dispatch(setSelectedCompany(undefined))}>
                                    <PlusIcon color={colors.neutral[500]} size={20} />
                                </div>
                            </div>
                        </div>
                        <div className="py-4">
                            <EditCompanyForm />
                        </div>
                    </>
            }
        </>
    )
}