import React from "react";
import { IconProps } from "./dto";

export function BriefcaseIcon(props: IconProps) {
    return (
        <svg width={props.size} height={props.size} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.9999 17.0624H5.99993C2.53493 17.0624 1.88993 15.4499 1.72493 13.8824L1.16243 7.87495C1.07993 7.08745 1.05743 5.92495 1.83743 5.05495C2.51243 4.30495 3.62993 3.94495 5.24993 3.94495H12.7499C14.3774 3.94495 15.4949 4.31245 16.1624 5.05495C16.9424 5.92495 16.9199 7.08745 16.8374 7.88245L16.2749 13.8749C16.1099 15.4499 15.4649 17.0624 11.9999 17.0624ZM5.24993 5.06245C3.98243 5.06245 3.11243 5.30995 2.66993 5.80495C2.30243 6.20995 2.18243 6.83245 2.27993 7.76245L2.84243 13.7699C2.96993 14.9549 3.29243 15.9374 5.99993 15.9374H11.9999C14.6999 15.9374 15.0299 14.9549 15.1574 13.7624L15.7199 7.76995C15.8174 6.83245 15.6974 6.20995 15.3299 5.80495C14.8874 5.30995 14.0174 5.06245 12.7499 5.06245H5.24993Z" fill={props.color} />
            <path d="M12 5.0625C11.6925 5.0625 11.4375 4.8075 11.4375 4.5V3.9C11.4375 2.565 11.4375 2.0625 9.6 2.0625H8.4C6.5625 2.0625 6.5625 2.565 6.5625 3.9V4.5C6.5625 4.8075 6.3075 5.0625 6 5.0625C5.6925 5.0625 5.4375 4.8075 5.4375 4.5V3.9C5.4375 2.58 5.4375 0.9375 8.4 0.9375H9.6C12.5625 0.9375 12.5625 2.58 12.5625 3.9V4.5C12.5625 4.8075 12.3075 5.0625 12 5.0625Z" fill={props.color} />
            <path d="M9 12.5625C6.9375 12.5625 6.9375 11.2875 6.9375 10.5225V9.75C6.9375 8.6925 7.1925 8.4375 8.25 8.4375H9.75C10.8075 8.4375 11.0625 8.6925 11.0625 9.75V10.5C11.0625 11.28 11.0625 12.5625 9 12.5625ZM8.0625 9.5625C8.0625 9.6225 8.0625 9.69 8.0625 9.75V10.5225C8.0625 11.295 8.0625 11.4375 9 11.4375C9.9375 11.4375 9.9375 11.3175 9.9375 10.515V9.75C9.9375 9.69 9.9375 9.6225 9.9375 9.5625C9.8775 9.5625 9.81 9.5625 9.75 9.5625H8.25C8.19 9.5625 8.1225 9.5625 8.0625 9.5625Z" fill={props.color} />
            <path d="M10.4999 11.0776C10.2224 11.0776 9.97488 10.8676 9.94488 10.5826C9.90738 10.2751 10.1249 9.99006 10.4324 9.95256C12.4124 9.70506 14.3099 8.95506 15.9074 7.79256C16.1549 7.60506 16.5074 7.66506 16.6949 7.92006C16.8749 8.16756 16.8224 8.52006 16.5674 8.70756C14.8124 9.98256 12.7424 10.8001 10.5674 11.0776C10.5449 11.0776 10.5224 11.0776 10.4999 11.0776Z" fill={props.color} />
            <path d="M7.50005 11.085C7.47755 11.085 7.45505 11.085 7.43255 11.085C5.37755 10.8525 3.37505 10.1025 1.64255 8.91755C1.38755 8.74505 1.32005 8.39255 1.49255 8.13755C1.66505 7.88255 2.01755 7.81505 2.27255 7.98755C3.85505 9.06755 5.67755 9.75005 7.55255 9.96755C7.86005 10.005 8.08505 10.2825 8.04755 10.59C8.02505 10.875 7.78505 11.085 7.50005 11.085Z" fill={props.color} />
        </svg>

    )
}