import axios from "axios";
import qs from "qs";
import { useState } from "react";
import { regions } from "../../../../lib/places/italianRegions";
import { provinceComuniMap } from "../../../../lib/places/listaComuni";
import { useAppDispatch, useAppSelector } from "../../../../lib/redux/hooks";
import { GlobeIcon } from "../../../../ui/icons/globe";
import { SelectCustomAsync } from "../../../../ui/molecules/asyncSelect";
import { SelectCustom } from "../../../../ui/molecules/select";
import { AccordionFilter } from "../../../../ui/organisms/accordionFilter";
import { PlaceFeatureDTO } from "../../dto";
import { setFindAllCompaniesFiltersCity, setFindAllCompaniesFiltersCountryRegion, setFindAllCompaniesFiltersState, setFindAllCompaniesFiltersZipCode } from "../slice";
import { comuneCapMap } from "../../../../lib/places/cap";

export function StateFilter() {
    const companyState = useAppSelector(state => state.companies)

    const [provinces, setProvinces] = useState<{ value: string, label: string }[]>([])

    const dispatch = useAppDispatch()

    const [isoCountries, setIsoCountries] = useState<{ value: string, code: string }[]>([
        { value: 'Italia', code: "IT" },
        { value: 'Francia', code: "FR" },
        { value: 'Germania', code: "DE" },
        { value: "Stati Uniti d'America", code: "US" },
        { value: "Regno Unito", code: "UK" },
    ])

    const defaultOptions = [
        { label: 'Italia', value: 'Italia', },
        { label: 'Francia', value: 'Francia' },
        { label: 'Germania', value: 'Germania' },
        { label: "Stati Uniti d'America", value: 'Stati Uniti' },
        { label: 'Regno Unito', value: 'Regno Unito' }
    ];

    const fetchCountries = async (inputValue: string): Promise<{ value: string, label: string }[]> => {
        if (!inputValue) {
            return defaultOptions;
        }

        try {
            const response = await axios.get('https://api.mapbox.com/search/geocode/v6/forward', {
                params: {
                    q: inputValue,
                    access_token: 'pk.eyJ1IjoiZ2l1bGlhbWFkZmFybSIsImEiOiJjbHphemJlbmYwcWwyMmtzZHE4dWZxNXNkIn0.RomI9T9UfQnlT1iEu3ieNw',
                    types: ['country'],
                    language: 'it'
                },
                paramsSerializer: params => {
                    return qs.stringify(params, {
                        arrayFormat: 'repeat',
                        encode: false
                    });
                }
            });

            const countries = response.data.features.map((feature: PlaceFeatureDTO) => ({
                label: feature.properties.name,
                value: feature.properties.name
            }));

            setIsoCountries(response.data.features.map((feature: PlaceFeatureDTO) => ({ code: feature.properties.context.country.country_code, value: feature.properties.name })))

            return countries;
        } catch (error) {
            console.error("Errore durante il fetch delle nazioni: ", error);
            return [];
        }
    };

    const fetchCities = async (inputValue: string): Promise<{ value: string, label: string }[]> => {
        if (!inputValue) {
            return defaultOptions;
        }

        try {
            const response = await axios.get('https://api.mapbox.com/search/geocode/v6/forward', {
                params: {
                    q: inputValue,
                    access_token: 'pk.eyJ1IjoiZ2l1bGlhbWFkZmFybSIsImEiOiJjbHphemJlbmYwcWwyMmtzZHE4dWZxNXNkIn0.RomI9T9UfQnlT1iEu3ieNw',
                    types: ['place'],
                    language: 'it',
                    country: isoCountries.find(country => country.value === companyState.filters.state?.value)?.code
                },
                paramsSerializer: params => {
                    return qs.stringify(params, {
                        arrayFormat: 'repeat',
                        encode: false
                    });
                }
            });

            const countries = response.data.features.map((feature: PlaceFeatureDTO) => ({
                label: feature.properties.name,
                value: feature.properties.name
            }));

            return countries;
        } catch (error) {
            console.error("Errore durante il fetch delle nazioni: ", error);
            return [];
        }
    };

    const provinceFilters = regions.filter(region => companyState.filters.countryRegion.map(region => region.value).includes(region.name)).flatMap(regions => regions.provinces)

    const comuni = () => {
        let options: { value: string, label: string }[] = []

        provinces.forEach(prov => {
            provinceComuniMap.get(prov.value)?.forEach(comune => {
                options.push({ label: comune, value: comune })
            })
        })

        return options.sort((a, b) => a.value.localeCompare(b.value))
    }

    const cap = () => {
        let options: { value: string, label: string }[] = []

        companyState.filters.city.forEach(comune => {
            comuneCapMap.get(comune.value)?.forEach(comune => {
                options.push({ label: comune, value: comune })
            })
        })

        return options.sort((a, b) => a.value.localeCompare(b.value))
    }

    const selectedOptions = () => {
        let options: {
            value: string;
            label: string;
        }[] = []

        if (companyState.filters.state !== undefined) {
            options.push(companyState.filters.state)
        }
        if (companyState.filters.countryRegion.length > 0) {
            companyState.filters.countryRegion.forEach(countryRegion => {
                options.push(countryRegion)
            })
        }
        if (provinces.length > 0) {
            provinces.forEach(prov => {
                options.push({ value: provinceFilters.find(_prov => _prov.code === prov.value)?.code!, label: provinceFilters.find(_prov => _prov.code === prov.value)?.name! })
            })
        }
        if (companyState.filters.city.length > 0) {
            companyState.filters.city.forEach(city => {
                options.push(city)
            })
        }

        if (companyState.filters.zipCode.length > 0) {
            companyState.filters.zipCode.forEach(zipCode => {
                options.push(zipCode)
            })
        }

        return options
    }

    return (
        <AccordionFilter
            key={'person-filter-state'}
            label={"Sede azienda"}
            icon={<GlobeIcon color={""} size={0} />}
            showClear={selectedOptions().length > 0}
            hideDelete
            options={selectedOptions()}
            clearAction={function (): void {
                dispatch(setFindAllCompaniesFiltersState(undefined))
                dispatch(setFindAllCompaniesFiltersCity([]))
                dispatch(setFindAllCompaniesFiltersCountryRegion([]))
                setProvinces([])
            }}
            deleteFilter={function (option): void {
                dispatch(setFindAllCompaniesFiltersState(undefined))
            }}
        >
            <div className="flex flex-col gap-3">
                <SelectCustomAsync
                    loadOptions={fetchCountries}
                    defaultOptions={defaultOptions}
                    isClearable
                    onChange={e => {
                        dispatch(setFindAllCompaniesFiltersState(e === null ? undefined : e))
                        dispatch(setFindAllCompaniesFiltersCity([]))
                        dispatch(setFindAllCompaniesFiltersCountryRegion([]))
                    }}
                    noOptionsMessage="Stato non trovato"
                    placeholder="Seleziona una nazione..."
                    defaultValue={companyState.filters.state}
                />
                {
                    companyState.filters.state !== undefined &&
                    companyState.filters.state?.value === 'Italia' &&
                    <div className="flex flex-col gap-3">
                        <SelectCustom
                            isMulti
                            onChange={e => {
                                if (e !== undefined && typeof e !== 'string') {
                                    dispatch(setFindAllCompaniesFiltersCountryRegion(e.map(option => ({ value: option, label: option }))))
                                }
                            }}
                            defaultValue={companyState.filters.countryRegion.map(option => option.value)}
                            placeholder={"Seleziona una regione"}
                            options={regions.map(region => ({ value: region.name, label: region.name }))}
                        />
                        {
                            companyState.filters.countryRegion.length > 0 &&
                            <SelectCustom
                                isMulti
                                onChange={e => {
                                    if (e !== undefined && typeof e !== 'string') {
                                        setProvinces(e.map(option => ({ value: option, label: option })))
                                    }
                                }}
                                defaultValue={provinces.map(option => option.value)}
                                placeholder={"Seleziona una provincia"}
                                options={provinceFilters.map(province => ({ value: province.code, label: province.name }))}
                            />
                        }
                        {
                            provinces.length > 0 &&
                            <SelectCustom
                                isMulti
                                onChange={e => {
                                    if (e !== undefined && typeof e !== 'string') {
                                        dispatch(setFindAllCompaniesFiltersCity(e.map(option => ({ value: option, label: option }))))
                                    }
                                }}
                                defaultValue={companyState.filters.city.map(option => option.value)}
                                placeholder={"Seleziona un comune"}
                                options={comuni()}
                            />
                        }
                        {
                            companyState.filters.city.length > 0 &&
                            <SelectCustom
                                isMulti
                                onChange={e => {
                                    if (e !== undefined && typeof e !== 'string') {
                                        dispatch(setFindAllCompaniesFiltersZipCode(e.map(option => ({ value: option, label: option }))))
                                    }
                                }}
                                defaultValue={companyState.filters.zipCode.map(option => option.value)}
                                placeholder={"Seleziona un CAP"}
                                options={cap()}
                            />
                        }
                    </div>
                }
                {
                    companyState.filters.state !== undefined &&
                    companyState.filters.state?.value !== 'Italia' &&
                    <SelectCustomAsync
                        isMulti
                        loadOptions={fetchCities}
                        defaultOptions={[]}
                        isClearable
                        defaultValue={companyState.filters.city}
                        onChange={e => {
                            dispatch(setFindAllCompaniesFiltersCity(e === null ? [] : e))
                            // dispatch(setFindAllPeopleFiltersCountryRegion([]))
                        }}
                        noOptionsMessage="Città non trovata"
                        placeholder="Seleziona una città..."
                    />
                }
            </div>

        </AccordionFilter>
    )
}