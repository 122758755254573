import { UniqueIdentifier } from "@dnd-kit/core";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from '@dnd-kit/utilities';
import { DatabasePreferences, alwaysVisibleCompany, alwaysVisiblePeople } from "../../../lib/preferences/dto";
import { useAppSelector } from "../../../lib/redux/hooks";
import { colors } from "../../../ui/colors";
import { EyeIcon } from "../../../ui/icons/eye";
import { EyeOffIcon } from "../../../ui/icons/eyeOff";
import { MenuIcon } from "../../../ui/icons/menu";

interface Props {
    id: { item: DatabasePreferences, uniqueIdentifier: UniqueIdentifier }
    sort: (items: boolean) => void
}

export function SortableItem(props: Props) {
    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
    } = useSortable({ id: props.id.uniqueIdentifier });

    const databaseState = useAppSelector(state => state.database)

    const style = {
        transform: CSS.Transform.toString(transform),
        transition
    };

    return (
        <div
            ref={setNodeRef}
            style={style}
            className={'h-8 px-4 py-2 flex items-center justify-between'}
        >
            <div className={'flex items-center gap-2'}>
                <div
                    {...attributes}
                    {...listeners}
                >
                    <MenuIcon color={colors.neutral[600]} size={16} />
                </div>
                <span className="text-label-md font-medium text-neutral-600">
                    {props.id.item.column}
                </span>
            </div>
            {
                (
                    databaseState.tabValue === 0 && !alwaysVisiblePeople.includes(props.id.item.column) ||
                    databaseState.tabValue === 1 && !alwaysVisibleCompany.includes(props.id.item.column)
                ) &&
                <div
                    className="cursor-pointer"
                    onClick={(e) => {
                        e.preventDefault()
                        e.stopPropagation()
                        props.sort(!props.id.item.visible)
                    }}
                >
                    {
                        props.id.item.visible ?
                            <EyeIcon size={16} color={colors.neutral[600]} /> :
                            <EyeOffIcon size={16} color={colors.neutral[600]} />
                    }
                </div>
            }
        </div>
    );
}