import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../../app/lib/redux/hooks";
import { EditIcon } from "../../../ui/icons/edit";
import { TrashIcon } from "../../../ui/icons/trash";
import { UserPlusIcon } from "../../../ui/icons/userPlus";
import { Spinner } from "../../../ui/molecules/spinner";
import { Dropdown } from "../../../ui/organisms/dropdown";
import { Pagination } from "../../../ui/organisms/pagination/index";
import { Cell } from "./cell";
import { Head } from "./head";
import { setFindAllPeopleFiltersPage, setIdToDeletePerson, setPersonToAddCompany, setSelectedPerson } from "./slice";

export function PeopleList() {
    const peopleState = useAppSelector(state => state.people)
    const preferencesState = useAppSelector(state => state.preferences)

    const dispatch = useAppDispatch()

    useEffect(() => {
        if (peopleState.deletePersonStatus === 'successfully') {
            dispatch(setIdToDeletePerson(undefined))
        }
    }, [peopleState.deletePersonStatus])

    return (
        <div className="h-full w-full table">
            <div className="flex items-center w-full justify-between bg-white px-[16px] rounded-t-[12px] py-[4px]">
                {
                    peopleState.findAllResponse?.total === 1 ?
                        <span className="text-text-md text-neutral-850">
                            Trovata <span className="font-semibold">1</span> persona
                        </span>
                        :
                        <span className="text-text-md text-neutral-850">
                            Trovate <span className="font-semibold">{peopleState.findAllResponse?.total}</span> persone
                        </span>
                }
                <Pagination
                    key={'pagination-people-top'}
                    pages={peopleState.findAllResponse?.totalPage || 0}
                    currentPage={peopleState.findAllResponse?.page || 0}
                    setPage={(page) => { dispatch(setFindAllPeopleFiltersPage(page)) }}
                />
            </div>
            <table className="w-full">
                <thead className="bg-neutral-800 h-[48px] font-medium text-neutral-300 uppercase text-text-xs">
                    <tr className="bg-neutral-800">
                        {
                            preferencesState.findAllResponse?.data[0].personPreferences.filter(column => column.visible).map((column, index) => (
                                <Head key={column.column} column={column.column} index={index} />
                            ))
                        }
                        <th className="text-left pr-[12px] pl-[4px]">
                            <div className="flex gap-[4px] px-[4px] w-[20px]" />
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {
                        peopleState.findAllResponse?.data.map((person, index) =>
                            <tr
                                className="bg-white h-[56px] border-b-[1px] border-b-neutral-100"
                                key={person.email + index}
                            >
                                {
                                    preferencesState.findAllResponse?.data[0].personPreferences.filter(column => column.visible).map((column, index) => (
                                        <Cell key={person.id + '-' + column.column} column={column.column} index={index} person={person} />
                                    ))
                                }
                                <td className="w-[20px] cursor-pointer z-20">
                                    {
                                        peopleState.findPersonStatus === 'loading' ||
                                            peopleState.deletePersonStatus === 'loading' ?
                                            <Spinner size={20} /> :
                                            <Dropdown items={[
                                                {
                                                    label: 'Modifica',
                                                    icon: <EditIcon color={""} size={0} />,
                                                    onClick: () => {
                                                        dispatch(setSelectedPerson(person.id))
                                                    }
                                                },
                                                (
                                                    person.companyId === null ? {
                                                        label: 'Associa azienda',
                                                        icon: <UserPlusIcon color={""} size={0} />,
                                                        onClick: () => dispatch(setPersonToAddCompany(person))
                                                    } : null
                                                ),
                                                {
                                                    label: 'Cancella',
                                                    icon: <TrashIcon color={""} size={0} />,
                                                    onClick: () => dispatch(setIdToDeletePerson(person.id))
                                                },
                                            ]} />
                                    }
                                </td>
                            </tr>
                        )}
                </tbody>
            </table>
            <div className="flex w-full bg-white shadow-sm items-center justify-end rounded-b-[12px] px-[16px] py-[4px] z-0">
                <Pagination
                    key={'pagination-people-bottom'}
                    pages={peopleState.findAllResponse?.totalPage || 0}
                    currentPage={peopleState.findAllResponse?.page || 0}
                    setPage={(page) => { dispatch(setFindAllPeopleFiltersPage(page)) }}
                />
            </div>
        </div>
    )
}