import Select from "react-select";
import { colors } from "../../colors";
import { SelectProps } from "./dto";
import './style.css';

export function SelectCustom(props: SelectProps) {
    return (
        <div className="w-[100%]">
            <Select
                isDisabled={props.disabled}
                isMulti={props.isMulti}
                options={props.options}
                noOptionsMessage={() => <span className="text-text-sm">{props.noOptionsMessage}</span>}
                isClearable={props.isClearable}
                placeholder={props.placeholder}
                //@ts-ignore
                onChange={(e) => props.onChange && props.onChange(props.isMulti ? e.map(e => e.value) : e?.value)}
                defaultValue={
                    props.defaultValue !== undefined ?
                        props.isMulti ?
                            props.options?.filter(opt => props.defaultValue?.includes(opt.value))
                            :
                            props.options?.find(opt => opt.value === props.defaultValue)
                        :
                        null
                }
                value={
                    props.defaultValue !== undefined ?
                        props.isMulti ?
                            props.options?.filter(opt => props.defaultValue?.includes(opt.value))
                            :
                            props.options?.find(opt => opt.value === props.defaultValue)
                        :
                        null
                }
                styles={{
                    placeholder: (baseStyles) => ({
                        ...baseStyles,
                        fontSize: '14px',
                        lineHeight: '16px',
                        color: colors.neutral[400],
                        fontFamily: 'Fira Sans',
                        fontWeight: 500
                    }),
                    option: (baseStyles, props) => ({
                        ...baseStyles,
                        fontSize: '14px',
                        lineHeight: '20px',
                        color: props.isSelected ? colors.neutral[800] : colors.neutral[600],
                        fontFamily: 'Fira Sans',
                        fontWeight: 500,
                        backgroundColor: props.isSelected ? colors.brandPrimary[400] : colors.white,
                        '&:hover': {
                            backgroundColor: colors.brandPrimary[200]
                        }
                    }),
                    input: (baseStyles, props) => ({
                        ...baseStyles,
                        fontSize: '14px',
                        lineHeight: '20px',
                        color: colors.neutral[600],
                        fontFamily: 'Fira Sans',
                        fontWeight: 500
                    }),
                    singleValue: (baseStyles, props) => ({
                        ...baseStyles,
                        fontSize: '14px',
                        lineHeight: '20px',
                        color: colors.neutral[600],
                        fontFamily: 'Fira Sans',
                        fontWeight: 500
                    }),
                    multiValue: (baseStyles, props) => ({
                        ...baseStyles,
                        fontSize: '14px',
                        lineHeight: '20px',
                        color: colors.brandPrimary[900],
                        fontFamily: 'Fira Sans',
                        fontWeight: 500,
                        padding: '0px 8px',
                        borderRadius: 8,
                        backgroundColor: colors.brandPrimary[200],
                        border: '1px solid ' + colors.brandPrimary[400],
                    }),
                    multiValueRemove: (baseStyles, props) => ({
                        ...baseStyles,
                        color: colors.neutral[500],
                        '&:hover': {
                            color: colors.brandPrimary[600],
                            backgroundColor: colors.brandPrimary[200],
                        }
                    }),
                    indicatorsContainer: (baseStyles) => ({
                        ...baseStyles,
                        alignItems: 'center'
                    }),
                    container: (baseStyles) => ({
                        ...baseStyles,
                        width: '100%',
                        minHeight: '44px'
                    }),
                    control: (baseStyles, state) => ({
                        ...baseStyles,
                        minHeight: '44px',
                        width: '100%',
                        borderRadius: '8px',
                        boxShadow: 'none',
                        border: (props.error ? '1px solid ' + colors.red[300] : state.isFocused ? '1px solid ' + colors.neutral[300] : '1px solid ' + colors.neutral[300]),
                        fontFamily: 'Fira Sans',
                        padding: '0px 12px',
                        '&:hover': {
                            border: '1px neutral ' + colors.neutral[300]
                        }
                    }),
                    valueContainer: (baseStyles, state) => ({
                        ...baseStyles,
                        padding: 0,
                    }),
                    indicatorSeparator: (baseStyles, state) => ({
                        display: 'none'
                    }),
                    dropdownIndicator: (baseStyles, state) => ({
                        color: state.isFocused ? colors.neutral[500] : colors.neutral[500]
                    }),
                }}
            />
            {
                props.error &&
                <span className="text-text-sm text-red-500">{props.errorLabel}</span>
            }
        </div>
    )
}