import clsx from "clsx"
import { ReactElement } from "react"
import { numericFormatter } from "react-number-format"
import { useAppDispatch } from "../../../lib/redux/hooks"
import { colors } from "../../../ui/colors"
import { GlobeIcon } from "../../../ui/icons/globe"
import { CompanyDTO } from "./dto"
import { setSelectedCompany } from "./slice"

interface Props {
    column: string
    index: number
    person: CompanyDTO
}
export function Cell(props: Props) {
    const { column, index, person } = props
    const dispatch = useAppDispatch()
    const cellMap = new Map<string, ReactElement>([
        ['Azienda', <div onClick={() => dispatch(setSelectedCompany(person.id))} className="text-text-md cursor-pointer font-semibold text-brandPrimary-500 w-full">{person.name}</div>],
        ['Cod. ATECO', <div className="text-text-sm text-neutral-600 w-full">{person.atecoCode}</div>],
        ['Fatturato', <div className="text-text-sm text-neutral-600 w-full">{person.revenue ? numericFormatter(person.revenue.toString(), { thousandSeparator: '.', decimalSeparator: ',', fixedDecimalScale: true, decimalScale: 2, prefix: '€ ' }) : ''}</div>],
        ['Telefono', <a rel="noreferrer" target="_blank" href={'tel:' + person.phone} className="text-text-sm text-neutral-600">{person.phone}</a>],
        ['Email', <a rel="noreferrer" target="_blank" href={'mailto:' + person.email} className="text-text-sm text-neutral-600">{person.email}</a>],
        ['Indirizzo', <div className="text-text-sm text-neutral-600 w-full">{person.address}</div>],
        ['CAP', <div className="text-text-sm text-neutral-600 w-full">{person.zipCode}</div>],
        ['Città', <div className="text-text-sm text-neutral-600 w-full">{person.city}</div>],
        ['Provincia', <div className="text-text-sm text-neutral-600 w-full">{person.province}</div>],
        ['Regione', <div className="text-text-sm text-neutral-600 w-full">{person.countryRegion}</div>],
        ['Stato', <div className="text-text-sm text-neutral-600 w-full">{person.state}</div>],
        ['P. IVA', <div className="text-text-sm text-neutral-600 w-full">{person.vat}</div>],
        ['Settore', <div className="text-text-sm text-neutral-600 w-full">{person.sector}</div>],
        ['Categoria', <div className="text-text-sm text-neutral-600 w-full">{person.category}</div>],
        ['Dipendenti', <div className="text-text-sm text-neutral-600 w-full">{person.employeesNumber ? numericFormatter(person.employeesNumber.toString(), { thousandSeparator: '.' }) : ''}</div>],
        ['Sito web', <a rel="noreferrer" target="_blank" href={person.website} className="flex items-center justify-center"><GlobeIcon color={colors.neutral[600]} size={20} /></a>],
    ])

    return (
        <td className={clsx({
            'pr-[4px] pl-[12px]': index === 0,
            'px-[4px]': index !== 0,
        })}>
            {cellMap.get(column)}
        </td>
    )
}