import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../lib/redux/hooks";
import { encodeBase64 } from "../../lib/utils";
import { MailIcon } from "../../ui/icons/mail";
import { SmartphoneIcon } from "../../ui/icons/smartphone";
import { Layout } from "../../ui/layout";
import Avatar from "../../ui/molecules/avatar";
import Button from "../../ui/molecules/button";
import Input from "../../ui/molecules/input";
import { SelectCustom } from "../../ui/molecules/select";
import { Spinner } from "../../ui/molecules/spinner";
import { Banner } from "../../ui/organisms/banner";
import FileUploader from "../../ui/organisms/fileUploader";
import { MenuItems } from "../../ui/organisms/navbar/dto";
import { UserRole, UserRoleMap } from "./dto";
import { findClients, findCollaborators, newUser, setCreateUserCollaborators, setCreateUserCustomerId, setCreateUserEmail, setCreateUserName, setCreateUserPhone, setCreateUserRole, setCreateUserStatus, setCreateUserSurname, setValidateNewUserEmail, setValidateNewUserName, setValidateNewUserPhone, setValidateNewUserRole, setValidateNewUserStatus, setValidateNewUserSurname, validateNewUser } from "./slice";

export function NewUser() {
    const usersState = useAppSelector(state => state.users)
    const dispatch = useAppDispatch()
    const navigation = useNavigate()

    let formData = new FormData()

    const [file, setFile] = useState<File | null>(null)
    const [file64, setFile64] = useState<string>('')

    useEffect(() => {
        let timeoutId: NodeJS.Timeout;
        if (usersState.newUserStatus === 'failed') {
            timeoutId = setTimeout(() => {
                dispatch(setCreateUserStatus('idle'))
            }, 5000);
        }
        return () => {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
        };
    }, [usersState.newUserStatus]);

    useEffect(() => {
        if (usersState.validateNewUserStatus === 'successfully') {
            if (file !== null)
                formData.append('file', file)
            formData.append('user', JSON.stringify(usersState.newUserRequest))
            dispatch(newUser(formData))
            dispatch(setValidateNewUserStatus('idle'))
        }
    }, [usersState.validateNewUserStatus])

    useEffect(() => {
        if (usersState.newUserStatus === 'successfully') {
            navigation('/users')
        }
    }, [usersState.newUserStatus, dispatch])

    useEffect(() => {
        dispatch(findCollaborators())
        dispatch(findClients())
        dispatch(setCreateUserEmail(''))
        dispatch(setCreateUserRole(undefined))
        dispatch(setCreateUserName(''))
        dispatch(setCreateUserSurname(''))
        dispatch(setCreateUserPhone(''))
        dispatch(setCreateUserStatus('idle'))
        dispatch(setValidateNewUserRole(false))
        dispatch(setValidateNewUserName(false))
        dispatch(setValidateNewUserSurname(false))
        dispatch(setValidateNewUserEmail(false))
        dispatch(setValidateNewUserPhone(false))
        setFile(null)
        setFile64('')
    }, [])

    return (
        <Layout
            menuItem={MenuItems.USERS}
            breadcrumbItems={['Utenti', 'Nuovo utente']}
            headerLabel={'Nuovo utente'}
            headerBackPath={'/users'}
            headerChildren={
                <div className="flex items-center h-[100%] w-[100%] justify-end">
                    {
                        usersState.newUserStatus === 'loading' || usersState.validateNewUserStatus === 'loading' ?
                            <Spinner /> :
                            <Button
                                size={"sm"}
                                iconPosition={"off"}
                                label="Salva utente"
                                variant={"solid"}
                                color={"blue"}
                                onClick={() => {
                                    dispatch(validateNewUser(usersState.newUserRequest))
                                }}
                            />
                    }
                </div>
            }
        >
            <div className="gap-[24px] flex flex-col w-[100%] p-[24px]">
                <Banner
                    type="error"
                    visible={usersState.newUserStatus === 'failed'}
                    label={"Si è verificato un errore durante la creazione dell'utente"}
                    closeAction={function (): void {
                        dispatch(setCreateUserStatus('idle'))
                    }}
                />
                <div className="p-[24px] gap-[20px] flex flex-col bg-white shadow-sm rounded-[12px]">
                    <div className="flex gap-[24px] w-[100%] items-start">
                        <div className="w-[240px] text-label-md font-medium text-neutral-600">Ruolo <span className="text-red-600"> *</span></div>
                        <div className="flex gap-[16px] w-[100%]">
                            <SelectCustom
                                isClearable
                                error={usersState.createUserErrors.role}
                                errorLabel="Inserire un ruolo."
                                placeholder={"Seleziona un ruolo"}
                                key={'new-user-roles-list'}
                                onChange={(value) => { dispatch(setCreateUserRole(value)); dispatch(setValidateNewUserRole(false)) }}
                                options={[
                                    { label: UserRoleMap.get(UserRole.collaborator)!, value: UserRole.collaborator },
                                    { label: UserRoleMap.get(UserRole.commercial)!, value: UserRole.commercial },
                                    { label: UserRoleMap.get(UserRole.customer)!, value: UserRole.customer },
                                    { label: UserRoleMap.get(UserRole.team_leader)!, value: UserRole.team_leader },
                                ]}
                            />
                        </div>
                    </div>
                    <div className="flex gap-[24px] w-[100%] items-start">
                        <div className="w-[240px] text-label-md font-medium text-neutral-600">Email <span className="text-red-600"> *</span></div>
                        <div className="flex gap-[16px] w-[100%]">
                            <Input supportingText={usersState.createUserErrors.email ? "Inserire un'email valida." : undefined} error={usersState.createUserErrors.email} startIcon={<MailIcon size={0} color="" />} placeholder="Email" onChangeText={(value) => { dispatch(setCreateUserEmail(value)); if (usersState.createUserErrors.email) dispatch(setValidateNewUserEmail(false)) }} defaultValue={usersState.newUserRequest?.email} />
                        </div>
                    </div>
                    <div className="flex gap-[24px] w-[100%] items-start">
                        <div className="w-[240px] text-label-md font-medium text-neutral-600">Nome <span className="text-red-600"> *</span></div>
                        <div className="flex gap-[16px] w-[100%]">
                            <Input supportingText={usersState.createUserErrors.name ? "Inserire il nome." : undefined} error={usersState.createUserErrors.name} placeholder="Nome" onChangeText={value => { dispatch(setCreateUserName(value)); if (usersState.createUserErrors.name) dispatch(setValidateNewUserName(false)) }} defaultValue={usersState.newUserRequest?.name} />
                            <Input supportingText={usersState.createUserErrors.surname ? "Inserire il cognome." : undefined} error={usersState.createUserErrors.surname} placeholder="Cognome" onChangeText={value => { dispatch(setCreateUserSurname(value)); if (usersState.createUserErrors.surname) dispatch(setValidateNewUserSurname(false)) }} defaultValue={usersState.newUserRequest?.surname} />
                        </div>
                    </div>
                    <div className="flex gap-[24px] w-[100%] items-start">
                        <div className="w-[240px] text-label-md font-medium text-neutral-600">Telefono</div>
                        <div className="flex gap-[16px] w-[100%]">
                            <Input supportingText={usersState.createUserErrors.phone ? "Inserire un numero di telefono valido." : undefined} error={usersState.createUserErrors.phone} placeholder="Telefono" startIcon={<SmartphoneIcon color={""} size={0} />} onChangeText={value => { dispatch(setCreateUserPhone(value)); if (usersState.createUserErrors.phone) dispatch(setValidateNewUserPhone(false)) }} defaultValue={usersState.newUserRequest?.phone} />
                        </div>
                    </div>
                    {
                        usersState.newUserRequest.role === UserRole.customer &&
                        <div className="flex gap-[24px] w-[100%] items-start">
                            <div className="w-[240px] text-label-md font-medium text-neutral-600">Collaboratori</div>
                            <div className="flex gap-[16px] w-[100%]">
                                <SelectCustom
                                    placeholder={"Seleziona i collaboratori"}
                                    onChange={(value) => dispatch(setCreateUserCollaborators(value))}
                                    isMulti
                                    isClearable
                                    key={'new-user-collaborators-list'}
                                    options={usersState.findCollaboratorsResponse?.data.filter(collaborator => collaborator.customerId === null).map((value) => ({ label: (value.name + ' ' + value.surname), value: value.id }))!}
                                />
                            </div>
                        </div>
                    }
                    {
                        usersState.newUserRequest.role === UserRole.collaborator &&
                        <div className="flex gap-[24px] w-[100%] items-start">
                            <div className="w-[240px] text-label-md font-medium text-neutral-600">Cliente</div>
                            <div className="flex gap-[16px] w-[100%]">
                                <SelectCustom
                                    placeholder={"Seleziona il cliente"}
                                    key={'new-user-customers-list'}
                                    isClearable
                                    onChange={(value) => dispatch(setCreateUserCustomerId(value))}
                                    options={usersState.findClientsResponse?.data.map((value) => ({ label: (value.name + ' ' + value.surname), value: value.id }))!}
                                />
                            </div>
                        </div>
                    }
                    <div className="flex gap-[24px] w-[100%] items-start">
                        <div className="w-[240px] text-label-md font-medium text-neutral-600">Immagine del profilo</div>
                        <div className="flex gap-[16px] w-[100%]">
                            <Avatar type="single" size={"2xl"} shape={'circle'} imageUrl={file64} altTextInitials={(usersState.newUserRequest.name[0] || '') + (usersState.newUserRequest.surname[0] || '')} />
                            <FileUploader id={"new-user-file-uploader"} style={"small"} onChange={e => {
                                setFile(e[0])
                                e[0].arrayBuffer().then(_e => (setFile64(encodeBase64(_e))))
                            }} />
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}