import { FindAllUserResponse, FindAllUsersFilters, UserDTO } from "./dto";
import { UsersServiceImpl } from "./serviceImpl";

export interface UsersService {
    findAllUsers(filters: FindAllUsersFilters): Promise<FindAllUserResponse>
    findUserById(id: string): Promise<UserDTO>
    editUser(id: string, data: FormData): Promise<void>
    createUser(data: FormData): Promise<void>
    deleteUser(id: string): Promise<void>
}

export function NewUsersService(): UsersService {
    return new UsersServiceImpl();
}
