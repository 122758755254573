import { updatePreferences } from "../../../../lib/preferences/slice";
import { useAppDispatch, useAppSelector } from "../../../../lib/redux/hooks";
import { colors } from "../../../../ui/colors";
import { ChevronIcon } from "../../../../ui/icons/chevron";
import { PlusIcon } from "../../../../ui/icons/plus";
import { setOption, setOptionsActive } from "../../slice";
import { Sorting } from "../../sorting";

export function ColumnsOptions() {
    const dispatch = useAppDispatch()

    const preferencesState = useAppSelector(state => state.preferences)

    return (
        <>
            <div className="h-12 flex-shrink-0 px-[16px] py-[12px] flex items-center justify-between border-b-[1px]">
                <div className="flex items-center gap-1">
                    <div className="cursor-pointer" onClick={() => dispatch(setOption(undefined))}>
                        <ChevronIcon color={colors.neutral[400]} size={16} direction="l" />
                    </div>
                    <span className="text-label-md text-neutral-800 font-bold">
                        Colonne
                    </span>
                </div>
                <div className="rotate-45 cursor-pointer" onClick={() => dispatch(setOptionsActive(false))}>
                    <PlusIcon color={colors.neutral[400]} size={24} />
                </div>
            </div>
            <div className="h-full flex flex-col gap-1 px-1 py-2 relative overflow-auto mb-4">
                <Sorting
                    key={'person-sortings'}
                    items={preferencesState.findAllResponse?.data[0].personPreferences!}
                    sortItems={(items) => {
                        dispatch(updatePreferences({
                            data: {
                                owner: preferencesState.findAllResponse?.data[0].owner!,
                                personPreferences: items,
                                companyPreferences: preferencesState.findAllResponse?.data[0].companyPreferences!
                            },
                            id: preferencesState.findAllResponse?.data[0].id!
                        }))
                    }}
                />
            </div>
        </>
    )
}