import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { PromiseStatuses } from "../../lib/types"
import { FindAllCompaniesFilters, FindAllCompaniesResponse } from "./companies/dto"
import { NewCompaniesService } from "./companies/service"

interface DatabaseState {
    tabValue: number
    filtersActive: boolean
    optionsActive: boolean
    option?: 'columns'
    companiesForFiltersResponse?: FindAllCompaniesResponse
    companiesForFiltersStatus: PromiseStatuses
}

const initialState: DatabaseState = {
    tabValue: 0,
    filtersActive: true,
    optionsActive: false,
    companiesForFiltersStatus: 'idle'
}

export const findAllCompaniesForFilters = createAsyncThunk(
    'database/findAllCompaniesForFilters',
    async (): Promise<FindAllCompaniesResponse> => {
        const CompaniesService = NewCompaniesService()
        const filters: FindAllCompaniesFilters = {
            order: true,
            sort: 'name',
            itemsPerPage: 0,
            page: 0,
            name: [],
            atecos: [],
            sector: [],
            category: [],
            department: [],
            role: [],
            revenue: undefined,
            employees: undefined,
            smartphone: undefined,
            privacy: undefined,
            state: undefined,
            city: [],
            countryRegion: [],
            zipCode: []
        }

        return CompaniesService.findAllCompanies(filters)
    }
)

const databaseSlice = createSlice({
    name: 'database/slice',
    initialState,
    reducers: {
        setTabValue: (state, action) => {
            state.tabValue = action.payload
        },
        setFiltersActive: (state, action) => {
            state.filtersActive = action.payload
        },
        setOptionsActive: (state, action) => {
            state.optionsActive = action.payload
        },
        setOption: (state, action) => {
            state.option = action.payload
        }
    },
    extraReducers(builder) {
        builder
            .addCase(findAllCompaniesForFilters.pending, (state) => {
                state.companiesForFiltersStatus = 'loading'
            })
            .addCase(findAllCompaniesForFilters.fulfilled, (state, action) => {
                state.companiesForFiltersStatus = 'successfully'
                state.companiesForFiltersResponse = action.payload
            })
            .addCase(findAllCompaniesForFilters.rejected, (state) => {
                state.companiesForFiltersStatus = 'failed'
            })
    },
})

export const {
    setTabValue,
    setFiltersActive,
    setOptionsActive,
    setOption,
} = databaseSlice.actions

export default databaseSlice.reducer