import { useEffect } from "react"
import { roleDepartment } from "../../../../lib/preferences/dto"
import { useAppDispatch, useAppSelector } from "../../../../lib/redux/hooks"
import { groupBy } from "../../../../lib/utils"
import { TruckIcon } from "../../../../ui/icons/truck"
import { SelectCustom } from "../../../../ui/molecules/select"
import { AccordionFilter } from "../../../../ui/organisms/accordionFilter"
import { setFindAllPeopleFiltersDepartment, setFindAllPeopleFiltersRole } from "../slice"

export function DepartmentFilter() {
    const peopleState = useAppSelector(state => state.people)

    const dispatch = useAppDispatch()

    useEffect(() => {
        if (peopleState.filters.department.length === 0) {
            dispatch(setFindAllPeopleFiltersRole([]))
        } else {
            let newRoles: { value: string, label: string }[] = []
            peopleState.filters.department.forEach(department => {
                const roles = roleDepartment.filter(_department => department.value === _department.department)
                if (roles.length > 0) {
                    roles.forEach(role => {
                        if (role && !newRoles.map(cat => cat.value).includes(role.role)) {
                            newRoles.push({ value: role.role, label: role.role })
                        }
                    })
                }
            })
            dispatch(setFindAllPeopleFiltersRole(newRoles))
        }
    }, [peopleState.filters.department])

    return (
        <AccordionFilter
            key={'person-filter-department'}
            label={"Reparto"}
            icon={<TruckIcon color={""} size={0} />}
            showClear={peopleState.filters.department.length > 0}
            options={peopleState.filters.department}
            clearAction={function (): void {
                dispatch(setFindAllPeopleFiltersDepartment([]))
            }}
            deleteFilter={function (option): void {
                dispatch(setFindAllPeopleFiltersDepartment(peopleState.filters.department.filter(name => name.value !== option.value)))
            }}
        >
            <SelectCustom
                isMulti
                isClearable={false}
                onChange={e => {
                    if (e !== undefined && typeof e !== 'string') {
                        dispatch(setFindAllPeopleFiltersDepartment(e.map(option => ({ value: option, label: option }))))
                    }
                }}
                defaultValue={peopleState.filters.department.map(option => option.value)}
                placeholder={"Cerca per reparto"}
                options={
                    groupBy(roleDepartment, 'department').map(role => ({ value: role.key, label: role.key }))
                }
                noOptionsMessage="Non sono presenti reparti."
            />
        </AccordionFilter>
    )
}