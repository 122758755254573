export const colors = {
    brandPrimary: {
        900: '#042567',
        850: '#063084',
        800: '#073AA2',
        700: '#094ED7',
        600: '#2369F6',
        500: '#3B7AF7',
        400: '#76A2F9',
        300: '#B1CAFC',
        200: '#E2EBFE',
        100: '#EBF2FE',
        50: '#EBF2FE',
    },
    brandSecondary: {
        900: '#133444',
        850: '#1F475C',
        800: '#2E5E76',
        700: '#42758F',
        600: '#57869E',
        500: '#709CB2',
        400: '#709CB2',
        300: '#709CB2',
        200: '#DEEFF7',
        100: '#DEEFF7',
        50: '#F3F9FC',
    },
    neutral: {
        900: '#1A1A38',
        850: '#262645',
        800: '#323252',
        700: '#434366',
        600: '#62628A',
        500: '#8383AD',
        400: '#ADADD6',
        300: '#CBCBEB',
        200: '#E4E4F7',
        100: '#F2F2FA',
        50: '#F7F7FC',
    },
    green: {
        800: '#166428',
        700: '#1D8636',
        600: '#26AB45',
        500: '#3AD45E',
        400: '#8AE59F',
        300: '#B0EEBE',
        200: '#D1F5D9',
        100: '#E6F9EA',
    },
    red: {
        800: '#940500',
        700: '#DB0700',
        600: '#FF211A',
        500: '#FF443D',
        400: '#FF7570',
        300: '#FFC4C2',
        200: '#FFDCDB',
        100: '#FFEBEB',
    },
    warning: {
        800: '#944F00',
        700: '#DB7500',
        600: '#FF941A',
        500: '#FFB057',
        400: '#FFD29E',
        300: '#FFE2C2',
        200: '#FFEEDB',
        100: '#FFEEDB',
    },
    info: {
        800: '#00587A',
        700: '#0075A3',
        600: '#0096D1',
        500: '#0FBBFF',
        400: '#70D7FF',
        300: '#9EE4FF',
        200: '#C7EFFF',
        100: '#E0F6FF',
    },
    white: '#FFFFFF',
}