import { ImportCSVResponseDTO } from "../dto";
import { AssociatePersonToCompany, EditPersonRequest, FindAllPeopleFilters, FindAllPeopleResponse, PersonDTO } from "./dto";
import { PeopleServiceImpl } from "./serviceImpl";

export interface PeopleService {
    findAllPeople(filters: FindAllPeopleFilters): Promise<FindAllPeopleResponse>
    deletePerson(id: string): Promise<void>
    findPersonById(id: string): Promise<PersonDTO>
    updatePerson(id: string, data: EditPersonRequest): Promise<void>
    importContact(data: FormData): Promise<ImportCSVResponseDTO>
    associateCompany(data: AssociatePersonToCompany, personId: string): Promise<void>
}

export function NewPeopleService(): PeopleService {
    return new PeopleServiceImpl();
}
