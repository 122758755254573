import { useAppDispatch, useAppSelector } from "../../../../lib/redux/hooks"
import { SmartphoneIcon } from "../../../../ui/icons/smartphone"
import { SelectCustom } from "../../../../ui/molecules/select"
import { AccordionFilter } from "../../../../ui/organisms/accordionFilter"
import { setFindAllCompaniesFiltersPrivacy } from "../slice"

export function PrivacyFilter() {
    const companiesState = useAppSelector(state => state.companies)
    const options = [
        { value: 'true', label: 'Sì' },
        { value: 'false', label: 'No' }
    ]

    const dispatch = useAppDispatch()

    return (
        <AccordionFilter
            key={'person-filter-privacy'}
            label={"Privacy"}
            icon={<SmartphoneIcon color={""} size={0} />}
            showClear={companiesState.filters.privacy !== undefined}
            options={companiesState.filters.privacy !== undefined ? [companiesState.filters.privacy] : []}
            clearAction={function (): void {
                dispatch(setFindAllCompaniesFiltersPrivacy(undefined))
            }}
            deleteFilter={function (option): void {
                dispatch(setFindAllCompaniesFiltersPrivacy(undefined))
            }}
        >
            <SelectCustom
                isClearable
                onChange={e => {
                    if (e === undefined) {
                        dispatch(setFindAllCompaniesFiltersPrivacy(undefined))
                    } else if (typeof e === 'string') {
                        dispatch(setFindAllCompaniesFiltersPrivacy(({ value: e, label: options.find(opt => opt.value === e)?.label })))
                    }
                }}
                defaultValue={companiesState.filters.privacy?.value}
                placeholder={"Privacy"}
                options={options}
            />
        </AccordionFilter>
    )
}