import { IconProps } from "./dto";

export function CoinStackIcon(props: IconProps) {
    return (
        <svg width={props.size} height={props.size} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_683_15708)">
                <path d="M10.6316 3.16668C10.6325 2.24584 8.7548 1.5 6.44124 1.5C4.12767 1.5 2.25168 2.24667 2.25 3.16668M2.25 3.16668C2.25 4.08753 4.126 4.83337 6.44124 4.83337C8.75648 4.83337 10.6325 4.08753 10.6325 3.16668L10.6325 9.63336M2.25 3.16668V13.1667C2.25084 14.0876 4.12684 14.8334 6.44124 14.8334C7.56365 14.8334 8.57709 14.6559 9.329 14.3701M2.25084 6.50004C2.25084 7.42088 4.12684 8.16672 6.44208 8.16672C8.75732 8.16672 10.6333 7.42088 10.6333 6.50004M9.3793 11.021C8.6232 11.3168 7.58628 11.5001 6.44124 11.5001C4.12684 11.5001 2.25084 10.7543 2.25084 9.83345M15.3954 10.0985C16.8682 11.5627 16.8682 13.9377 15.3954 15.4019C13.9226 16.866 11.5336 16.866 10.0608 15.4019C8.58799 13.9377 8.58799 11.5627 10.0608 10.0985C11.5336 8.63429 13.9226 8.63429 15.3954 10.0985Z" stroke={props.color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            </g>
            <defs>
                <clipPath id="clip0_683_15708">
                    <rect width="18" height="18" fill="white" />
                </clipPath>
            </defs>
        </svg>

    )
}