import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../../lib/redux/hooks";
import Button from "../../../../ui/molecules/button";
import { findAllCompaniesForFilters } from "../../slice";
import { findAllPeople, setFindAllPeopleFiltersAtecos, setFindAllPeopleFiltersCategory, setFindAllPeopleFiltersCity, setFindAllPeopleFiltersCountryRegion, setFindAllPeopleFiltersDepartment, setFindAllPeopleFiltersEmployees, setFindAllPeopleFiltersName, setFindAllPeopleFiltersPrivacy, setFindAllPeopleFiltersRevenue, setFindAllPeopleFiltersRole, setFindAllPeopleFiltersSector, setFindAllPeopleFiltersSmartphone, setFindAllPeopleFiltersState, setFindAllPeopleFiltersZipCode } from "../slice";
import { AtecoFilter } from "./ateco";
import { CategoryFilter } from "./category";
import { CompanyFilter } from "./company";
import { DepartmentFilter } from "./department";
import { EmployeesNumberFilter } from "./employeesNumber";
import { PrivacyFilter } from "./privacy";
import { RevenueFilter } from "./revenue";
import { RoleFilter } from "./role";
import { SectorFilter } from "./sector";
import { SmartphoneFilter } from "./smartphone";
import { StateFilter } from "./state";

export function Filters() {
    const databaseState = useAppSelector(state => state.database)
    const peopleState = useAppSelector(state => state.people)

    const dispatch = useAppDispatch()

    useEffect(() => {
        dispatch(findAllCompaniesForFilters())
    }, [])

    const filters = Object.entries(peopleState.filters).filter(entr => entr[0] !== 'page' && entr[0] !== 'itemsPerPage' && entr[0] !== 'order' && entr[0] !== 'sort' && entr[0] !== 'personName' && entr[0] !== 'personEmail' && entr[0] !== 'hasCompany' && entr[1] !== undefined && entr[1].length !== 0).length

    return (
        <div className="bg-white rounded-[12px] border-[1px] border-neutral-200 w-[400px] h-full">
            <div className="px-[24px] py-[12px] flex items-center justify-between">
                <span className="text-text-md text-neutral-800 font-bold">
                    {'Filtri ' + (filters === 0 ? '' : '(' + filters + ')')}
                </span>
                <div className="flex items-center">
                    <Button
                        size={"sm"}
                        iconPosition={"off"}
                        variant={"link"}
                        color={"blue"}
                        label="Pulisci tutti"
                        onClick={() => {
                            dispatch(setFindAllPeopleFiltersName([]))
                            dispatch(setFindAllPeopleFiltersRevenue(undefined))
                            dispatch(setFindAllPeopleFiltersEmployees(undefined))
                            dispatch(setFindAllPeopleFiltersAtecos([]))
                            dispatch(setFindAllPeopleFiltersSector([]))
                            dispatch(setFindAllPeopleFiltersCategory([]))
                            dispatch(setFindAllPeopleFiltersDepartment([]))
                            dispatch(setFindAllPeopleFiltersRole([]))
                            dispatch(setFindAllPeopleFiltersState(undefined))
                            dispatch(setFindAllPeopleFiltersCity([]))
                            dispatch(setFindAllPeopleFiltersCountryRegion([]))
                            dispatch(setFindAllPeopleFiltersSmartphone(undefined))
                            dispatch(setFindAllPeopleFiltersPrivacy(undefined))
                            dispatch(setFindAllPeopleFiltersZipCode([]))
                            dispatch(findAllPeople({
                                atecos: [],
                                category: [],
                                department: [],
                                employees: undefined,
                                itemsPerPage: 10,
                                name: [],
                                order: true,
                                page: 0,
                                privacy: undefined,
                                revenue: undefined,
                                role: [],
                                sector: [],
                                city: [],
                                smartphone: undefined,
                                sort: undefined,
                                state: undefined,
                                countryRegion: [],
                                zipCode: []
                            }))
                        }}
                    />
                    <Button
                        size={"sm"}
                        iconPosition={"off"}
                        variant={"outline"}
                        color={"gray"}
                        label="Applica"
                        onClick={() => dispatch(findAllPeople(peopleState.filters))}
                    />
                </div>
            </div>
            <div className="overflow-auto w-full">
                <CompanyFilter />
                <RevenueFilter />
                <EmployeesNumberFilter />
                <AtecoFilter />
                <SectorFilter />
                <CategoryFilter />
                <DepartmentFilter />
                <RoleFilter />
                <SmartphoneFilter />
                <PrivacyFilter />
                <StateFilter />
            </div>
        </div>
    )
}