import { Router } from "@remix-run/router"
import { useEffect, useState } from "react"
import { RouterProvider, createBrowserRouter } from "react-router-dom"
import { Dashboard } from "../../pages/dashboard"
import { Database } from "../../pages/database"
import { ImportCompanies } from "../../pages/database/companies/import"
import { ImportPeople } from "../../pages/database/people/import"
import { Users } from "../../pages/users"
import { EditUser } from "../../pages/users/editUser"
import { NewUser } from "../../pages/users/newUser"
import { setMenuItems } from "../../ui/layout/slice"
import { Spinner } from "../../ui/molecules/spinner"
import { MenuItems } from "../../ui/organisms/navbar/dto"
import { keycloak } from "../keycloak"
import { useAppDispatch, useAppSelector } from "../redux/hooks"

const adminRouter = createBrowserRouter([
    {
        path: '/dashboard',
        element: (
            <Dashboard />
        ),
    },
    {
        path: '/database',
        element: (
            <Database />
        )
    },
    {
        path: '/import-people',
        element: (
            <ImportPeople />
        )
    },
    {
        path: '/import-companies',
        element: (
            <ImportCompanies />
        )
    },
    {
        path: '/target-list',
        element: (
            <Dashboard />
        )
    },
    {
        path: '/projects',
        element: (
            <Dashboard />
        )
    },
    {
        path: '/users',
        element: (
            <Users />
        )
    },
    {
        path: '/edit-user/:id',
        element: (
            <EditUser />
        )
    },
    {
        path: '/new-user',
        element: (
            <NewUser />
        )
    },
    {
        path: '/database',
        element: (
            <Dashboard />
        )
    },
    {
        path: '/pipeline',
        element: (
            <Dashboard />
        )
    },
    {
        path: '/calendar',
        element: (
            <Dashboard />
        )
    },
    {
        path: '/report',
        element: (
            <Dashboard />
        )
    },
    {
        path: '/settings',
        element: (
            <Dashboard />
        )
    },
])

const teamLeaderRouter = createBrowserRouter([
    {
        path: '/dashboard',
        element: (
            <Dashboard />
        )
    },
])

const commercialRouter = createBrowserRouter([
    {
        path: '/dashboard',
        element: (
            <Dashboard />
        )
    },
])

const customerRouter = createBrowserRouter([
    {
        path: '/dashboard',
        element: (
            <Dashboard />
        )
    },
])

const collaboratorRouter = createBrowserRouter([
    {
        path: '/dashboard',
        element: (
            <Dashboard />
        )
    },
])

export function KeycloakRouter() {
    const roles = useAppSelector(state => state.keycloak.userRoles)

    const dispatch = useAppDispatch()

    const [router, setRouter] = useState<Router | undefined>(undefined)

    useEffect(() => {
        if (roles !== undefined && roles.length > 0 && keycloak.token !== undefined) {
            if (keycloak.hasRealmRole('admin')) {
                setRouter(adminRouter)
                dispatch(setMenuItems([
                    { item: MenuItems.DASHBOARD, section: 1 },
                    { item: MenuItems.DATABASE, section: 1 },
                    { item: MenuItems.TARGET_LIST, section: 1 },
                    { item: MenuItems.PROJECTS, section: 1 },
                    { item: MenuItems.USERS, section: 1 },
                    { item: MenuItems.PIPELINE, section: 2 },
                    { item: MenuItems.CALENDAR, section: 2 },
                    { item: MenuItems.REPORT, section: 2 },
                    { item: MenuItems.SETTINGS, section: 3 }
                ]))
            } else if (keycloak.hasRealmRole('team_leader')) {
                setRouter(teamLeaderRouter)
                dispatch(setMenuItems([{ item: MenuItems.DASHBOARD, section: 1 }]))
            } else if (keycloak.hasRealmRole('commercial')) {
                setRouter(commercialRouter)
                dispatch(setMenuItems([{ item: MenuItems.DASHBOARD, section: 1 }]))
            } else if (keycloak.hasRealmRole('customer')) {
                setRouter(customerRouter)
                dispatch(setMenuItems([{ item: MenuItems.DASHBOARD, section: 1 }]))
            } else if (keycloak.hasRealmRole('collaborator')) {
                setRouter(collaboratorRouter)
                dispatch(setMenuItems([{ item: MenuItems.DASHBOARD, section: 1 }]))
            }
            else {
                return
            }
        }
    }, [roles, keycloak.token])

    if (router === undefined) {
        return (
            <div className='w-full h-[100vh] flex items-center justify-center'>
                <Spinner />
            </div>
        )
    }

    return (
        <RouterProvider router={router} />
    );
}