import React from "react";
import { IconProps } from "./dto";

export function TruckIcon(props: IconProps) {
    return (
        <svg width={props.size} height={props.size} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_707_4257)">
                <path d="M12 12V2.25H0.75V12H12ZM12 12H17.25V8.25L15 6H12V12ZM6 13.875C6 14.9105 5.16053 15.75 4.125 15.75C3.08947 15.75 2.25 14.9105 2.25 13.875C2.25 12.8395 3.08947 12 4.125 12C5.16053 12 6 12.8395 6 13.875ZM15.75 13.875C15.75 14.9105 14.9105 15.75 13.875 15.75C12.8395 15.75 12 14.9105 12 13.875C12 12.8395 12.8395 12 13.875 12C14.9105 12 15.75 12.8395 15.75 13.875Z" stroke={props.color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            </g>
            <defs>
                <clipPath id="clip0_707_4257">
                    <rect width="18" height="18" fill="white" />
                </clipPath>
            </defs>
        </svg>

    )
}