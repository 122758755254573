import { useEffect } from "react"
import { useAppDispatch, useAppSelector } from "../../../lib/redux/hooks"
import { colors } from "../../../ui/colors"
import { PlusIcon } from "../../../ui/icons/plus"
import Avatar from "../../../ui/molecules/avatar"
import { Spinner } from "../../../ui/molecules/spinner"
import { AccordionEmployee } from "./accordionEmployee"
import { setFindEmployeesResponse, setFindEmployeesStatus } from "./slice"

export function CompanyEmployees() {
    const companiesState = useAppSelector(state => state.companies)
    const databaseState = useAppSelector(state => state.database)

    const dispatch = useAppDispatch()

    useEffect(() => {
        dispatch(setFindEmployeesResponse([]))
        dispatch(setFindEmployeesStatus('idle'))
    }, [databaseState.tabValue])

    return (
        <>
            {
                companiesState.findCompanyStatus === 'loading' ?
                    <Spinner /> :
                    <>
                        <div className="px-6 pt-4 flex items-center justify-between">
                            <Avatar
                                type="info"
                                imageUrl=""
                                firstRow={companiesState.selectedCompanyNameForEmployees!}
                                size="md"
                                altTextInitials={companiesState.selectedCompanyNameForEmployees?.slice(0, 1).toUpperCase()!}
                            />
                            <div className="flex items-center gap-4">
                                <div className="cursor-pointer rotate-45" onClick={() => { dispatch(setFindEmployeesStatus('idle')); dispatch(setFindEmployeesResponse([])) }}>
                                    <PlusIcon color={colors.neutral[500]} size={20} />
                                </div>
                            </div>
                        </div>
                        <div className="py-4">
                            {companiesState.findEmployeesResponse.map(employee => (
                                <AccordionEmployee employee={employee} />
                            ))}
                        </div>
                    </>
            }
        </>
    )
}