import React from "react";
import { colors } from "../../colors";
import { MenuItemsIconsMap, PathMenuItemMap } from "../navbar/dto";
import { BreadcrumbProps } from "./Breadcrumb.types";

const Breadcrumb: React.FC<BreadcrumbProps> = ({
    ...props
}) => {
    return (
        <div style={{ display: 'flex', flexDirection: 'row', gap: '4px', alignItems: 'center' }}>
            {MenuItemsIconsMap(PathMenuItemMap.get(props.path[0].toLowerCase())!, colors.neutral[500], 12)}
            {
                props.path.map((item, index) => (
                    <div key={index} style={{ display: 'flex', flexDirection: 'row', gap: '8px', alignItems: 'center' }}>
                        <span className="text-text-sm font-normal" style={{ color: index + 1 !== props.path.length ? colors.neutral[500] : colors.neutral[800] }}>
                            {item}
                        </span>
                        {
                            index + 1 !== props.path.length &&
                            <svg width="8" height="20" viewBox="0 0 8 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M4.81325 6.48H6.26125L3.18925 18H1.74125L4.81325 6.48Z" fill="#E4E4F7" />
                            </svg>
                        }
                    </div>
                )
                )}
        </div>
    )
};

export default Breadcrumb;