import clsx from "clsx"
import { ReactElement, ReactNode, useEffect, useRef, useState } from "react"
import { keycloak } from "../../lib/keycloak"
import { useAppDispatch, useAppSelector } from "../../lib/redux/hooks"
import { colors } from "../colors"
import { BellIcon } from "../icons/bell"
import { MailIcon } from "../icons/mail"
import { MenuIcon } from "../icons/menu"
import { SettingsIcon } from "../icons/settings"
import { UsersIcon } from "../icons/users"
import Avatar from "../molecules/avatar"
import Button from "../molecules/button"
import Breadcrumb from "../organisms/breadcrumb"
import { Header } from "../organisms/header"
import SideNav from "../organisms/navbar"
import { MenuItems } from "../organisms/navbar/dto"
import { MenuStatus } from "./dto"
import { setMenuStatus } from "./slice"
import { PeopleList } from "../../pages/database/people/list"

interface Props {
    children?: ReactNode
    headerChildren?: ReactElement
    headerLabel: string
    breadcrumbItems: string[]
    menuItem: MenuItems
    headerBackPath?: string
}

export function Layout(props: Props) {
    const { children } = props

    const layoutState = useAppSelector(state => state.layout)
    const menuRef = useRef<HTMLTableCellElement>(null);
    const dispatch = useAppDispatch()

    const [showMenu, setShowMenu] = useState(false)

    const toggleMenu = () => {
        setShowMenu(!showMenu);
    };

    const closeMenu = () => {
        setShowMenu(false);
    };

    const handleClickOutside = (event: MouseEvent) => {
        if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
            closeMenu();
        }
    };

    useEffect(() => {
        if (showMenu) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [showMenu]);

    return (<div
        className={`antialiased min-h-screen max-h-screen bg-white`}
        style={{ display: 'flex', flexDirection: 'row', height: '100vh', width: '100vw' }}
    >
        <div
            onMouseEnter={() => {
                if (layoutState.menuStatus === MenuStatus.CLOSED) {
                    dispatch(setMenuStatus(MenuStatus.HOVER))
                }
            }}
            onMouseLeave={() => {
                if (layoutState.menuStatus === MenuStatus.HOVER) {
                    dispatch(setMenuStatus(MenuStatus.CLOSED))
                }
            }}
            className={
                clsx(
                    "border-neutral-200 border-r-[1px] transition-all hidden lg:block z-[60] flex-shrink-0",
                    {
                        "w-[240px]": layoutState.menuStatus === MenuStatus.OPEN || layoutState.menuStatus === MenuStatus.HOVER,
                        "w-[72px]": layoutState.menuStatus === MenuStatus.CLOSED,
                        "absolute h-[100%] bg-white": layoutState.menuStatus === MenuStatus.HOVER
                    }
                )
            }
        >
            <SideNav menuItem={props.menuItem} />
        </div>
        <div
            className={
                clsx(
                    "border-neutral-200 border-r-[1px] transition-all lg:hidden flex-shrink-0 z-[60]",
                    {
                        "absolute h-[100%] w-[100%]": layoutState.menuStatus === MenuStatus.HOVER,
                        "hidden": layoutState.menuStatus !== MenuStatus.HOVER
                    }
                )
            }
        >
            <div
                className="w-[100%] h-[100%] backdrop-blur-sm transition-all"
                onClick={() => {
                    dispatch(setMenuStatus(MenuStatus.CLOSED))
                }}
            >
                <div
                    className="w-[240px] h-[100%] bg-white opacity-100 transition-all"
                    onClick={(e) => {
                        e.preventDefault()
                        e.stopPropagation()
                    }}
                >
                    <SideNav menuItem={props.menuItem} />
                </div>
            </div>
        </div>
        <div
            style={{
                flexGrow: 1,
                display: 'flex',
                flexDirection: 'column',
                width: layoutState.menuStatus === MenuStatus.OPEN ? 'calc(100% - 240px)' : layoutState.menuStatus === MenuStatus.HOVER ? 'calc(100% - 72px)' : '100%',
                overflow: 'hidden'  // Aggiunto overflow hidden per evitare che il div padre scorra
            }}
            className={
                clsx(
                    "transition-all",
                    {
                        'ml-[0px] lg:ml-[72px]': layoutState.menuStatus === MenuStatus.HOVER,
                        'ml-[0px]': layoutState.menuStatus !== MenuStatus.HOVER,
                    }
                )
            }
        >
            <div className="flex flex-row items-center justify-between px-[24px]">
                <div className="block lg:hidden py-[20px]">
                    <div
                        className="cursor-pointer"
                        onClick={() => {
                            dispatch(setMenuStatus(MenuStatus.HOVER))
                        }}
                    >
                        <MenuIcon color={colors.neutral[400]} size={24} />
                    </div>
                </div>
                <div className="hidden lg:flex h-[80px]">
                    <Breadcrumb path={props.breadcrumbItems} />
                </div>
                <div className="flex flex-row gap-[8px] items-center">
                    <div className="flex flex-row gap-[4px]">
                        <Button size={"md"} iconPosition={"only"} variant={"link"} color={"gray"} icon={<SettingsIcon color={""} size={0} />} />
                        <Button size={"md"} iconPosition={"only"} variant={"link"} color={"gray"} icon={<MailIcon color={""} size={0} />} />
                        <Button size={"md"} iconPosition={"only"} variant={"link"} color={"gray"} icon={<BellIcon color={""} size={0} />} />
                    </div>
                    <div className="h-[40px] w-[1px] bg-neutral-200" />
                    <span className="text-text-md font-semibold text-neutral-800">
                        {keycloak.hasRealmRole('admin') ? 'Admin' : 'ciao'}
                    </span>
                    <div className="cursor-pointer relative" onClick={e => { toggleMenu() }} ref={menuRef}>
                        <Avatar type="single" size={'sm'} shape='circle' imageUrl='' altTextInitials="AD" />
                        {
                            showMenu &&
                            <div onClick={e => { e.preventDefault(); e.stopPropagation(); keycloak.logout({ redirectUri: window.location.origin }) }} className="absolute bg-white right-2 shadow-sm rounded-xl p-3 gap-2 flex items-center w-[200px]">
                                <UsersIcon color={colors.neutral[500]} size={20} />
                                <span className="text-text-md text-neutral-600 font-semibold">Logout</span>
                            </div>
                        }
                    </div>
                </div>
            </div>
            <div className="h-[1px] w-[100%] bg-neutral-200 flex-shrink-0" />
            <Header
                backPath={props.headerBackPath}
                label={props.headerLabel}
            >
                {props.headerChildren}
            </Header>
            <div
                style={{
                    flexGrow: 1,
                    padding: !children ? '24px' : '0px',
                    overflowY: 'auto',  // Aggiunto overflowY auto per lo scroll
                    height: '100%',  // Impostato altezza 100%
                }}
                className="bg-neutral-50 border-t-[1px] border-t-neutral-200"
            >
                {
                    !children ?
                        <div className="rounded-[12px] shadow-sm border-[1px] h-[100%] border-neutral-200" /> :
                        children
                }
            </div>
        </div>
    </div>)
}