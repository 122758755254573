import { useRef, useState } from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { TabPagesProps } from "./dto";
import './style.css';

export function TabPages(props: TabPagesProps) {
    const touchStartXRef = useRef<number | null>(null);
    const [animating, setAnimating] = useState(false);

    const nextSlide = () => {
        if (!animating) {
            setAnimating(true);
            props.setDirection('left');
            props.setValue((props.value + 1) % props.pages.length);
        }
    };

    const prevSlide = () => {
        if (!animating) {
            setAnimating(true);
            props.setDirection('right');
            props.setValue((props.value - 1 + props.pages.length) % props.pages.length);
        }
    };

    const handleTouchStart = (e: React.TouchEvent) => {
        touchStartXRef.current = e.touches[0].clientX;
    };

    const handleTouchEnd = (e: React.TouchEvent) => {
        if (touchStartXRef.current !== null) {
            const touchEndX = e.changedTouches[0].clientX;
            const touchDifference = touchStartXRef.current - touchEndX;

            if (touchDifference > 50) {
                nextSlide();
            } else if (touchDifference < -50) {
                prevSlide();
            }

            touchStartXRef.current = null;
        }
    };

    const handleMouseDown = (e: React.MouseEvent) => {
        touchStartXRef.current = e.clientX;
    };

    const handleMouseUp = (e: React.MouseEvent) => {
        if (touchStartXRef.current !== null) {
            const mouseEndX = e.clientX;
            const mouseDifference = touchStartXRef.current - mouseEndX;

            if (mouseDifference > 50) {
                nextSlide();
            } else if (mouseDifference < -50) {
                prevSlide();
            }

            touchStartXRef.current = null;
        }
    };

    const handleAnimationEnd = () => setAnimating(false);

    return (
        <div className="relative overflow-hidden h-full"
            onTouchStart={handleTouchStart}
            onTouchEnd={handleTouchEnd}
        // onMouseDown={handleMouseDown}
        // onMouseUp={handleMouseUp}
        >
            <TransitionGroup className="h-full w-full">
                {props.pages.map((page, index) => (
                    index === props.value && (
                        <CSSTransition
                            key={index}
                            classNames={`slide-${props.direction}`}
                            timeout={500}
                            onEntered={handleAnimationEnd}
                            onExited={handleAnimationEnd}
                        >
                            <div className="h-full w-full">
                                {page}
                            </div>
                        </CSSTransition>
                    )
                ))}
            </TransitionGroup>
        </div>
    );
}
