import { roleDepartment } from "../../../../lib/preferences/dto"
import { useAppDispatch, useAppSelector } from "../../../../lib/redux/hooks"
import { groupBy } from "../../../../lib/utils"
import { PersonalCardIcon } from "../../../../ui/icons/personalCard"
import { SelectCustom } from "../../../../ui/molecules/select"
import { AccordionFilter } from "../../../../ui/organisms/accordionFilter"
import { setFindAllCompaniesFiltersRole } from "../slice"

export function RoleFilter() {
    const companiesState = useAppSelector(state => state.companies)
    const databaseState = useAppSelector(state => state.database)
    const preferencesState = useAppSelector(state => state.preferences)

    const dispatch = useAppDispatch()

    const filteredRoles = roleDepartment
        .filter(department => companiesState.filters.department.length > 0 ? companiesState.filters.department.map(_department => _department.value).includes(department.department) : department)


    return (
        <AccordionFilter
            key={'person-filter-role'}
            label={"Ruolo"}
            icon={<PersonalCardIcon color={""} size={0} />}
            showClear={companiesState.filters.role.length > 0}
            options={companiesState.filters.role}
            clearAction={function (): void {
                dispatch(setFindAllCompaniesFiltersRole([]))
            }}
            deleteFilter={function (option): void {
                dispatch(setFindAllCompaniesFiltersRole(companiesState.filters.role.filter(name => name.value !== option.value)))
            }}
        >
            <SelectCustom
                isMulti
                isClearable={false}
                onChange={e => {
                    if (e !== undefined && typeof e !== 'string') {
                        dispatch(setFindAllCompaniesFiltersRole(e.map(option => ({ value: option, label: option }))))
                    }
                }}
                defaultValue={companiesState.filters.role.map(option => option.value)}
                placeholder={"Cerca per ruolo"}
                options={groupBy(filteredRoles, 'role').map(sector => ({ value: sector.key, label: sector.key }))}
                noOptionsMessage="Non sono presenti ruoli."
            />
        </AccordionFilter>
    )
}