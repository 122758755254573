import clsx from "clsx";
import { SpinnerProps } from "./dto";

export function Spinner(props: SpinnerProps) {
    return (
        <div
            style={{ width: props.size ?? 40 + 'px', height: props.size ?? 40 + 'px', borderWidth: '2px' }}
            className={clsx(`rounded-full animate-spin`, {
                "border-t-brandPrimary-500 border-brandPrimary-200": props.color === 'primary' || props.color === undefined,
                "border-t-brandSecondary-500 border-brandSecondary-200": props.color === 'secondary',
                "border-t-info-500 border-info-200": props.color === 'info',
                "border-t-neutral-500 border-neutral-200": props.color === 'neutral',
                "border-t-red-500 border-red-200": props.color === 'red',
                "border-t-orange-500 border-orange-200": props.color === 'orange',
                "border-t-green-500 border-green-200": props.color === 'green',
            })}
        />
    )
}