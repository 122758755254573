import clsx from "clsx"
import { useEffect, useState } from "react"
import { roleDepartment } from "../../../lib/preferences/dto"
import { useAppDispatch, useAppSelector } from "../../../lib/redux/hooks"
import { groupBy } from "../../../lib/utils"
import { colors } from "../../../ui/colors"
import { LinkedinIcon } from "../../../ui/icons/linkedin"
import { MailIcon } from "../../../ui/icons/mail"
import { PhoneCallIcon } from "../../../ui/icons/phoneCall"
import { PlusIcon } from "../../../ui/icons/plus"
import Avatar from "../../../ui/molecules/avatar"
import Button from "../../../ui/molecules/button"
import Input from "../../../ui/molecules/input/Input"
import { SelectCustom } from "../../../ui/molecules/select"
import { Spinner } from "../../../ui/molecules/spinner"
import Toggle from "../../../ui/molecules/toggle"
import { TabMenu } from "../../../ui/organisms/tabs/tabMenu"
import { TabPages } from "../../../ui/organisms/tabs/tabPages"
import { EditCompanyForm } from "../companies/editCompanyForm"
import { editCompany, findCompany, setEditCompanyAddress, setEditCompanyAtecoCode, setEditCompanyCap, setEditCompanyCategory, setEditCompanyCity, setEditCompanyEmail, setEditCompanyEmployeesNumber, setEditCompanyName, setEditCompanyPhone, setEditCompanyProvince, setEditCompanyRegion, setEditCompanyRevenue, setEditCompanySector, setEditCompanyState, setEditCompanyVat, setEditCompanyWebSite } from "../companies/slice"
import { editPerson, findPerson, setEditPersonCompanyId, setEditPersonDepartment, setEditPersonEmail, setEditPersonLinkedinProfile, setEditPersonName, setEditPersonPhone, setEditPersonPrivacy, setEditPersonRole, setEditPersonSurname, setSelectedPerson } from "./slice"

export function EditPerson() {
    const peopleState = useAppSelector(state => state.people)
    const companiesState = useAppSelector(state => state.companies)
    const [direction, setDirection] = useState<'left' | 'right'>('left');
    const [tabValue, setTabValue] = useState<number>(0);

    const dispatch = useAppDispatch()

    useEffect(() => {
        if (peopleState.selectedPerson !== undefined) {
            dispatch(findPerson(peopleState.selectedPerson))
            setTabValue(0)
        }
    }, [peopleState.selectedPerson])

    useEffect(() => {
        if (peopleState.findPersonStatus === 'successfully') {
            dispatch(setEditPersonName(peopleState.findPersonResponse?.name))
            dispatch(setEditPersonSurname(peopleState.findPersonResponse?.surname))
            dispatch(setEditPersonRole(peopleState.findPersonResponse?.role))
            dispatch(setEditPersonDepartment(peopleState.findPersonResponse?.department))
            dispatch(setEditPersonPrivacy(peopleState.findPersonResponse?.privacy))
            dispatch(setEditPersonPhone(peopleState.findPersonResponse?.phone))
            dispatch(setEditPersonEmail(peopleState.findPersonResponse?.email))
            dispatch(setEditPersonLinkedinProfile(peopleState.findPersonResponse?.linkedinProfile))
            dispatch(setEditPersonCompanyId(peopleState.findPersonResponse?.companyId))
            dispatch(findCompany(peopleState.findPersonResponse?.companyId!))
        }
    }, [peopleState.findPersonStatus])

    useEffect(() => {
        if (companiesState.findCompanyStatus === 'successfully') {
            dispatch(setEditCompanyVat(companiesState.findCompanyResponse?.vat))
            dispatch(setEditCompanyName(companiesState.findCompanyResponse?.name))
            dispatch(setEditCompanySector(companiesState.findCompanyResponse?.sector))
            dispatch(setEditCompanyCategory(companiesState.findCompanyResponse?.category))
            dispatch(setEditCompanyAtecoCode(companiesState.findCompanyResponse?.atecoCode))
            dispatch(setEditCompanyAddress(companiesState.findCompanyResponse?.address))
            dispatch(setEditCompanyRegion(companiesState.findCompanyResponse?.countryRegion))
            dispatch(setEditCompanyState(companiesState.findCompanyResponse?.state))
            dispatch(setEditCompanyCity(companiesState.findCompanyResponse?.city))
            dispatch(setEditCompanyProvince(companiesState.findCompanyResponse?.province))
            dispatch(setEditCompanyCap(companiesState.findCompanyResponse?.zipCode))
            dispatch(setEditCompanyWebSite(companiesState.findCompanyResponse?.website))
            dispatch(setEditCompanyEmail(companiesState.findCompanyResponse?.email))
            dispatch(setEditCompanyRevenue(companiesState.findCompanyResponse?.revenue))
            dispatch(setEditCompanyPhone(companiesState.findCompanyResponse?.phone))
            dispatch(setEditCompanyEmployeesNumber(companiesState.findCompanyResponse?.employeesNumber))
        }
    }, [companiesState.findCompanyStatus])

    return (
        <>
            {
                peopleState.findPersonStatus === 'loading' || peopleState.editPersonStatus === 'loading' || companiesState.editCompanyStatus === 'loading' ?
                    <Spinner /> :
                    <div>
                        <div className="px-6 pt-4 flex items-center justify-between">
                            <Avatar
                                type="info"
                                imageUrl=""
                                firstRow={peopleState.findPersonResponse?.name + ' ' + peopleState.findPersonResponse?.surname}
                                secondRow={peopleState.findPersonResponse?.role}
                                thirdRow={peopleState.findPersonResponse?.companyName}
                                size="md"
                                altTextInitials={peopleState.findPersonResponse?.name[0].toUpperCase()! + peopleState.findPersonResponse?.surname[0].toUpperCase()!}
                            />
                            <div className="flex items-center gap-4">
                                <Button size={"sm"} iconPosition={"off"} variant={"outline"} color={"blue"} label="Salva" onClick={() => {
                                    dispatch(editCompany({ data: companiesState.editCompanyRequest, id: companiesState.findCompanyResponse?.id! }))
                                    dispatch(editPerson({ data: peopleState.editPersonRequest, id: peopleState.findPersonResponse?.id! }))
                                }} />
                                <div className="cursor-pointer rotate-45" onClick={() => dispatch(setSelectedPerson(undefined))}>
                                    <PlusIcon color={colors.neutral[500]} size={20} />
                                </div>
                            </div>
                        </div>
                        <TabMenu
                            direction={direction}
                            setDirection={value => setDirection(value)}
                            setValue={e => setTabValue(e)}
                            value={tabValue}
                            items={[
                                {
                                    icon: <></>,
                                    label: "Anagrafica"
                                },
                                {
                                    icon: <></>,
                                    label: "Azienda"
                                },
                            ]}
                        />
                        <TabPages
                            direction={direction}
                            setDirection={value => setDirection(value)}
                            pages={[
                                <div key={0} className="px-6 py-4 flex flex-col gap-4">
                                    <div className="flex gap-2">
                                        <Input label="Nome" disabled={peopleState.findPersonResponse?.active} value={peopleState.editPersonRequest?.name ?? ''} onChangeText={text => dispatch(setEditPersonName(text))} />
                                        <Input label="Cognome" disabled={peopleState.findPersonResponse?.active} value={peopleState.editPersonRequest?.surname ?? ''} onChangeText={text => dispatch(setEditPersonSurname(text))} />
                                    </div>
                                    <div className="flex flex-col gap-[6px]">
                                        <span style={{ fontSize: '14px', fontWeight: 500, lineHeight: '16px', textAlign: 'left', color: 'rgba(78, 94, 126, 1)' }}>Ruolo</span>
                                        <SelectCustom
                                            isClearable
                                            disabled={peopleState.findPersonResponse?.active}
                                            onChange={e => {
                                                if (e === undefined || typeof e === 'string') {
                                                    dispatch(setEditPersonRole(e))
                                                }
                                            }}
                                            defaultValue={peopleState.editPersonRequest?.role ?? undefined}
                                            placeholder={"Cerca per ruolo"}
                                            options={groupBy(roleDepartment, 'role').map(sector => ({ value: sector.key, label: sector.key }))}
                                            noOptionsMessage="Non sono presenti ruoli."
                                        />
                                    </div>
                                    <Input label="Reparto" disabled value={roleDepartment.find(role => role.role === peopleState.editPersonRequest.role)?.department || ''} onChangeText={text => dispatch(setEditPersonDepartment(text))} />
                                    <Toggle disabled={peopleState.findPersonResponse?.active} placeholder="Permessi privacy" checked={peopleState.editPersonRequest?.privacy ?? false} onChange={function (value: boolean): void {
                                        dispatch(setEditPersonPrivacy(value))
                                    }} />
                                    <Input label="Cellulare" endIcon={<PhoneCallIcon color={""} size={0} />} value={peopleState.editPersonRequest?.phone ?? ''} onChangeText={text => dispatch(setEditPersonPhone(text))} />
                                    <Input label="Mail" endIcon={<MailIcon color={""} size={0} />} value={peopleState.editPersonRequest?.email ?? ''} onChangeText={text => dispatch(setEditPersonEmail(text))} />
                                    <Input label="LinkedIn" endIcon={<LinkedinIcon color={""} size={0} />} value={peopleState.editPersonRequest?.linkedinProfile ?? ''} onChangeText={text => dispatch(setEditPersonLinkedinProfile(text))} />
                                </div>,
                                <div key={1} className="h-full">
                                    <div className={clsx("flex items-center justify-center h-[50%]", { 'hidden': companiesState.findCompanyStatus !== 'loading' })}>
                                        <Spinner />
                                    </div>
                                    <div className={clsx("flex items-center justify-center pt-8", { 'hidden': companiesState.findCompanyStatus !== 'failed' })}>
                                        <span className="text-text-sm text-neutral-800">
                                            Non è stata trovata nessuna azienda associata al contatto.
                                        </span>
                                    </div>
                                    <div className={clsx("flex items-center justify-center pb-4", { 'hidden': companiesState.findCompanyStatus !== 'successfully' })}>
                                        <EditCompanyForm />
                                    </div>
                                </div>,
                            ]}
                            value={tabValue}
                            setValue={(e) => setTabValue(e)}
                        />
                    </div >
            }
        </>
    )
}