import { CSSProperties, ReactElement } from "react"
import { DefaultIcon } from "./icons/defaultIcon"
import { DragonIcon } from "./icons/dragonIcon"
import { ErrorIcon } from "./icons/errorIcon"
import { HoverIcon } from "./icons/hoverIcon"
import { colors } from "../../colors"

export interface FileUploaderProps {
    id: string
    disabled?: boolean
    error?: boolean
    style: FileUploaderStyle
    multiple?: boolean
    onChange?: (event: File[]) => void
}

export type FileUploaderStatus = 'default' | 'hover' | 'dragon' | 'focus' | 'error' | 'disabled'
export type FileUploaderStyle = 'large' | 'small'

export const FileUploadStatusMap = new Map<FileUploaderStatus, { style: CSSProperties, icon: ReactElement }>([
    ['default', {
        style: {
            backgroundColor: 'rgba(249, 249, 251, 1)',
            border: '1px dashed ' + colors.neutral[300],
        },
        icon: DefaultIcon(),
    }],
    ['hover', {
        style: {
            backgroundColor: 'rgba(246, 247, 249, 1)',
            border: '1px dashed ' + colors.neutral[300],
        },
        icon: HoverIcon(),
    }],
    ['dragon', {
        style: {
            backgroundColor: 'rgba(250, 250, 255, 1)',
            border: '1px dashed rgba(138, 142, 229, 1)',
            opacity: '1 !important'
        },
        icon: DragonIcon(),
    }],
    ['focus', {
        style: {
            backgroundColor: 'rgba(250, 250, 255, 1)',
            border: '1px dashed rgba(138, 142, 229, 1)',
            opacity: '1 !important',
            boxShadow: '0px 0px 0px 4px rgba(146, 92, 255, 0.16)',
        },
        icon: DragonIcon(),
    }],
    ['error', {
        style: {
            backgroundColor: 'white',
            border: '1px dashed rgba(255, 164, 158, 1)',
        },
        icon: ErrorIcon(),
    }],
    ['disabled', {
        style: {
            backgroundColor: 'rgba(249, 249, 251, 1)',
            border: '1px dashed rgba(242, 242, 250, 1)',
            opacity: 0.56,
            cursor: 'default'
        },
        icon: DefaultIcon(),
    }],
])

export const FileUploadStyleMap = new Map<FileUploaderStyle, CSSProperties>([
    ['large', {
        height: '156px',
        padding: '32px 24px 32px 24px',
        flexDirection: 'column',
        alignItems: 'center',
    }],
    ['small', {
        height: '84px',
        padding: '8px',
        flexDirection: 'column',
        alignItems: 'start',
    }]
])
