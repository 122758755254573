import qs from "qs";
import { cdAxios } from "../../../lib/axios";
import { keycloak } from "../../../lib/keycloak";
import { ImportCSVResponseDTO } from "../dto";
import { AssociatePersonToCompany, EditPersonRequest, FindAllPeopleFilters, FindAllPeopleResponse, PersonDTO } from "./dto";
import { PeopleService } from "./service";

export class PeopleServiceImpl implements PeopleService {

    public findAllPeople(filters: FindAllPeopleFilters): Promise<FindAllPeopleResponse> {
        return cdAxios.get("/api/person", {
            params: {
                itemsPerPage: filters.itemsPerPage,
                page: filters.page,
                sort: filters.sort,
                order: filters.order,
                privacy: filters.privacy?.value,
                revenue: filters.revenue?.value,
                employeesNumber: filters.employees?.value,
                personName: filters.personName,
                personEmail: filters.personEmail,
                hasCompany: filters.hasCompany,
                smartphone: filters.smartphone?.value,
                atecoCode: filters.atecos.map(ateco => '~:' + ateco.value),
                category: filters.category.map(category => category.value),
                department: filters.department.map(department => department.value),
                name: filters.name.map(name => name.label),
                role: filters.role.map(role => role.value),
                sector: filters.sector.map(sector => sector.value),
                state: filters.state?.value,
                city: filters.city.map(city => city.value),
                countryRegion: filters.countryRegion.map(countryRegion => countryRegion.value),
                zipCode: filters.zipCode.map(zipCode => zipCode.value),
            },
            paramsSerializer: params => {
                return qs.stringify(params, {
                    arrayFormat: 'repeat',
                    encode: false
                });
            },
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => response.data)
    }

    public deletePerson(id: string): Promise<void> {
        return cdAxios.delete("/api/person/" + id, {
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => response.data)
    }

    public findPersonById(id: string): Promise<PersonDTO> {
        return cdAxios.get("/api/person/" + id, {
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => response.data)
    }

    public updatePerson(id: string, data: EditPersonRequest): Promise<void> {
        return cdAxios.put("/api/person/" + id, data, {
            headers: {
                Authorization: 'Bearer ' + keycloak.token,
                Accept: '*/*',
                ContentType: 'application/json',
                "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Methods": "GET",
            }
        }).then((response) => response.data)
    }

    public importContact(data: FormData): Promise<ImportCSVResponseDTO> {
        return cdAxios.post("/api/person/csv",
            data,
            {
                headers: {
                    Authorization: 'Bearer ' + keycloak.token,
                    Accept: '*/*',
                    'Content-Type': 'multipart/form-data'
                }
            }
        ).then((response) => response.data);
    }

    public associateCompany(data: AssociatePersonToCompany, personId: string): Promise<void> {
        return cdAxios.post("/api/person/" + personId + "/company",
            data,
            {
                headers: {
                    Authorization: 'Bearer ' + keycloak.token,
                    Accept: '*/*',
                    ContentType: 'application/json',
                    "Access-Control-Allow-Headers": "accept, authorization, content-type, x-requested-with",
                    "Access-Control-Allow-Origin": "http://localhost:3000",
                    "Access-Control-Allow-Methods": "GET",
                }
            }
        ).then((response) => response.data);
    }
}
