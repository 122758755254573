import { ObjectServiceImpl } from "./serviceImpl";

export interface ObjectService {
    findFileById(id: string): Promise<string>
    findFileByIdWithUserId(id: string, userid: string): Promise<{ userId: string, objectId: string }>
}

export function NewObjectService(): ObjectService {
    return new ObjectServiceImpl();
}
