import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../lib/redux/hooks";
import { BuildingIcon } from "../../ui/icons/building";
import { DownloadIcon } from "../../ui/icons/download";
import { FiltersLinesIcon } from "../../ui/icons/filtersLines";
import { MoreHorizontalIcon } from "../../ui/icons/moreHorizontal";
import { TrashIcon } from "../../ui/icons/trash";
import { UsersIcon } from "../../ui/icons/users";
import Button from "../../ui/molecules/button";
import { TabMenu } from "../../ui/organisms/tabs/tabMenu";
import { setSelectedCompany } from "./companies/slice";
import { setSelectedPerson } from "./people/slice";
import { setFiltersActive, setOptionsActive, setTabValue } from "./slice";

interface Props {
    direction: 'left' | 'right'
    setDirection: (value: 'left' | 'right') => void
}

export function HeaderDatabase(props: Props) {
    const databaseState = useAppSelector(state => state.database)
    const dispatch = useAppDispatch()
    const navigate = useNavigate()

    return (
        <div className="flex h-full w-full items-center justify-between">
            <div className="h-full">
                <TabMenu
                    direction={props.direction}
                    setDirection={value => props.setDirection(value)}
                    setValue={e => { dispatch(setTabValue(e)); dispatch(setSelectedCompany(undefined)); dispatch(setSelectedPerson(undefined)) }}
                    value={databaseState.tabValue}
                    items={[
                        {
                            icon: <UsersIcon color={""} size={0} />,
                            label: "Persone"
                        },
                        {
                            icon: <BuildingIcon color={""} size={0} />,
                            label: "Aziende"
                        },
                        // {
                        //     icon: <TrashIcon color={""} size={0} />,
                        //     label: "Cestino"
                        // }
                    ]}
                />
            </div>
            <div className="h-full flex items-center gap-[8px]">
                <Button
                    size={"sm"}
                    iconPosition={"only"}
                    variant={"ghost"}
                    color={"gray"}
                    icon={<DownloadIcon color={""} size={0} />}
                />
                <Button
                    size={"sm"}
                    iconPosition={"only"}
                    variant={databaseState.optionsActive ? 'outline' : "ghost"}
                    color={databaseState.optionsActive ? 'blue' : "gray"}
                    onClick={() => dispatch(setOptionsActive(!databaseState.optionsActive))}
                    icon={<MoreHorizontalIcon color={""} size={0} />}
                />
                <Button
                    size={"sm"}
                    iconPosition={"only"}
                    variant={databaseState.filtersActive ? 'outline' : "ghost"}
                    color={databaseState.filtersActive ? 'blue' : "gray"}
                    onClick={() => dispatch(setFiltersActive(!databaseState.filtersActive))}
                    icon={<FiltersLinesIcon color={""} size={0} />}
                />
                <Button
                    size={"sm"}
                    iconPosition={"off"}
                    variant={"solid"}
                    color={"blue"}
                    onClick={() => {
                        if (databaseState.tabValue === 0) {
                            navigate('/import-people')
                        }
                        if (databaseState.tabValue === 1) {
                            navigate('/import-companies')
                        }
                    }}
                    label="Importa contatti"
                />
            </div>
        </div>
    )
}