import { useAppDispatch, useAppSelector } from "../../../../lib/redux/hooks"
import { colors } from "../../../../ui/colors"
import { ChevronIcon } from "../../../../ui/icons/chevron"
import { ListIcon } from "../../../../ui/icons/list"
import { PlusIcon } from "../../../../ui/icons/plus"
import { setOption, setOptionsActive } from "../../slice"

export function DefaultOption() {
    const dispatch = useAppDispatch()
    const preferencesState = useAppSelector(state => state.preferences)

    const columnActive = preferencesState.findAllResponse?.data[0].companyPreferences ? preferencesState.findAllResponse?.data[0].companyPreferences.filter(col => col.visible).length : 0

    return (
        <>
            <div className="h-12 flex-shrink-0 px-[16px] py-[12px] flex items-center justify-between border-b-[1px]">
                <span className="text-label-md text-neutral-800 font-bold">
                    Opzioni
                </span>
                <div className="rotate-45 cursor-pointer" onClick={() => dispatch(setOptionsActive(false))}>
                    <PlusIcon color={colors.neutral[400]} size={24} />
                </div>
            </div>
            <div className="h-full bg-neutral-50 px-4 overflow-auto">
                <div className="cursor-pointer flex items-center justify-between py-4" onClick={() => dispatch(setOption('columns'))}>
                    <div className="flex items-center gap-2">
                        <ListIcon color={colors.neutral[600]} size={16} />
                        <span className="text-label-md font-medium text-neutral-600">
                            Colonne
                        </span>
                    </div>
                    <div className="flex items-center gap-2">
                        <span className="text-label-sm font-medium text-neutral-400">
                            {preferencesState.findAllResponse?.data[0].companyPreferences.filter(col => col.visible).length + (columnActive === 1 ? ' attiva' : ' attive')}
                        </span>
                        <ChevronIcon color={colors.neutral[400]} size={16} direction="r" />
                    </div>
                </div>
            </div>
        </>
    )
}