
export interface DatabasePreferences {
    column: string
    visible: boolean
}

export interface Preferences {
    id: string,
    owner: string,
    personPreferences: DatabasePreferences[],
    companyPreferences: DatabasePreferences[]
}

export interface CreatePreferenceRequest {
    owner: string,
    personPreferences: DatabasePreferences[],
    companyPreferences: DatabasePreferences[]
}

export interface FindAllPreferencesResponse {
    total: number,
    page: number,
    perPage: number,
    prev: false,
    next: false,
    totalPage: number,
    data: Preferences[]
}

export interface AtecoDTO {
    atecoCode: string
    atecoDescription: string
    sector: string
    category: string
}

export const alwaysVisiblePeople = ['Contatto', 'Telefono', 'Email', 'Profilo Linkedin', 'Ruolo', 'Azienda', 'Reparto', 'Privacy']
export const alwaysVisibleCompany = ['Azienda', 'Fatturato', 'Telefono', 'Cod. ATECO', 'Settore']

export const roleDepartment = [
    { department: "Proprietà e Direzione", role: "Amministratore Delegato" },
    { department: "Proprietà e Direzione", role: "Titolare" },
    { department: "Proprietà e Direzione", role: "Direttore Generale (General Manager)" },
    { department: "Amministrazione e Contabilità", role: "Responsabile Amministrazione" },
    { department: "Amministrazione e Contabilità", role: "Responsabile del Controllo di Gestione" },
    { department: "Amministrazione e Contabilità", role: "Direttore finanziario" },
    { department: "Amministrazione e Contabilità", role: "Segretaria amministrativa" },
    { department: "Amministrazione e Contabilità", role: "Controller" },
    { department: "Amministrazione e Contabilità", role: "Responsabile acquisiti" },
    { department: "Marketing e Vendite", role: "Direttore Marketing" },
    { department: "Marketing e Vendite", role: "Direttore Commerciale" },
    { department: "Marketing e Vendite", role: "Marketing manager" },
    { department: "Marketing e Vendite", role: "Digital Marketing manager" },
    { department: "Marketing e Vendite", role: "Responsabile Vendite" },
    { department: "Marketing e Vendite", role: "Brand Manager" },
    { department: "Marketing e Vendite", role: "E-commerce manager" },
    { department: "Marketing e Vendite", role: "Trade marketing manager" },
    { department: "Marketing e Vendite", role: "Responsabile della Comunicazione" },
    { department: "Marketing e Vendite", role: "Marketing specialist" },
    { department: "Marketing e Vendite", role: "Responsabile eventi" },
    { department: "Marketing e Vendite", role: "Import export manager" },
    { department: "Marketing e Vendite", role: "Project Manager" },
    { department: "Risorse Umane", role: "Recruiter" },
    { department: "Risorse Umane", role: "HR Specialist" },
    { department: "Risorse Umane", role: "Hr Manager" },
    { department: "Risorse Umane", role: "Responsabile risorse umane" },
    { department: "Risorse Umane", role: "Responsabile Corsi, Formazione e Sviluppo" },
    { department: "Risorse Umane", role: "Assistente risorse umane" },
    { department: "IT e Tecnologia", role: "Chief Information Officer (CIO)" },
    { department: "IT e Tecnologia", role: "Responsabile IT" },
    { department: "IT e Tecnologia", role: "Sviluppatore Software" },
    { department: "IT e Tecnologia", role: "Analista di Sistemi" },
    { department: "IT e Tecnologia", role: "Specialista in Sicurezza Informatica" },
    { department: "IT e Tecnologia", role: "It Manager" },
    { department: "Produzione e Logistica", role: "Direttore di Produzione" },
    { department: "Produzione e Logistica", role: "Responsabile della Logistica" },
    { department: "Produzione e Logistica", role: "Responsabile Magazzino" },
    { department: "Produzione e Logistica", role: "Ingegnere di Produzione" },
    { department: "Ricerca e Sviluppo", role: "Responsabile ricerca e sviluppo" },
    { department: "Ricerca e Sviluppo", role: "Ricercatore" },
    { department: "Ricerca e Sviluppo", role: "Ingegnere di Prodotto" },
    { department: "Ricerca e Sviluppo", role: "Specialista in Innovazione" },
    { department: "Ricerca e Sviluppo", role: "Responsabile innovazione" },
    { department: "Legale e Compliance", role: "Responsabile ufficio legale" },
    { department: "Legale e Compliance", role: "Consulente Legale" },
    { department: "Legale e Compliance", role: "Responsabile della Compliance" },
    { department: "Legale e Compliance", role: "Specialista in Proprietà Intellettuale" },
    { department: "Legale e Compliance", role: "Assistente legale" },
    { department: "Legale e Compliance", role: "Reputation Manager" },
    { department: "Qualità e sicurezza", role: "Responsabile qualità" },
    { department: "Qualità e sicurezza", role: "Responsabile sicurezza" },
    { department: "Qualità e sicurezza", role: "Rspp" },
    { department: "Qualità e sicurezza", role: "Hse Manager" },
    { department: "Qualità e sicurezza", role: "HSE&Q Manager" },
    { department: "Qualità e sicurezza", role: "Responsabile Manutenzione" },
    { department: "Qualità e sicurezza", role: "Responsabile sistemi di gestione" }
];
