import clsx from "clsx";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../lib/redux/hooks";
import { Spinner } from "../../../ui/molecules/spinner";
import { Banner } from "../../../ui/organisms/banner";
import { NotFound } from "../../../ui/organisms/notFound";
import { findAllCompanies, setEditCompanyStatus } from "../companies/slice";
import { setTabValue } from "../slice";
import { EditPerson } from "./editPerson";
import { Filters } from "./filters";
import { PeopleHorizontalFilters } from "./filters/horizontalFilters";
import { PeopleList } from "./list";
import { Options } from "./options/options";
import { findAllPeople, setAssociatePersonToCompanyStatus, setDeletePersonStatus, setEditPersonStatus, setFindAllPeopleFiltersPage, setPersonToAddCompany, setSelectedPerson } from "./slice";

export function People() {
    const databaseState = useAppSelector(state => state.database)
    const peopleState = useAppSelector(state => state.people)
    const companiesState = useAppSelector(state => state.companies)

    const dispatch = useAppDispatch()
    const navigate = useNavigate()

    const [isFirstLoad, setIsFirstLoad] = useState(true);

    useEffect(() => {
        dispatch(findAllPeople(peopleState.filters))
        setIsFirstLoad(false);
    }, [])

    useEffect(() => {
        if (!isFirstLoad) {
            dispatch(setFindAllPeopleFiltersPage(0));
            dispatch(findAllPeople({ ...peopleState.filters, page: 0 }))
        }
    }, [peopleState.filters.order, peopleState.filters.sort, peopleState.filters.personName, peopleState.filters.personEmail, peopleState.filters.hasCompany])

    useEffect(() => {
        if (!isFirstLoad)
            dispatch(findAllPeople(peopleState.filters))
    }, [peopleState.filters.page])

    useEffect(() => {
        let timeoutId: NodeJS.Timeout;
        if (peopleState.deletePersonStatus === 'failed' || peopleState.deletePersonStatus === 'successfully') {
            if (peopleState.deletePersonStatus === 'successfully') {
                dispatch(findAllPeople(peopleState.filters))
            }
            timeoutId = setTimeout(() => {
                dispatch(setDeletePersonStatus('idle'))
            }, 5000);
        }
        return () => {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
        };
    }, [peopleState.deletePersonStatus]);

    useEffect(() => {
        let timeoutId: NodeJS.Timeout;
        if (companiesState.editCompanyStatus === 'failed' || companiesState.editCompanyStatus === 'successfully') {
            if (companiesState.editCompanyStatus === 'successfully') {
                dispatch(findAllCompanies(companiesState.filters))
            }
            timeoutId = setTimeout(() => {
                dispatch(setEditCompanyStatus('idle'))
            }, 5000);
        }
        return () => {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
        };
    }, [companiesState.editCompanyStatus]);

    useEffect(() => {
        let timeoutId: NodeJS.Timeout;
        if (peopleState.editPersonStatus === 'failed' || peopleState.editPersonStatus === 'successfully') {
            if (peopleState.editPersonStatus === 'successfully') {
                dispatch(setSelectedPerson(undefined))
                dispatch(findAllPeople(peopleState.filters))
            }
            timeoutId = setTimeout(() => {
                dispatch(setEditPersonStatus('idle'))
            }, 5000);
        }
        return () => {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
        };
    }, [peopleState.editPersonStatus]);

    useEffect(() => {
        let timeoutId: NodeJS.Timeout;
        if (peopleState.associatePersonToCompanyStatus === 'failed' || peopleState.associatePersonToCompanyStatus === 'successfully') {
            if (peopleState.associatePersonToCompanyStatus === 'successfully') {
                dispatch(setPersonToAddCompany(undefined))
                dispatch(findAllPeople(peopleState.filters))
            }
            timeoutId = setTimeout(() => {
                dispatch(setAssociatePersonToCompanyStatus('idle'))
            }, 5000);
        }
        return () => {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
        };
    }, [peopleState.associatePersonToCompanyStatus]);

    useEffect(() => {
        if (peopleState.importCSVStatus === 'failed' || peopleState.importCSVStatus === 'successfully') {
            navigate('/database')
            dispatch(setTabValue(0))
        }
    }, [peopleState.importCSVStatus]);

    return (
        <div className="flex flex-col max-h-full pl-[24px] py-[24px] gap-4">
            <div className="absolute flex flex-col gap-4 right-6">
                <Banner
                    label={"Persona eliminata."}
                    visible={peopleState.deletePersonStatus === 'successfully'}
                    closeAction={function (): void {
                        dispatch(setDeletePersonStatus('idle'))
                    }}
                    type={"success"}
                />
                <Banner
                    label={"Si è verificato un errore durante l'eliminazione della persona."}
                    visible={peopleState.deletePersonStatus === 'failed'}
                    closeAction={function (): void {
                        dispatch(setDeletePersonStatus('idle'))
                    }}
                    type={"error"}
                />
                <Banner
                    label={"Persona associata correttamente all'azienda."}
                    visible={peopleState.associatePersonToCompanyStatus === 'successfully'}
                    closeAction={function (): void {
                        dispatch(setAssociatePersonToCompanyStatus('idle'))
                    }}
                    type={"success"}
                />
                <Banner
                    label={"Si è verificato un errore durante l'associazione della persona all'azienda."}
                    visible={peopleState.associatePersonToCompanyStatus === 'failed'}
                    closeAction={function (): void {
                        dispatch(setAssociatePersonToCompanyStatus('idle'))
                    }}
                    type={"error"}
                />
                <Banner
                    label={"Persona aggiornata."}
                    visible={peopleState.editPersonStatus === 'successfully'}
                    closeAction={function (): void {
                        dispatch(setEditPersonStatus('idle'))
                    }}
                    type={"success"}
                />
                <Banner
                    label={"Si è verificato un errore durante la modifica della persona."}
                    visible={peopleState.editPersonStatus === 'failed'}
                    closeAction={function (): void {
                        dispatch(setEditPersonStatus('idle'))
                    }}
                    type={"error"}
                />
                <Banner
                    label={"Azienda aggiornata."}
                    visible={companiesState.editCompanyStatus === 'successfully'}
                    closeAction={function (): void {
                        dispatch(setEditCompanyStatus('idle'))
                    }}
                    type={"success"}
                />
                <Banner
                    label={"Si è verificato un errore durante la modifica dell'azienda."}
                    visible={companiesState.editCompanyStatus === 'failed'}
                    closeAction={function (): void {
                        dispatch(setEditCompanyStatus('idle'))
                    }}
                    type={"error"}
                />
            </div>
            <PeopleHorizontalFilters />
            <div className="flex max-h-full gap-4">
                <div className={clsx("transition-all duration-300 w-[400px] flex-shrink-0", {
                    "block": databaseState.filtersActive,
                    "hidden": !databaseState.filtersActive,
                })}>
                    <Filters />
                </div>
                <div className="pr-[24px] w-full overflow-auto">
                    {
                        peopleState.findAllStatus === 'loading' ?
                            <div className="w-full h-[45%] flex items-center justify-center">
                                <Spinner />
                            </div> :
                            peopleState.findAllResponse?.data.length === 0 || peopleState.findAllResponse?.data === null ?
                                <NotFound /> :
                                <PeopleList />
                    }
                </div>
                <div className={clsx("absolute flex-shrink-0 w-[290px] h-full right-0 top-0 bg-white transition-all shadow-md", {
                    'hidden': !databaseState.optionsActive
                })}>
                    <Options />
                </div>
                <div className={clsx("mb-6 absolute flex-shrink-0 w-[480px] h-full right-0 top-0 bg-neutral-50 transition-all shadow-lg overflow-auto", {
                    'hidden': peopleState.selectedPerson === undefined
                })}>
                    <EditPerson />
                </div>
            </div>
        </div>
    )
}