import React from "react";
import { IconProps } from "./dto";

export function RocketIcon(props: IconProps) {
    return (
        <svg width={props.size} height={props.size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_295_306)">
                <path d="M11.104 8.89611L4.53383 15.4663M5.24288 10.698L2.02472 9.93785C1.67065 9.85421 1.54613 9.41465 1.80261 9.15724L4.36191 6.59795C4.53383 6.42602 4.76708 6.32752 5.01149 6.32566L7.89696 6.30057M11.5315 2.99877C13.6791 4.46334 15.5367 6.32101 17.0013 8.46863M9.30114 14.7572L10.0613 17.9754C10.1449 18.3294 10.5845 18.454 10.8419 18.1975L13.4012 15.6382C13.5731 15.4663 13.6716 15.233 13.6735 14.9886L13.6986 12.1031M17.5524 6.95851L18.3033 3.34539C18.5077 2.36219 17.6379 1.49237 16.6547 1.69682L13.0416 2.44769C11.9775 2.66886 11.0018 3.19578 10.2342 3.96431L7.26969 6.92785C6.13873 8.05881 5.40272 9.52524 5.1704 11.1078L5.16017 11.1757C5.01334 12.1858 5.35161 13.2053 6.07275 13.9273C6.79388 14.6485 7.81426 14.9867 8.82441 14.839L8.89224 14.8288C10.4748 14.5974 11.9413 13.8604 13.0722 12.7295L16.0358 9.76593C16.8043 8.99833 17.3312 8.02256 17.5524 6.95851Z" stroke={props.color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            </g>
            <defs>
                <clipPath id="clip0_295_306">
                    <rect width={props.size} height={props.size} fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}