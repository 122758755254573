import clsx from "clsx";
import { colors } from "../../colors";
import { PlusIcon } from "../../icons/plus";
import { BannerProps } from "./dto";

export function Banner(props: BannerProps) {

    const colorMap = new Map<string, string>([
        ['success', colors.green[800]],
        ['error', colors.red[800]],
        ['warning', ' #9a3412'],
    ])

    return (
        <div className={clsx("transition-all duration-[400ms] ease-out rounded-[12px] p-[24px] items-center justify-between shadow-md z-40 min-w-96 gap-8", {
            "flex": props.visible,
            "hidden": !props.visible,
            'bg-red-200': props.type === 'error',
            'bg-green-200': props.type === 'success',
            'bg-orange-200': props.type === 'warning',
        })}>
            <span className={clsx("text-text-md font-semibold", {
                "text-red-800": props.type === 'error',
                'text-green-800': props.type === 'success',
                'text-orange-800': props.type === 'warning',
            })}>{props.label}</span>
            <div
                onClick={() => props.closeAction()}
                className={clsx("rounded-[50%] border-[1px] p-[2px] rotate-45 cursor-pointer", {
                    "border-red-800": props.type === 'error',
                    'border-green-800': props.type === 'success',
                    'border-orange-800': props.type === 'warning',
                })}
            >
                <PlusIcon color={colorMap.get(props.type)!} size={20} />
            </div>
        </div>
    )
}