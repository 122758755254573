import { useEffect } from "react"
import { useAppDispatch, useAppSelector } from "../../../../lib/redux/hooks"
import { CodeIcon } from "../../../../ui/icons/code"
import { SelectCustom } from "../../../../ui/molecules/select"
import { AccordionFilter } from "../../../../ui/organisms/accordionFilter"
import { setFindAllPeopleFiltersAtecos, setFindAllPeopleFiltersCategory, setFindAllPeopleFiltersSector } from "../slice"

export function AtecoFilter() {
    const peopleState = useAppSelector(state => state.people)
    const databaseState = useAppSelector(state => state.database)
    const preferencesState = useAppSelector(state => state.preferences)

    const dispatch = useAppDispatch()

    useEffect(() => {
        if (peopleState.filters.atecos.length === 0) {
            dispatch(setFindAllPeopleFiltersCategory([]))
            dispatch(setFindAllPeopleFiltersSector([]))
        } else {
            peopleState.filters.atecos.forEach(ateco => {
                const category = preferencesState.findAllAtecosResponse.find(_ateco => _ateco.atecoCode === ateco.value)?.category
                const sector = preferencesState.findAllAtecosResponse.find(_ateco => _ateco.atecoCode === ateco.value)?.sector
                if (category && !peopleState.filters.category.map(cat => cat.value).includes(category)) {
                    dispatch(setFindAllPeopleFiltersCategory([...peopleState.filters.category, { value: category, label: category }]))
                }
                if (sector && !peopleState.filters.sector.map(cat => cat.value).includes(sector)) {
                    dispatch(setFindAllPeopleFiltersSector([...peopleState.filters.sector, { value: sector, label: sector }]))
                }
            })
        }
    }, [peopleState.filters.atecos])

    return (
        <AccordionFilter
            key={'person-filter-ateco'}
            label={"Codice Ateco"}
            icon={<CodeIcon color={""} size={0} />}
            showClear={peopleState.filters.atecos.length > 0}
            options={peopleState.filters.atecos}
            clearAction={function (): void {
                dispatch(setFindAllPeopleFiltersAtecos([]))
            }}
            deleteFilter={function (option): void {
                dispatch(setFindAllPeopleFiltersAtecos(peopleState.filters.atecos.filter(name => name.value !== option.value)))
            }}
        >
            <SelectCustom
                isMulti
                onChange={e => {
                    if (e !== undefined && typeof e !== 'string') {
                        dispatch(setFindAllPeopleFiltersAtecos(e.map(option => ({ value: option, label: (preferencesState.findAllAtecosResponse.find(ateco => ateco.atecoCode === option)?.atecoCode + ' - ' + preferencesState.findAllAtecosResponse.find(ateco => ateco.atecoCode === option)?.atecoDescription) }))))
                    }
                }}
                isClearable={false}
                defaultValue={peopleState.filters.atecos.map(option => option.value)}
                placeholder={"Cerca per ateco"}
                options={preferencesState.findAllAtecosResponse.map(ateco => ({ value: ateco.atecoCode, label: ateco.atecoCode + ' - ' + ateco.atecoDescription }))}
                noOptionsMessage="Non sono presenti ateco."
            />
        </AccordionFilter>
    )
}