import { useAppDispatch, useAppSelector } from "../../../../lib/redux/hooks"
import { BuildingIcon } from "../../../../ui/icons/building"
import { SelectCustom } from "../../../../ui/molecules/select"
import { AccordionFilter } from "../../../../ui/organisms/accordionFilter"
import { setFindAllPeopleFiltersName } from "../slice"

export function CompanyFilter() {
    const peopleState = useAppSelector(state => state.people)
    const databaseState = useAppSelector(state => state.database)

    const dispatch = useAppDispatch()

    return (
        <AccordionFilter
            key={'person-filter-company'}
            label={"Azienda"}
            icon={<BuildingIcon color={""} size={0} />}
            showClear={peopleState.filters.name.length > 0}
            options={peopleState.filters.name}
            clearAction={function (): void {
                dispatch(setFindAllPeopleFiltersName([]))
            }}
            deleteFilter={function (option): void {
                dispatch(setFindAllPeopleFiltersName(peopleState.filters.name.filter(name => name.value !== option.value)))
            }}
        >
            <SelectCustom
                isMulti
                onChange={e => {
                    if (e !== undefined && typeof e !== 'string') {
                        dispatch(setFindAllPeopleFiltersName(e.map(option => ({ value: option, label: databaseState.companiesForFiltersResponse?.data.find(company => company.id === option)?.name }))))
                    }
                }}
                isClearable={false}
                defaultValue={peopleState.filters.name.map(option => option.value)}
                placeholder={"Cerca per ragione sociale"}
                options={databaseState.companiesForFiltersResponse ? databaseState.companiesForFiltersResponse.data.map(company => ({ label: company.name, value: company.id })) : []}
                noOptionsMessage="Non sono presenti aziende."
            />
        </AccordionFilter>
    )
}