import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { NewObjectService } from "../../lib/objects/service"
import { emailRegex, phoneRegex } from "../../lib/regex"
import { PromiseStatuses } from "../../lib/types"
import { EditUserRequest, FindAllUserResponse, FindAllUsersFilters, NewUserRequest, UserDTO, UserRole } from "./dto"
import { NewUsersService } from "./service"

interface UsersState {
    findAllStatus: PromiseStatuses
    findAllResponse?: FindAllUserResponse
    findUserByIdStatus: PromiseStatuses
    findUserByIdResponse?: UserDTO
    filters: FindAllUsersFilters
    editUserRequest: EditUserRequest
    editUserStatus: PromiseStatuses
    newUserRequest: NewUserRequest
    newUserStatus: PromiseStatuses
    findCollaboratorsResponse?: FindAllUserResponse
    findCollaboratorsStatus: PromiseStatuses
    findClientsResponse?: FindAllUserResponse
    findClientsStatus: PromiseStatuses
    validateNewUserStatus: PromiseStatuses
    validateUpdateUserStatus: PromiseStatuses
    createUserErrors: {
        role: boolean
        email: boolean
        errorLabel: string
        name: boolean
        surname: boolean
        phone: boolean
    },
    updateUserErrors: {
        name: boolean
        surname: boolean
        phone: boolean
    },
    deleteUserStatus: PromiseStatuses
    selectedUser?: string,
    findUsersAvatarsListResponse: { userId: string, objectId: string | null }[]
    findUsersAvatarsListStatus: PromiseStatuses
    findUserAvatarResponse?: string
    findUserAvatarStatus: PromiseStatuses
}

const initialState: UsersState = {
    findAllStatus: 'idle',
    findUserByIdStatus: 'idle',
    filters: {
        role: undefined,
        search: undefined,
        order: true,
        sort: undefined,
        itemsPerPage: 10,
        page: 0
    },
    editUserRequest: {
        name: '',
        surname: '',
        collaborators: [],
        customerId: '',
        phone: ''
    },
    editUserStatus: 'idle',
    newUserRequest: {
        name: '',
        surname: '',
        email: '',
        objectId: '',
        role: undefined,
        collaborators: [],
        customerId: '',
        phone: ''
    },
    newUserStatus: 'idle',
    findCollaboratorsStatus: 'idle',
    findClientsStatus: 'idle',
    validateNewUserStatus: 'idle',
    validateUpdateUserStatus: 'idle',
    createUserErrors: {
        role: false,
        email: false,
        name: false,
        surname: false,
        phone: false,
        errorLabel: '',
    },
    updateUserErrors: {
        name: false,
        surname: false,
        phone: false
    },
    deleteUserStatus: 'idle',
    findUsersAvatarsListResponse: [],
    findUsersAvatarsListStatus: 'idle',
    findUserAvatarStatus: 'idle'
}

export const findAllUsers = createAsyncThunk(
    'users/findAllUsers',
    async (filters: FindAllUsersFilters, thunkApi): Promise<FindAllUserResponse> => {
        const UsersService = NewUsersService()

        return UsersService.findAllUsers(filters)
    }
)

export const findUserById = createAsyncThunk(
    'users/findUserById',
    async (id: string, thunkApi): Promise<UserDTO> => {
        const UsersService = NewUsersService()

        return UsersService.findUserById(id)
    }
)

export const editUser = createAsyncThunk(
    'users/editUser',
    async (request: { id: string, data: FormData }, thunkApi): Promise<void> => {
        const UsersService = NewUsersService()

        return UsersService.editUser(request.id, request.data)
            .catch(error => {
                throw (thunkApi.rejectWithValue(error))
            })
    }
)

export const newUser = createAsyncThunk(
    'users/newUser',
    async (data: FormData, thunkApi): Promise<void> => {
        const UsersService = NewUsersService()

        return UsersService.createUser(data)
            .catch(error => {
                throw (thunkApi.rejectWithValue(error))
            })
    }
)

export const deleteUser = createAsyncThunk(
    'users/deleteUser',
    async (id: string, thunkApi): Promise<void> => {
        const UsersService = NewUsersService()

        return UsersService.deleteUser(id)
            .catch(error => {
                throw (thunkApi.rejectWithValue(error))
            })
    }
)

export const validateNewUser = createAsyncThunk(
    'users/validateNewUser',
    async (user: NewUserRequest, thunkApi): Promise<void> => {

        let isValid = true

        thunkApi.dispatch(setValidateNewUserName(false))
        thunkApi.dispatch(setValidateNewUserSurname(false))
        thunkApi.dispatch(setValidateNewUserEmail(false))
        thunkApi.dispatch(setValidateNewUserRole(false))
        thunkApi.dispatch(setValidateNewUserPhone(false))
        thunkApi.dispatch(setValidateNewUserStatus('idle'))

        if (!user.role || user.role === '') {
            thunkApi.dispatch(setValidateNewUserRole(true))
            isValid = false
        }

        if (user.name === '' || !user.name) {
            thunkApi.dispatch(setValidateNewUserName(true))
            isValid = false
        }

        if (user.surname === '' || !user.surname) {
            thunkApi.dispatch(setValidateNewUserSurname(true))
            isValid = false
        }


        if (user.email === '' || !user.email || !emailRegex.test(user.email)) {
            thunkApi.dispatch(setValidateNewUserEmail(true))
            isValid = false
        }

        if (user.phone !== '' && user.phone && !phoneRegex.test(user.phone)) {
            thunkApi.dispatch(setValidateNewUserPhone(true))
            isValid = false
        }

        if (!isValid) {
            return Promise.reject()
        }

        return Promise.resolve()
    }
)

export const validateUpdateUser = createAsyncThunk(
    'users/validateUpdateUser',
    async (user: EditUserRequest, thunkApi): Promise<void> => {

        let isValid = true

        thunkApi.dispatch(setValidateUpdateUserPhone(false))
        thunkApi.dispatch(setValidateUpdateUserStatus('idle'))
        thunkApi.dispatch(setValidateUpdateUserName(false))
        thunkApi.dispatch(setValidateUpdateUserSurname(false))

        if (user.name === '' || !user.name) {
            thunkApi.dispatch(setValidateUpdateUserName(true))
            isValid = false
        }

        if (user.surname === '' || !user.surname) {
            thunkApi.dispatch(setValidateUpdateUserSurname(true))
            isValid = false
        }

        if (user.phone !== '' && user.phone && !phoneRegex.test(user.phone)) {
            thunkApi.dispatch(setValidateUpdateUserPhone(true))
            isValid = false
        }

        if (!isValid) {
            return Promise.reject()
        }

        return Promise.resolve()
    }
)

export const findCollaborators = createAsyncThunk(
    'users/findCollaborators',
    async (_, thunkApi): Promise<FindAllUserResponse> => {
        const UsersService = NewUsersService()

        return UsersService.findAllUsers({
            role: UserRole.collaborator,
            order: true,
            sort: 'name',
            itemsPerPage: 10000000,
            page: 0
        })
    }
)

export const findClients = createAsyncThunk(
    'users/findClients',
    async (_, thunkApi): Promise<FindAllUserResponse> => {
        const UsersService = NewUsersService()

        return UsersService.findAllUsers({
            role: UserRole.customer,
            order: true,
            sort: 'name',
            itemsPerPage: 10000000,
            page: 0
        })
    }
)

export const findUsersAvatarsList = createAsyncThunk(
    'users/findUsersAvatarsList',
    async (request: { objectId: string | null, userId: string }[], thunkApi): Promise<{ userId: string, objectId: string | null }[]> => {
        let promises: Promise<{ userId: string, objectId: string | null }>[] = []
        promises = request.map(async (user) => {
            if (user.objectId !== null && user.objectId !== undefined) {
                const objectService = NewObjectService()

                return objectService.findFileByIdWithUserId(user.objectId, user.userId)
            } else return Promise.resolve({ userId: user.userId, objectId: '' })
        })
        return Promise.all(promises)
    }
)

export const findUserAvatar = createAsyncThunk(
    'users/findUserAvatar',
    async (request: string, thunkApi): Promise<string> => {
        const objectService = NewObjectService()

        return objectService.findFileById(request)
    }
)

const usersSlice = createSlice({
    name: 'users/slice',
    initialState,
    extraReducers(builder) {
        builder
            .addCase(findAllUsers.pending, (state) => {
                state.findAllStatus = 'loading'
            })
            .addCase(findAllUsers.fulfilled, (state, action) => {
                state.findAllStatus = 'successfully'
                state.findAllResponse = action.payload
            })
            .addCase(findAllUsers.rejected, (state) => {
                state.findAllStatus = 'failed'
            })
            .addCase(findUserById.pending, (state) => {
                state.findUserByIdStatus = 'loading'
            })
            .addCase(findUserById.fulfilled, (state, action) => {
                state.findUserByIdStatus = 'successfully'
                state.findUserByIdResponse = action.payload
            })
            .addCase(findUserById.rejected, (state) => {
                state.findUserByIdStatus = 'failed'
            })
            .addCase(editUser.pending, (state) => {
                state.editUserStatus = 'loading'
            })
            .addCase(editUser.fulfilled, (state) => {
                state.editUserStatus = 'successfully'
            })
            .addCase(editUser.rejected, (state, action) => {
                state.editUserStatus = 'failed'
            })
            .addCase(newUser.pending, (state) => {
                state.newUserStatus = 'loading'
            })
            .addCase(newUser.fulfilled, (state, action) => {
                state.newUserStatus = 'successfully'
            })
            .addCase(newUser.rejected, (state, action) => {
                //@ts-ignore
                if (action.payload.response.data.errors === 'Error creating user on keycloak') {
                    state.newUserStatus = 'idle'
                    state.createUserErrors.email = true
                    state.createUserErrors.errorLabel = 'Questa email è già stata utilizzata.'
                } else {
                    state.newUserStatus = 'failed'
                }
            })
            .addCase(findCollaborators.pending, (state) => {
                state.findCollaboratorsStatus = 'loading'
            })
            .addCase(findCollaborators.fulfilled, (state, action) => {
                state.findCollaboratorsStatus = 'successfully'
                state.findCollaboratorsResponse = action.payload
            })
            .addCase(findCollaborators.rejected, (state) => {
                state.findCollaboratorsStatus = 'failed'
            })
            .addCase(findClients.pending, (state) => {
                state.findClientsStatus = 'loading'
            })
            .addCase(findClients.fulfilled, (state, action) => {
                state.findClientsStatus = 'successfully'
                state.findClientsResponse = action.payload
            })
            .addCase(findClients.rejected, (state) => {
                state.findClientsStatus = 'failed'
            })
            .addCase(validateNewUser.pending, (state) => {
                state.validateNewUserStatus = 'loading'
            })
            .addCase(validateNewUser.fulfilled, (state) => {
                state.validateNewUserStatus = 'successfully'
            })
            .addCase(validateNewUser.rejected, (state) => {
                state.validateNewUserStatus = 'failed'
            })
            .addCase(validateUpdateUser.pending, (state) => {
                state.validateUpdateUserStatus = 'loading'
            })
            .addCase(validateUpdateUser.fulfilled, (state) => {
                state.validateUpdateUserStatus = 'successfully'
            })
            .addCase(validateUpdateUser.rejected, (state) => {
                state.validateUpdateUserStatus = 'failed'
            })
            .addCase(deleteUser.pending, (state) => {
                state.deleteUserStatus = 'loading'
            })
            .addCase(deleteUser.fulfilled, (state) => {
                state.deleteUserStatus = 'successfully'
            })
            .addCase(deleteUser.rejected, (state) => {
                state.deleteUserStatus = 'failed'
            })
            .addCase(findUsersAvatarsList.pending, (state) => {
                state.findUsersAvatarsListStatus = 'loading'
            })
            .addCase(findUsersAvatarsList.fulfilled, (state, action) => {
                state.findUsersAvatarsListStatus = 'successfully'
                state.findUsersAvatarsListResponse = action.payload
            })
            .addCase(findUsersAvatarsList.rejected, (state) => {
                state.findUsersAvatarsListStatus = 'failed'
            })
            .addCase(findUserAvatar.pending, (state) => {
                state.findUserAvatarStatus = 'loading'
            })
            .addCase(findUserAvatar.fulfilled, (state, action) => {
                state.findUserAvatarStatus = 'successfully'
                state.findUserAvatarResponse = action.payload
            })
            .addCase(findUserAvatar.rejected, (state) => {
                state.findUserAvatarStatus = 'failed'
            })
    },
    reducers: {
        setFindAllUsersFiltersRole: (state, action) => {
            state.filters.role = action.payload
        },
        setFindAllUsersFiltersSearch: (state, action) => {
            state.filters.search = action.payload
        },
        setFindAllUsersFiltersOrder: (state, action) => {
            state.filters.order = action.payload
        },
        setFindAllUsersFiltersSort: (state, action) => {
            state.filters.sort = action.payload
        },
        setFindAllUsersFiltersItemsPerPage: (state, action) => {
            state.filters.itemsPerPage = action.payload
        },
        setFindAllUsersFiltersPage: (state, action) => {
            state.filters.page = action.payload
        },
        setFindUserByIdResponse: (state, action) => {
            state.findUserByIdResponse = action.payload
        },
        setFindUserByIdStatus: (state, action) => {
            state.findUserByIdStatus = action.payload
        },
        setCreateUserName: (state, action) => {
            state.newUserRequest.name = action.payload
        },
        setCreateUserSurname: (state, action) => {
            state.newUserRequest.surname = action.payload
        },
        setCreateUserRole: (state, action) => {
            state.newUserRequest.role = action.payload
        },
        setCreateUserEmail: (state, action) => {
            state.newUserRequest.email = action.payload
        },
        setCreateUserPhone: (state, action) => {
            state.newUserRequest.phone = action.payload
        },
        setCreateUserCollaborators: (state, action) => {
            state.newUserRequest.collaborators = action.payload
        },
        setCreateUserCustomerId: (state, action) => {
            state.newUserRequest.customerId = action.payload
        },
        setCreateUserObjectId: (state, action) => {
            state.newUserRequest.objectId = action.payload
        },
        setCreateUserStatus: (state, action) => {
            state.newUserStatus = action.payload
        },
        setValidateNewUserStatus: (state, action) => {
            state.validateNewUserStatus = action.payload
        },
        setValidateNewUserRole: (state, action) => {
            state.createUserErrors.role = action.payload
        },
        setValidateNewUserEmail: (state, action) => {
            state.createUserErrors.email = action.payload
        },
        setValidateNewUserPhone: (state, action) => {
            state.createUserErrors.phone = action.payload
        },
        setValidateNewUserName: (state, action) => {
            state.createUserErrors.name = action.payload
        },
        setValidateNewUserSurname: (state, action) => {
            state.createUserErrors.surname = action.payload
        },
        setValidateNewUserErrorLabel: (state, action) => {
            state.createUserErrors.errorLabel = action.payload
        },
        setUpdateUserName: (state, action) => {
            state.editUserRequest.name = action.payload
        },
        setUpdateUserSurname: (state, action) => {
            state.editUserRequest.surname = action.payload
        },
        setUpdateUserCustomerId: (state, action) => {
            state.editUserRequest.customerId = action.payload
        },
        setUpdateUserCollaborators: (state, action) => {
            state.editUserRequest.collaborators = action.payload
        },
        setUpdateUserPhone: (state, action) => {
            state.editUserRequest.phone = action.payload
        },
        setUpdateUserStatus: (state, action) => {
            state.editUserStatus = action.payload
        },
        setValidateUpdateUserPhone: (state, action) => {
            state.updateUserErrors.phone = action.payload
        },
        setValidateUpdateUserName: (state, action) => {
            state.updateUserErrors.name = action.payload
        },
        setValidateUpdateUserSurname: (state, action) => {
            state.updateUserErrors.surname = action.payload
        },
        setValidateUpdateUserStatus: (state, action) => {
            state.validateUpdateUserStatus = action.payload
        },
        setDeleteUserStatus: (state, action) => {
            state.deleteUserStatus = action.payload
        },
        setSelectedUser: (state, action) => {
            state.selectedUser = action.payload
        }
    },
})

export const {
    setFindAllUsersFiltersRole,
    setFindAllUsersFiltersItemsPerPage,
    setFindAllUsersFiltersOrder,
    setFindAllUsersFiltersPage,
    setFindAllUsersFiltersSort,
    setFindUserByIdResponse,
    setCreateUserEmail,
    setCreateUserName,
    setCreateUserObjectId,
    setCreateUserRole,
    setCreateUserSurname,
    setCreateUserPhone,
    setCreateUserStatus,
    setCreateUserCollaborators,
    setCreateUserCustomerId,
    setValidateNewUserStatus,
    setValidateNewUserEmail,
    setValidateNewUserName,
    setValidateNewUserRole,
    setValidateNewUserSurname,
    setValidateNewUserPhone,
    setValidateNewUserErrorLabel,
    setUpdateUserCollaborators,
    setUpdateUserCustomerId,
    setUpdateUserName,
    setUpdateUserPhone,
    setUpdateUserSurname,
    setUpdateUserStatus,
    setValidateUpdateUserName,
    setValidateUpdateUserPhone,
    setValidateUpdateUserSurname,
    setValidateUpdateUserStatus,
    setDeleteUserStatus,
    setSelectedUser,
    setFindAllUsersFiltersSearch,
    setFindUserByIdStatus
} = usersSlice.actions

export default usersSlice.reducer