export const peopleColumns = [
    {
        column: 'Contatto',
        visible: true
    },
    {
        column: 'Telefono',
        visible: true
    },
    {
        column: 'Email',
        visible: true
    },
    {
        column: 'Profilo Linkedin',
        visible: true
    },
    {
        column: 'Ruolo',
        visible: true
    },
    {
        column: 'Azienda',
        visible: true
    },
    {
        column: 'Reparto',
        visible: true
    },
    {
        column: 'Privacy',
        visible: true
    }
]

export const companyColumns = [
    { column: 'Azienda', visible: true },
    { column: 'Fatturato', visible: true },
    { column: 'Telefono', visible: true },
    { column: 'Email', visible: true },
    { column: 'Indirizzo', visible: true },
    { column: 'CAP', visible: true },
    { column: 'Città', visible: true },
    { column: 'Provincia', visible: true },
    { column: 'Regione', visible: true },
    { column: 'Stato', visible: true },
    { column: 'P. IVA', visible: true },
    { column: 'Cod. ATECO', visible: true },
    { column: 'Settore', visible: true },
    { column: 'Categoria', visible: true },
    { column: 'Dipendenti', visible: true },
    { column: 'Sito web', visible: true },
]

export const signOptions = [
    { label: 'Uguale a', value: '=' },
    { label: 'Maggiore di', value: '>' },
    { label: 'Minore di', value: '<' },
    { label: 'Compreso tra', value: '><' },
];

export interface ImportCSVResponseDTO {
    ids: number[]
    missed: number[]
}

export interface SearchPlacesDTO {
    type: string,
    features: PlaceFeatureDTO[],
    attribution: string
}

export interface PlaceFeatureDTO {
    type: "Feature"
    id: string
    geometry: {
        type: "Point"
        coordinates: number[]
    }
    properties: {
        mapbox_id: string,
        feature_type: string,
        full_address: string,
        name: string,
        name_preferred: string,
        coordinates: {
            longitude: string,
            latitude: string
        }
        bbox: number[],
        context: {
            country: {
                mapbox_id: string,
                name: string,
                country_code: string,
                country_code_alpha_3: string,
                wikidata_id: string,
                translations: {
                    it: {
                        language: string,
                        name: string
                    }
                }
            }
        }
    }
}