import { IconProps } from "./dto";

export function PhoneCallIcon(props: IconProps) {
    return (
        <svg width={props.size} height={props.size} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_635_5551)">
                <path d="M10.0336 3.33335C10.6848 3.4604 11.2832 3.77886 11.7523 4.24798C12.2214 4.7171 12.5399 5.31553 12.6669 5.96669M10.0336 0.666687C11.3865 0.816978 12.648 1.4228 13.6111 2.38469C14.5742 3.34658 15.1816 4.60736 15.3336 5.96002M14.6669 11.28V13.28C14.6677 13.4657 14.6297 13.6495 14.5553 13.8196C14.4809 13.9897 14.3718 14.1424 14.235 14.2679C14.0982 14.3934 13.9367 14.489 13.7608 14.5485C13.5849 14.608 13.3985 14.6301 13.2136 14.6134C11.1622 14.3904 9.19161 13.6894 7.46028 12.5667C5.8495 11.5431 4.48384 10.1775 3.46028 8.56669C2.3336 6.82749 1.63244 4.84735 1.41361 2.78669C1.39695 2.60233 1.41886 2.41653 1.47795 2.2411C1.53703 2.06568 1.63199 1.90448 1.75679 1.76777C1.88159 1.63106 2.03348 1.52183 2.20281 1.44703C2.37213 1.37224 2.55517 1.33353 2.74028 1.33335H4.74028C5.06382 1.33017 5.37748 1.44474 5.62279 1.65571C5.8681 1.86668 6.02833 2.15965 6.07361 2.48002C6.15803 3.12006 6.31458 3.7485 6.54028 4.35335C6.62998 4.59197 6.64939 4.8513 6.59622 5.10061C6.54305 5.34992 6.41952 5.57876 6.24028 5.76002L5.39361 6.60669C6.34265 8.27572 7.72458 9.65765 9.39361 10.6067L10.2403 9.76002C10.4215 9.58078 10.6504 9.45725 10.8997 9.40408C11.149 9.35091 11.4083 9.37032 11.6469 9.46002C12.2518 9.68572 12.8802 9.84227 13.5203 9.92669C13.8441 9.97237 14.1399 10.1355 14.3513 10.385C14.5627 10.6345 14.6751 10.9531 14.6669 11.28Z" stroke={props.color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            </g>
            <defs>
                <clipPath id="clip0_635_5551">
                    <rect width="16" height="16" fill="white" />
                </clipPath>
            </defs>
        </svg>

    )
}