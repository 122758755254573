import { ReactElement } from "react"
import { ActivityIcon } from "../../icons/activity"
import { CalendarIcon } from "../../icons/calendar"
import { DatabaseIcon } from "../../icons/database"
import { HomeIcon } from "../../icons/home"
import { ListIcon } from "../../icons/list"
import { RocketIcon } from "../../icons/rocket"
import { SettingsIcon } from "../../icons/settings"
import { TrelloIcon } from "../../icons/trello"
import { UsersIcon } from "../../icons/users"

export enum MenuItems {
    DASHBOARD = 'DASHBOARD',
    DATABASE = 'DATABASE',
    TARGET_LIST = 'TARGET_LIST',
    PROJECTS = 'PROJECTS',
    USERS = 'USERS',
    PIPELINE = 'PIPELINE',
    CALENDAR = 'CALENDAR',
    REPORT = 'REPORT',
    SETTINGS = 'SETTINGS'
}

export const MenuItemsLabelMap = new Map<MenuItems, string>([
    [MenuItems.DASHBOARD, 'Dashboard'],
    [MenuItems.DATABASE, 'Database'],
    [MenuItems.TARGET_LIST, 'Liste Target'],
    [MenuItems.PROJECTS, 'Progetti'],
    [MenuItems.USERS, 'Utenti'],
    [MenuItems.PIPELINE, 'Pipeline'],
    [MenuItems.CALENDAR, 'Calendario'],
    [MenuItems.REPORT, 'Report'],
    [MenuItems.SETTINGS, 'Impostazioni']
])

export const MenuItemsHrefMap = new Map<MenuItems, string>([
    [MenuItems.DASHBOARD, '/dashboard'],
    [MenuItems.DATABASE, '/database'],
    [MenuItems.TARGET_LIST, '/target-list'],
    [MenuItems.PROJECTS, '/projects'],
    [MenuItems.USERS, '/users'],
    [MenuItems.PIPELINE, '/pipeline'],
    [MenuItems.CALENDAR, '/calendar'],
    [MenuItems.REPORT, '/report'],
    [MenuItems.SETTINGS, '/settings']
])

export const PathMenuItemMap = new Map<string, MenuItems>([
    ['dashboard', MenuItems.DASHBOARD],
    ['database', MenuItems.DATABASE],
    ['liste target', MenuItems.TARGET_LIST],
    ['progetti', MenuItems.PROJECTS],
    ['utenti', MenuItems.USERS],
    ['pipeline', MenuItems.PIPELINE],
    ['calendario', MenuItems.CALENDAR],
    ['report', MenuItems.REPORT],
    ['impostazioni', MenuItems.SETTINGS]
])

export function MenuItemsIconsMap(menuItem: MenuItems, color: string, size: number): ReactElement {
    const map = new Map<MenuItems, ReactElement>([
        [MenuItems.DASHBOARD, <HomeIcon color={color} size={size} />],
        [MenuItems.DATABASE, <DatabaseIcon color={color} size={size} />],
        [MenuItems.TARGET_LIST, <ListIcon color={color} size={size} />],
        [MenuItems.PROJECTS, <RocketIcon color={color} size={size} />],
        [MenuItems.USERS, <UsersIcon color={color} size={size} />],
        [MenuItems.PIPELINE, <TrelloIcon color={color} size={size} />],
        [MenuItems.CALENDAR, <CalendarIcon color={color} size={size} />],
        [MenuItems.REPORT, <ActivityIcon color={color} size={size} />],
        [MenuItems.SETTINGS, <SettingsIcon color={color} size={size} />]
    ])

    return (map.get(menuItem)!)
}