import { useAppDispatch, useAppSelector } from "../../../../lib/redux/hooks"
import { groupBy } from "../../../../lib/utils"
import { BriefcaseIcon } from "../../../../ui/icons/briefcase"
import { SelectCustom } from "../../../../ui/molecules/select"
import { AccordionFilter } from "../../../../ui/organisms/accordionFilter"
import { setFindAllCompaniesFiltersSector } from "../slice"

export function SectorFilter() {
    const companiesState = useAppSelector(state => state.companies)
    const preferencesState = useAppSelector(state => state.preferences)

    const dispatch = useAppDispatch()

    const filteredAtecos = preferencesState.findAllAtecosResponse
        .filter(ateco => companiesState.filters.atecos.length > 0 ? companiesState.filters.atecos.map(_ateco => _ateco.value).includes(ateco.atecoCode) : ateco)

    return (
        <AccordionFilter
            key={'person-filter-sector'}
            label={"Settore"}
            icon={<BriefcaseIcon color={""} size={0} />}
            showClear={companiesState.filters.sector.length > 0}
            options={companiesState.filters.sector}
            clearAction={function (): void {
                dispatch(setFindAllCompaniesFiltersSector([]))
            }}
            deleteFilter={function (option): void {
                dispatch(setFindAllCompaniesFiltersSector(companiesState.filters.sector.filter(name => name.value !== option.value)))
            }}
        >
            <SelectCustom
                isMulti
                isClearable={false}
                onChange={e => {
                    if (e !== undefined && typeof e !== 'string') {
                        dispatch(setFindAllCompaniesFiltersSector(e.map(option => ({ value: option, label: (groupBy(preferencesState.findAllAtecosResponse, 'sector').find(ateco => ateco.key === option)?.key) }))))
                    }
                }}
                defaultValue={companiesState.filters.sector.map(option => option.value)}
                placeholder={"Cerca per settore"}
                options={
                    groupBy(filteredAtecos, 'sector').map(sector => ({ value: sector.key, label: sector.key }))
                }
                noOptionsMessage="Non sono presenti settori."
            />
        </AccordionFilter>
    )
}