import clsx from "clsx";
import Button from "../../molecules/button";
import { ConfirmModalProps } from "./dto";

export function ConfirmModal(props: ConfirmModalProps) {
    return (
        <div
            onClick={() => props.onClose()}
            className={clsx("transition-all absolute bg-opacity-30 bg-neutral-950 backdrop-blur-sm top-0 left-0 h-full w-full flex items-center justify-center z-[100]", {
                "block": props.visible,
                "hidden": !props.visible
            })} >
            <div
                onClick={e => {
                    e.preventDefault()
                    e.stopPropagation()
                }}
                className="transition-all flex flex-col items-center gap-5 p-[20px] bg-white rounded-[12px] opacity-100 shadow-sm min-w-[400px]">
                <span className="text-text-md font-semibold text-neutral-800">{props.title}</span>
                <span className="text-text-md font-normal text-neutral-500">{props.label}</span>
                <div className="w-full flex flex-1 gap-3">
                    <div className="w-1/2">
                        <Button fullWidth size={"sm"} iconPosition={"off"} variant={"outline"} color={"gray"} label="Annulla" onClick={() => props.onClose()} />
                    </div>
                    <div className="w-1/2">
                        <Button fullWidth size={"sm"} iconPosition={"off"} variant={"solid"} color={"blue"} label="Conferma" onClick={() => props.confirmAction()} />
                    </div>
                </div>
            </div>
        </div>
    )
}